import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";
import { isEmpty, isEmptyList, isEmptyObject } from "../../../../utils";

function PowerballSectionAddons(props) {
  const { t } = useTranslation();

  const { loading, draws, config, onChangeConfig } = props;

  // eslint-disable-next-line no-unused-vars
  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const PowerballMegaplierContainer = (props) => {
    const { config, onChangeConfig } = props;

    const powerplay = useMemo(() => {
      return !isEmptyObject(config) && !isEmpty(config.powerplay)
        ? config.powerplay
        : false;
    }, [config]);

    const onClickMegaplierHandler = useCallback(
      (evt) => {
        onChangeConfig(evt, {
          ...config,
          powerplay: !powerplay,
        });
      },
      [config, powerplay, onChangeConfig]
    );

    return (
      <div className="powerball-section-addons__powerplay__container">
        <div className="powerball-section-addons__powerplay__content">
          <div
            className="powerball-section-addons__powerplay__value"
            onClick={(evt) => onClickMegaplierHandler(evt)}
          >
            <input
              type="checkbox"
              defaultChecked={powerplay}
              className="powerball-section-addons__powerplay__input"
            />
            <div className="powerball-section-addons__powerplay__label">
              {t("powerball.body.addons.play_powerplay")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeConfigHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  return (
    <div className="powerball-section-addons__container">
      {loading ? (
        <div className="powerball-section-addons__loading">
          <Loading />
        </div>
      ) : (
        <div className="powerball-section-addons__content">
          <PowerballMegaplierContainer
            config={config}
            onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
          />
        </div>
      )}
    </div>
  );
}

export default PowerballSectionAddons;
