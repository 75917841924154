import { useMemo } from "react";

import moment from "moment";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";
import CountdonwnComponent from "../../../../components/countdown-component";

import {
  isEmpty,
  nFormatter,
  DATE_FORMAT,
  isEmptyList,
} from "../../../../utils";
import { GAME_OID } from "../../../../utils/constants";

function GordoSectionTitle(props) {
  const { loading, draws } = props;

  const { t, i18n } = useTranslation();

  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const TitleHeader = (props) => {
    const title = useMemo(() => {
      return !isEmpty(draw)
        ? draw.title
        : t(`draws.${GAME_OID.EL_GORDO}.title`);
    }, []);
    return (
      <div className="gordo-section-title__content__header">
        <span className="subtitle">{t("gordo.body.title.play")}</span>
        <h1 className="title">{title}</h1>
      </div>
    );
  };

  const TitleBody = (props) => {
    const pot = useMemo(() => {
      if (!isEmpty(draw)) {
        if (!isEmpty(draw.pot) && parseFloat(draw.pot) > 0) {
          const { amount, symbol } = nFormatter(parseFloat(draw.pot), 2, i18n);
          return (
            <span className="title-pot" title={`${amount} ${symbol}`}>
              <span className="amount">{amount}</span>
              <span className="unit">{symbol}</span>
            </span>
          );
        } else {
          return (
            <span className="title-pot" title={t("message.waiting_pot")}>
              {t("message.waiting_pot")}
            </span>
          );
        }
      } else {
        return (
          <span className="title-pot" title={t("message.waiting_pot")}>
            {t("message.waiting_pot")}
          </span>
        );
      }
    }, []);

    return (
      <div className="gordo-section-title__content__body">
        <span className="subtitle">{t("gordo.body.title.pot")}</span>
        {pot}
      </div>
    );
  };

  const TitleFooter = (props) => {
    const closeDate = useMemo(() => {
      return !isEmpty(draw)
        ? moment(draw.close_date).format(DATE_FORMAT.US_LONG_FORMAT)
        : "";
    }, []);

    return (
      <div className="gordo-section-title__content__footer">
        <span className="subtitle">{t("gordo.body.title.closingDate")}</span>
        {!isEmpty(closeDate) ? (
          <CountdonwnComponent className={"title-countdown"} date={closeDate} />
        ) : (
          <span className="title-msg">{t("message.waiting_draw")}</span>
        )}
      </div>
    );
  };

  return (
    <div className="gordo-section-title__container">
      {loading ? (
        <div className="gordo-section-title__loading">
          <Loading color={"#ffffff"} />
        </div>
      ) : (
        <div className="gordo-section-title__content">
          <>
            <TitleHeader />
            <TitleBody />
            <TitleFooter />
          </>
        </div>
      )}
    </div>
  );
}

export default GordoSectionTitle;
