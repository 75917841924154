import FooterPage from "../../../components/footer";

function LegalFooter() {
  return (
    <div className="legal-footer">
      <FooterPage />
    </div>
  );
}

export default LegalFooter;
