import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { isEmpty, isEmptyList } from "../../../utils";

function GordoBetContainer(props) {
  const { index, bet, config, randomizable, onChange, onClear, onRandom } =
    props;

  const { t } = useTranslation();

  const numbersMemo = useMemo(() => {
    return !isEmpty(bet) ? bet.numbers : [];
  }, [bet]);

  const specialsMemo = useMemo(() => {
    return !isEmpty(bet) ? bet.specials : [];
  }, [bet]);

  const isSelected = useCallback((listItem, item) => {
    return listItem.includes(item);
  }, []);

  const numberClazzes = useCallback(
    (number) => {
      return {
        selected: isSelected(numbersMemo, number),
        disabled: bet.disabled,
      };
    },
    [numbersMemo, bet.disabled, isSelected]
  );

  const onClickNumberHandler = useCallback(
    (evt, number) => {
      if (!bet.disabled) {
        let modifiedNumbers = [...numbersMemo];
        if (!isEmptyList(numbersMemo)) {
          if (!numbersMemo.includes(number)) {
            if (numbersMemo.length < config.maxNumbers) {
              modifiedNumbers = [...numbersMemo, number];
            }
          } else {
            const idx = numbersMemo.findIndex((item) => item === number);
            modifiedNumbers = [
              ...numbersMemo.slice(0, idx),
              ...numbersMemo.slice(idx + 1),
            ];
          }
        } else {
          if (numbersMemo.length < config.maxNumbers) {
            modifiedNumbers = [...numbersMemo, number];
          }
        }
        onChange(evt, {
          numbers: [...modifiedNumbers],
          specials: [...specialsMemo],
        });
      }
    },
    [bet.disabled, numbersMemo, specialsMemo, config.maxNumbers, onChange]
  );

  const onClickRandomHandler = useCallback(
    (evt) => {
      if (!bet.disabled) {
        if (randomizable) {
          onRandom(evt, {
            numbers: [...numbersMemo],
            specials: [...specialsMemo],
          });
        }
      }
    },
    [bet.disabled, randomizable, numbersMemo, specialsMemo, onRandom]
  );

  const onClickClearHandler = useCallback(
    (evt) => {
      if (!bet.disabled) {
        onClear(evt, {
          numbers: [],
          specials: [],
          config: config,
        });
      }
    },
    [bet.disabled, onClear, config]
  );

  return (
    <div
      key={index}
      data-line={index}
      className={classNames("gordo-board__line", {
        disabled: bet.disabled,
      })}
    >
      <div className="gordo-board__grid-numbers__container">
        <div className="gordo-board__grid-numbers__content">
          <span
            className={classNames("gordo-board__grid-numbers__title", {
              hide: true,
            })}
          >
            {t("gordo.body.board.numbers.title")}
          </span>
          <div className="gordo-board__grid-numbers__numbers">
            {[...Array(config.numbers).keys()]
              .map((number) => number + 1)
              .map((number) => {
                return (
                  <span
                    key={number}
                    data-number={number}
                    className={classNames(
                      "gordo-board__number",
                      numberClazzes(number)
                    )}
                    onClick={(evt) => onClickNumberHandler(evt, number)}
                  >
                    {number}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
      <div className="gordo-board__actions__container">
        <span
          role="button"
          className={classNames("icon clickable icon-delete", {
            disabled: bet.disabled,
          })}
          title={t("button.delete")}
          onClick={(evt) => onClickClearHandler(evt)}
        />
        <span
          className={classNames("icon icon-checkmark", {
            show: bet.valid,
          })}
        />
        {randomizable && (
          <span
            role="button"
            className={classNames("icon clickable icon-dice", {
              disabled: bet.disabled,
            })}
            title={t("button.random")}
            onClick={(evt) => onClickRandomHandler(evt)}
          />
        )}
      </div>
    </div>
  );
}

export default GordoBetContainer;
