import FooterPage from "../../../components/footer";

function BlogCategoryFooter() {
  return (
    <div className="blog-category-footer">
      <FooterPage />
    </div>
  );
}

export default BlogCategoryFooter;
