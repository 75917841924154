import { useMemo } from "react";

import moment from "moment";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";
import CountdonwnComponent from "../../../../components/countdown-component";

import { GAME_OID } from "../../../../utils/constants";
import { DATE_FORMAT, isEmpty, nFormatter } from "../../../../utils";

function RaffleNinoSectionTitle(props) {
  const { loadingRaffle, raffle } = props;
  const { t, i18n } = useTranslation();

  const TitleHeader = (props) => {
    const title = useMemo(() => {
      return !isEmpty(raffle)
        ? raffle.title
        : t(`draws.${GAME_OID.LOTERIA_NAC}.title.${10}`);
    }, []);
    return (
      <div className="raffle-nino-section-title__content__header">
        <span className="subtitle">{t("raffle_nino.body.title.play")}</span>
        <h1 className="title">{title}</h1>
      </div>
    );
  };

  const TitleBody = (props) => {
    const pot = useMemo(() => {
      if (!isEmpty(raffle)) {
        if (!isEmpty(raffle.pot) && parseFloat(raffle.pot) > 0) {
          const { amount, symbol } = nFormatter(
            parseFloat(raffle.pot),
            2,
            i18n
          );
          return (
            <span className="title-pot" title={`${amount} ${symbol}`}>
              <span className="amount">{amount}</span>
              <span className="unit">{symbol}</span>
            </span>
          );
        } else {
          return (
            <span className="title-pot" title={t("message.waiting_pot")}>
              {t("message.waiting_pot")}
            </span>
          );
        }
      } else {
        return (
          <span className="title-pot" title={t("message.waiting_pot")}>
            {t("message.waiting_pot")}
          </span>
        );
      }
    }, []);

    return (
      <div className="raffle-nino-section-title__content__body">
        <span className="subtitle">{t("raffle_nino.body.title.pot")}</span>
        {pot}
      </div>
    );
  };

  const TitleFooter = (props) => {
    const closeDate = useMemo(() => {
      return !isEmpty(raffle)
        ? moment(raffle.close_date).format(DATE_FORMAT.US_LONG_FORMAT)
        : "";
    }, []);

    return (
      <div className="raffle-nino-section-title__content__footer">
        <span className="subtitle">
          {t("raffle_nino.body.title.closingDate")}
        </span>
        {!isEmpty(closeDate) ? (
          <CountdonwnComponent className={"title-countdown"} date={closeDate} />
        ) : (
          <span className="title-msg">{t("message.waiting_draw")}</span>
        )}
      </div>
    );
  };

  return (
    <div className="raffle-nino-section-title__container">
      {loadingRaffle ? (
        <div className="raffle-nino-section-title__loading">
          <Loading color={"#ffffff"} />
        </div>
      ) : (
        <div className="raffle-nino-section-title__content">
          <>
            <TitleHeader />
            <TitleBody />
            <TitleFooter />
          </>
        </div>
      )}
    </div>
  );
}

export default RaffleNinoSectionTitle;
