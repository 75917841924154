import { useCallback, useMemo } from "react";

import classNames from "classnames";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import Loading from "../loading";

import { equalsIgnoringCase, isEmpty, isEmptyList } from "../../utils";

function BlogList(props) {
  const {
    loading,
    disabled,
    loadingPosts,
    posts,
    loadingCategories,
    categories,
    loadingSelectedCategory,
    selectedCategory,
    params,
    onChangeParams,
  } = props;

  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const showMoreBtn = useMemo(() => {
    if (
      !loadingSelectedCategory &&
      !isEmptyList(posts) &&
      !isEmpty(selectedCategory)
    ) {
      return selectedCategory.count > posts.length;
    }
    return false;
  }, [loadingSelectedCategory, posts, selectedCategory]);

  const BlogListItem = (props) => {
    const { post, categories } = props;

    const locale = useMemo(() => {
      return pathname.split("/")[1];
    }, []);

    const imgSrc = useMemo(() => {
      return !isEmpty(post) &&
        !isEmpty(post.yoast_head_json) &&
        !isEmptyList(post.yoast_head_json.og_image) &&
        !isEmpty(post.yoast_head_json.og_image[0].url)
        ? post.yoast_head_json.og_image[0].url
        : "";
    }, [post]);

    const postCategory = useMemo(() => {
      if (
        !isEmpty(post) &&
        !isEmptyList(categories) &&
        !isEmptyList(post.categories)
      ) {
        const categoryIds = categories.map((cat) => cat.id);
        const postCategories = post.categories.filter((id) => {
          return categoryIds.includes(id);
        });

        return categories.find((cat) => cat.id === postCategories[0]);
      }
      return null;
    }, [post, categories]);

    const categoryName = useMemo(() => {
      return !isEmpty(postCategory) ? postCategory.name : "";
    }, [postCategory]);

    const getCategoryPostTitle = useCallback(() => {
      return !isEmpty(postCategory) ? postCategory.description : "";
    }, [postCategory]);

    const getCategoryPostLink = useCallback(() => {
      return !disabled
        ? !isEmpty(postCategory)
          ? getLocalePath(
              `${t("routes.public.blog.index")}/${postCategory.slug.replace(
                `${locale}-`,
                ""
              )}/`
            )
          : ""
        : "";
    }, [locale, postCategory]);

    const getPostTitle = useCallback((post) => {
      return !isEmpty(post) &&
        !isEmpty(post.title) &&
        !isEmpty(post.title.rendered)
        ? post.title.rendered
        : "";
    }, []);

    const getPostDate = useCallback(
      (date) => {
        const datePost = moment(date);
        const formatDate = equalsIgnoringCase(locale, "es")
          ? "dddd, DD [de] MMMM [de] YYYY"
          : "Mo MMMM YYYY";
        return datePost.locale(locale).format(formatDate);
      },
      [locale]
    );

    const getPostLink = useCallback(
      (post) => {
        return !disabled
          ? !isEmpty(postCategory) && !isEmpty(post)
            ? getLocalePath(
                `${t("routes.public.blog.index")}/${postCategory.slug.replace(
                  `${locale}-`,
                  ""
                )}/${post.slug.replace(`${locale}-`, "")}/`
              )
            : ""
          : "";
      },
      [locale, postCategory]
    );

    return (
      <div className="blog-list__post__container">
        <div className="blog-list__post__content">
          <div className="row">
            <img
              src={imgSrc}
              width={"100%"}
              height={"300px"}
              alt="image_post"
            />
          </div>
          <div className="row">
            <Link
              className="blog-list__post__title"
              title={getPostTitle(post)}
              to={getPostLink(post)}
            >
              <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            </Link>
          </div>
          <div className="row">
            <div className="blog-list__post__date">
              <span className="icon icon-calendar"></span>
              <span
                className="label"
                dangerouslySetInnerHTML={{
                  __html: getPostDate(post.date),
                }}
              />
            </div>
            <Link
              className="blog-list__post__category"
              title={getCategoryPostTitle()}
              to={getCategoryPostLink()}
            >
              <span className="icon icon-folder"></span>
              <span
                className="label"
                dangerouslySetInnerHTML={{ __html: categoryName }}
              />
            </Link>
          </div>
          <div className="row">
            <div
              className="blog-list__post__excerpt"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            />
          </div>
          <div className="row">
            <Link
              className="blog-list__post__link"
              title={t("button.go_post")}
              to={getPostLink(post)}
            >
              <span className="icon icon-chevron-right"></span>
              <span className="label">{t("button.go_post")}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const BlogListMore = (props) => {
    const { disabled } = props;

    const onClickHandler = useCallback(
      (evt) => {
        if (!disabled) {
          onChangeParams(evt, {
            filter: { ...params.filter },
            pagination: {
              ...params.pagination,
              index: params.pagination.index + 1,
            },
          });
        }
      },
      [disabled]
    );

    return (
      <div
        role="button"
        title={t("button.load_more")}
        className={classNames("btn", { disabled: disabled })}
        onClick={(evt) => onClickHandler(evt)}
      >
        {t("button.load_more")}
      </div>
    );
  };

  const BlogListEmpty = (props) => {
    return <p className="message">{t("message.empty_list")}</p>;
  };

  const loadingComponent = useMemo(() => {
    return loading || loadingCategories || loadingPosts;
  }, [loading, loadingCategories, loadingPosts]);

  return (
    <div className="blog-list__container">
      <div className="blog-list__content">
        <>
          {loadingComponent ? (
            <div className="blog-list__loading">
              <Loading />
            </div>
          ) : (
            <>
              {!isEmptyList(posts) ? (
                <div className="blog-list__posts">
                  <>
                    {posts.map((post, idx) => (
                      <BlogListItem
                        key={idx}
                        post={post}
                        categories={categories}
                      />
                    ))}
                    {showMoreBtn && <BlogListMore disabled={disabled} />}
                  </>
                </div>
              ) : (
                <div className="blog-list__empty">
                  {!loading && <BlogListEmpty />}
                </div>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default BlogList;
