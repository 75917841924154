import FooterPage from "../../../components/footer";

function BonolotoFooter() {
  return (
    <div className="bonoloto-footer">
      <FooterPage />
    </div>
  );
}

export default BonolotoFooter;
