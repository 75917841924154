import { useTranslation } from "react-i18next";

import Loading from "../../../components/loading";
import SyndicateCard from "../../../components/syndicate-card";

function SyndicatesBody(props) {
  const { loadingSyndicates, syndicates, loadingContent, content } = props;

  const { t } = useTranslation();

  const ListSyndicateCards = (props) => {
    return (
      <>
        {syndicates.map((item, idx) => (
          <SyndicateCard key={idx} item={item} />
        ))}
      </>
    );
  };

  return (
    <div className="syndicates-body">
      <div className="syndicates-body__container">
        <h1 className="syndicates-body__title">{t("syndicates.body.title")}</h1>
        <div className="syndicates-body__content">
          {loadingSyndicates ? (
            <div className="syndicates-body__loading">
              <Loading />
            </div>
          ) : (
            <div className="syndicates-body__cards">
              <ListSyndicateCards />
            </div>
          )}
          {loadingContent ? (
            <div className="syndicates-body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className="syndicates-body__info"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SyndicatesBody;
