import * as yup from "yup";

import { NO_WHITE_SPACES_REGEX, PASSWORD_FORMAT_REGEX } from "../utils";

const getUserLoginSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(60, t("validators.maxLength", { value: 60 })),
    password: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(16, t("validators.maxLength", { value: 16 }))
      .matches(NO_WHITE_SPACES_REGEX, t("validators.no_whitespaces"))
      .matches(PASSWORD_FORMAT_REGEX, t("validators.password_mismatch")),
    //"g-recaptcha-response": yup.string().required(t("validators.notEmpty")),
  });
};

export default getUserLoginSchema;
