import FooterPage from "../../../components/footer";

function LandingFooter() {
  return (
    <div className="landing-footer">
      <FooterPage />
    </div>
  );
}

export default LandingFooter;
