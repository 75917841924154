const GAME_OID = {
  LOTERIA_NAC: 1,
  EUROMILLIONS: 2,
  PRIMITIVA: 3,
  BONOLOTO: 4,
  EL_GORDO: 5,
  QUINIELA: 6,
  POWERBALL: 7,
  MEGAMILLIONS: 8,
  SUPERENALOTTO: 9,
  EURODREAMS: 10,
};

const TABS = {
  SINGLE: "single",
  MULTIPLE: "multiple",
};

const PRICE_TABLE = {
  EUROMILLIONS: [
    { specials: 2, numbers: 5, comb_bets: 1 },
    { specials: 2, numbers: 6, comb_bets: 6 },
    { specials: 2, numbers: 7, comb_bets: 21 },
    { specials: 2, numbers: 8, comb_bets: 56 },
    { specials: 2, numbers: 9, comb_bets: 126 },
    { specials: 2, numbers: 10, comb_bets: 252 },
    { specials: 3, numbers: 5, comb_bets: 3 },
    { specials: 3, numbers: 6, comb_bets: 18 },
    { specials: 3, numbers: 7, comb_bets: 63 },
    { specials: 3, numbers: 8, comb_bets: 168 },
    { specials: 3, numbers: 9, comb_bets: 378 },
    { specials: 3, numbers: 10, comb_bets: 756 },
    { specials: 4, numbers: 5, comb_bets: 6 },
    { specials: 4, numbers: 6, comb_bets: 36 },
    { specials: 4, numbers: 7, comb_bets: 126 },
    { specials: 4, numbers: 8, comb_bets: 336 },
    { specials: 4, numbers: 9, comb_bets: 756 },
    { specials: 4, numbers: 10, comb_bets: 1512 },
    { specials: 5, numbers: 5, comb_bets: 10 },
    { specials: 5, numbers: 6, comb_bets: 60 },
    { specials: 5, numbers: 7, comb_bets: 210 },
    { specials: 5, numbers: 8, comb_bets: 560 },
    { specials: 5, numbers: 9, comb_bets: 1260 },
    { specials: 5, numbers: 10, comb_bets: 2520 },
  ],
  PRIMITVA: [
    { numbers: 5, comb_bets: 1 },
    { numbers: 6, comb_bets: 6 },
    { numbers: 7, comb_bets: 7 },
    { numbers: 8, comb_bets: 28 },
    { numbers: 9, comb_bets: 84 },
    { numbers: 10, comb_bets: 210 },
    { numbers: 11, comb_bets: 462 },
  ],
  BONOLOTO: [
    { numbers: 5, comb_bets: 44 },
    { numbers: 6, comb_bets: 1 },
    { numbers: 7, comb_bets: 7 },
    { numbers: 8, comb_bets: 28 },
    { numbers: 9, comb_bets: 84 },
    { numbers: 10, comb_bets: 210 },
    { numbers: 11, comb_bets: 462 },
  ],
  GORDO: [
    { numbers: 5, comb_bets: 1 },
    { numbers: 6, comb_bets: 6 },
    { numbers: 7, comb_bets: 21 },
    { numbers: 8, comb_bets: 56 },
    { numbers: 9, comb_bets: 126 },
    { numbers: 10, comb_bets: 252 },
    { numbers: 11, comb_bets: 462 },
  ],
  QUINIELA: [],
  POWERBALL: [
    { specials: 1, numbers: 5, comb_bets: 1 },
    { specials: 1, numbers: 6, comb_bets: 6 },
    { specials: 1, numbers: 7, comb_bets: 21 },
    { specials: 1, numbers: 8, comb_bets: 56 },
    { specials: 1, numbers: 9, comb_bets: 126 },
    { specials: 1, numbers: 10, comb_bets: 252 },
  ],
  MEGAMILLIONS: [
    { specials: 1, numbers: 5, comb_bets: 1 },
    { specials: 1, numbers: 6, comb_bets: 6 },
    { specials: 1, numbers: 7, comb_bets: 21 },
    { specials: 1, numbers: 8, comb_bets: 56 },
    { specials: 1, numbers: 9, comb_bets: 126 },
    { specials: 1, numbers: 10, comb_bets: 252 },
  ],
  SUPERENALOTTO: [
    { numbers: 6, comb_bets: 1 },
    { numbers: 8, comb_bets: 28 },
    { numbers: 9, comb_bets: 84 },
    { numbers: 10, comb_bets: 210 },
    { numbers: 11, comb_bets: 462 },
    { numbers: 12, comb_bets: 924 },
    { numbers: 14, comb_bets: 3003 },
  ],
  EURODREAMS: [
    { specials: 1, numbers: 5, comb_bets: 35 },
    { specials: 1, numbers: 6, comb_bets: 1 },
    { specials: 1, numbers: 7, comb_bets: 7 },
    { specials: 1, numbers: 8, comb_bets: 28 },
    { specials: 1, numbers: 9, comb_bets: 84 },
    { specials: 1, numbers: 10, comb_bets: 210 },
  ],
};

export { GAME_OID, TABS, PRICE_TABLE };
