import "swiper/css";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

export const Slider = ({ slides }) => {
  return (
    <Swiper spaceBetween={30}>
      {slides.map((slide, idx) => (
        <SwiperSlide key={idx}>
          <img src={slide.image} alt={slide.title} title={slide.title} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
