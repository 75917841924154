import { forwardRef, useCallback, useMemo, useState } from "react";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import {
  Box,
  Fade,
  Badge,
  Stack,
  Button,
  styled,
  Tooltip,
  Typography,
  IconButton,
  tooltipClasses,
  ClickAwayListener,
} from "@mui/material";
import SummaryListCart from "../summary-list-cart";
import SummaryTotalCart from "../summary-total-cart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import { isEmptyList } from "../../../utils";
import { logDebug } from "../../../utils/logger";

const StyledTooltip = styled(
  forwardRef(({ className, ...props }, ref) => (
    <Tooltip ref={ref} {...props} classes={{ popper: className }} />
  ))
)(({ theme }) => ({
  [`& .${tooltipClasses.popper}`]: {
    backgroundColor: "transparent",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: "100%",
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -1,
    top: -3,
    padding: "6px 3px",
    color: theme.palette.primary.main,
    fontWeight: 600,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

function CartButton(props) {
  const { items } = props;

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const numCartItems = useMemo(() => {
    return !isEmptyList(items) ? items.length : 0;
  }, [items]);

  const onClickAwayHandler = useCallback((evt) => {
    logDebug("CartButton", "onClickAwayHandler", { evt: evt });
    setOpen(false);
  }, []);

  const onOpenTooltipHandler = useCallback((evt) => {
    logDebug("CartButton", "onOpenTooltipHandler", { evt: evt });
    setOpen(true);
  }, []);

  const onCloseTooltipHandler = useCallback((evt) => {
    logDebug("CartButton", "onCloseTooltipHandler", { evt: evt });
    setOpen(false);
  }, []);

  const onClickGoToCartHandler = useCallback(
    (evt) => {
      logDebug("CartButton", "onClickGoToCartHandler", { evt: evt });
      setOpen(false);
      navigate(getLocalePath(t("routes.public.cart.index")));
    },
    [navigate, getLocalePath, t]
  );

  const onClickCartButtonHandler = useCallback(
    (evt) => {
      logDebug("CartButton", "onClickCartButtonHandler", { evt: evt });

      if (isUpperMd) {
        setOpen((prev) => !prev);
      } else {
        setOpen(false);
        navigate(getLocalePath(t("routes.public.cart.index")));
      }
    },
    [isUpperMd, navigate, getLocalePath, t]
  );

  const CartTooltip = forwardRef(({ ...props }, ref) => {
    const { items, onClickGoToCart } = props;

    const onClickGoToCartHandler = useCallback(
      (evt) => {
        onClickGoToCart && onClickGoToCart(evt);
      },
      [onClickGoToCart]
    );

    return (
      <Stack ref={ref} spacing={2} sx={{ width: "250px", p: 2 }}>
        <Typography
          variant="body1"
          component="span"
          title={t("cart.body.title")}
          aria-label={t("cart.body.title")}
          sx={{
            width: "100%",
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {t("cart.body.title")}
        </Typography>
        <Box
          sx={{
            maxHeight: "200px",
            overflowY: "auto",
            paddingRight: "8px",
          }}
        >
          <SummaryListCart items={items} />
        </Box>
        <SummaryTotalCart items={items} />
        <Button
          variant="contained"
          fullWidth
          onClick={(evt) => onClickGoToCartHandler(evt)}
        >
          {t("button.cart")}
        </Button>
      </Stack>
    );
  });

  return (
    <StyledTooltip
      open={open}
      arrow={true}
      placement="bottom"
      title={
        <ClickAwayListener onClickAway={(evt) => onClickAwayHandler(evt)}>
          <CartTooltip
            items={items}
            onClickGoToCart={(evt) => onClickGoToCartHandler(evt)}
          />
        </ClickAwayListener>
      }
      disableFocusListener
      disableHoverListener
      TransitionComponent={Fade}
      onOpen={(evt) => onOpenTooltipHandler(evt)}
      onClose={(evt) => onCloseTooltipHandler(evt)}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={(evt) => onClickCartButtonHandler(evt)}
      >
        <StyledBadge max={9} badgeContent={numCartItems}>
          <ShoppingCartOutlinedIcon />
        </StyledBadge>
      </IconButton>
    </StyledTooltip>
  );
}

export default CartButton;
