import React, { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import PrimitivaBetContainer from "./primitiva-bet-container";
import PrimitivaMultipleContainer from "./Primitiva-multiple-container";

import { TABS } from "../../utils/constants";
import { equalsIgnoringCase, isEmpty, isEmptyList } from "../../utils";

function PrimitivaBoard(props) {
  const { tab, onChangeTab, config, onChangeConfig, bets, onChangeBets } =
    props;

  const { t } = useTranslation();

  const isSingleTab = useMemo(
    () => equalsIgnoringCase(tab, TABS.SINGLE),
    [tab]
  );

  const isMultipleTab = useMemo(
    () => equalsIgnoringCase(tab, TABS.MULTIPLE),
    [tab]
  );

  const onClickSingleTabHandler = useCallback(
    (evt) => onChangeTab(evt, TABS.SINGLE),
    [onChangeTab]
  );

  const onClickMultipleTabHandler = useCallback(
    (evt) => onChangeTab(evt, TABS.MULTIPLE),
    [onChangeTab]
  );

  const validateBet = useCallback(
    (numbers, specials, newConfig = null) => {
      const maxNumbers = !isEmpty(newConfig)
        ? newConfig.maxNumbers
        : config.maxNumbers;
      const maxSpecials = !isEmpty(newConfig)
        ? newConfig.maxSpecials
        : config.maxSpecials;

      return maxNumbers > 0 && maxSpecials > 0
        ? maxNumbers === numbers.length && maxSpecials === specials.length
        : false;
    },
    [config]
  );

  const betChangeHandler = useCallback(
    (evt, data, index) => {
      const modifiedBet = {
        numbers: [...data.numbers],
        specials: [...data.specials],
        valid: validateBet(data.numbers, data.specials),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            specials: [],
            valid: false,
            disabled:
              idx === 0
                ? !validateBet(modifiedBet.numbers, modifiedBet.specials)
                : true,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
    },
    [bets, validateBet, onChangeBets]
  );

  const betClearHandler = useCallback(
    (evt, data, index) => {
      const modifiedBet = {
        numbers: [],
        specials: [],
        valid: validateBet([], []),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            specials: [],
            valid: false,
            disabled: true,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
      onChangeConfig(evt, {
        type: equalsIgnoringCase(tab, TABS.SINGLE)
          ? TABS.SINGLE
          : TABS.MULTIPLE,
        num_draws: 1,
        minBets: equalsIgnoringCase(tab, TABS.SINGLE) ? 8 : 1,
        maxBets: equalsIgnoringCase(tab, TABS.SINGLE) ? 25 : 1,
        numbers: 49,
        maxNumbers: equalsIgnoringCase(tab, TABS.SINGLE) ? 6 : 0,
        specials: 10,
        maxSpecials: equalsIgnoringCase(tab, TABS.SINGLE) ? 1 : 1,
        joker: false,
      });
    },
    [tab, bets, validateBet, onChangeBets, onChangeConfig]
  );

  const getRandomNumbers = useCallback((max, length) => {
    let count = 0;
    const result = [];
    const array = [...Array(max).keys()].map((item) => item + 1);
    while (count < length) {
      const random = array[Math.floor(Math.random() * array.length)];
      if (!result.includes(random)) {
        result.push(random);
        count++;
      }
    }
    return result;
  }, []);

  const betRandomHandler = useCallback(
    (evt, data, index) => {
      const randomNumbers = getRandomNumbers(config.numbers, config.maxNumbers);
      let randomSpecials = [];
      if (isEmptyList(bets[0].specials)) {
        randomSpecials = getRandomNumbers(config.specials, config.maxSpecials);
      } else {
        randomSpecials = [...bets[0].specials];
      }
      const modifiedBet = {
        numbers: randomNumbers,
        specials: randomSpecials,
        valid: validateBet(randomNumbers, randomSpecials),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            specials: [...randomSpecials],
            valid: false,
            disabled: idx !== 0,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
    },
    [
      config.numbers,
      config.maxNumbers,
      config.specials,
      config.maxSpecials,
      bets,
      validateBet,
      getRandomNumbers,
      onChangeBets,
    ]
  );

  const betRandomNumbersHandler = useCallback(
    (evt, data, index) => {
      const randomNumbers = getRandomNumbers(data.numbers, data.maxNumbers);
      let randomSpecials = [];
      if (isEmptyList(bets[0].specials)) {
        randomSpecials = getRandomNumbers(data.specials, data.maxSpecials);
      } else {
        randomSpecials = [...bets[0].specials];
      }
      const modifiedBet = {
        numbers: randomNumbers,
        specials: randomSpecials,
        valid: validateBet(randomNumbers, randomSpecials, data),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            specials: [],
            valid: false,
            disabled: idx !== 0,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
      onChangeConfig(evt, data);
    },
    [bets, validateBet, getRandomNumbers, onChangeBets, onChangeConfig]
  );

  const configChangeHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  return (
    <div className="primitiva-board__container">
      <div className="primitiva-board__content">
        <div className="primitiva-board__nav-tabs__container">
          <div
            className={classNames("primitiva-board__nav-tab", {
              active: isSingleTab,
            })}
            onClick={(evt) => onClickSingleTabHandler(evt)}
          >
            {t("primitiva.body.tabs.single")}
          </div>
          <div
            className={classNames("primitiva-board__nav-tab", {
              active: isMultipleTab,
            })}
            onClick={(evt) => onClickMultipleTabHandler(evt)}
          >
            {t("primitiva.body.tabs.multiple")}
          </div>
        </div>

        <div
          className={classNames("primitiva-board__grid__container", {
            fadeIn: isSingleTab,
            hide: !isSingleTab,
          })}
        >
          <div className="primitiva-board__grid__content single">
            {bets.map((bet, idx) => (
              <PrimitivaBetContainer
                key={idx + 1}
                index={idx + 1}
                bet={bet}
                config={config}
                randomizable
                onClear={(evt, data) => betClearHandler(evt, data, idx)}
                onChange={(evt, data) => betChangeHandler(evt, data, idx)}
                onRandom={(evt, data) => betRandomHandler(evt, data, idx)}
              />
            ))}
          </div>
        </div>
        <div
          className={classNames("primitiva-board__grid__container", {
            fadeIn: isMultipleTab,
            hide: !isMultipleTab,
          })}
        >
          <div className="primitiva-board__grid__content multiple">
            {bets.map((bet, idx) => (
              <React.Fragment key={idx + 1}>
                <PrimitivaBetContainer
                  index={idx + 1}
                  bet={bet}
                  config={config}
                  onClear={(evt, data) => betClearHandler(evt, data, idx)}
                  onChange={(evt, data) => betChangeHandler(evt, data, idx)}
                />
                <PrimitivaMultipleContainer
                  config={config}
                  onChangeConfig={(evt, data) => configChangeHandler(evt, data)}
                  onRandomNumbers={(evt, data) =>
                    betRandomNumbersHandler(evt, data, idx)
                  }
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrimitivaBoard;
