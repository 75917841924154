import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../utils";

function EuromillionsMultipleContainer(props) {
  const { config, onChangeConfig, onRandomNumbers, onRandomSpecials } = props;

  const { t } = useTranslation();

  const NumberCombinationContainer = (props) => {
    const { maxNumbers, onChangeMaxNumbers } = props;

    const combinatories = [
      { maxNumbers: 5, bets: 5 },
      { maxNumbers: 6, bets: 6 },
      { maxNumbers: 7, bets: 21 },
      { maxNumbers: 8, bets: 56 },
      { maxNumbers: 9, bets: 126 },
      { maxNumbers: 10, bets: 252 },
    ];

    const isSelected = useCallback(
      (value) => maxNumbers === value,
      [maxNumbers]
    );

    const onClickHandler = useCallback(
      (evt, value) => onChangeMaxNumbers(evt, value),
      [onChangeMaxNumbers]
    );

    return (
      <>
        <div className="euromillions-board__config__body__title-container">
          <div className="euromillions-board__config__body__title-content">
            {t("euromillions.body.config.body.numbers.title")}
          </div>
        </div>
        <div className="euromillions-board__config__body__combination-container">
          <div className="euromillions-board__config__body__combination-content numbers">
            {combinatories.map((value, idx) => {
              return (
                <div
                  key={`numbers ${idx + 1}`}
                  className={classNames(
                    "euromillions-board__config__body__combination-item",
                    { selected: isSelected(value.maxNumbers) }
                  )}
                  onClick={(evt) => onClickHandler(evt, value.maxNumbers)}
                >
                  <div className="euromillions-board__config__body__combination-item__header">
                    <div className="number">{value.maxNumbers}</div>
                  </div>
                  <div className="euromillions-board__config__body__combination-item__body">
                    <div className="numbers">
                      {t(
                        "euromillions.body.config.body.numbers.num_numbersWithCount",
                        { count: value.maxNumbers }
                      )}
                    </div>
                    <div className="bets">
                      {t(
                        "euromillions.body.config.body.numbers.num_betsWithCount",
                        { count: value.bets }
                      )}
                    </div>
                  </div>
                  <div className="euromillions-board__config__body__combination-item__footer">
                    <div className="icon icon-dice"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const SpecialCombinationContainer = (props) => {
    const { maxSpecials, onChangeMaxSpecials } = props;

    const combinatories = [
      { maxSpecials: 2, bets: 1 },
      { maxSpecials: 3, bets: 3 },
      { maxSpecials: 4, bets: 6 },
      { maxSpecials: 5, bets: 10 },
    ];

    const isSelected = useCallback(
      (value) => maxSpecials === value,
      [maxSpecials]
    );

    const onClickHandler = useCallback(
      (evt, value) => onChangeMaxSpecials(evt, value),
      [onChangeMaxSpecials]
    );

    return (
      <>
        <div className="euromillions-board__config__body__title-container">
          <div className="euromillions-board__config__body__title-content">
            {t("euromillions.body.config.body.specials.title")}
          </div>
        </div>
        <div className="euromillions-board__config__body__combination-container">
          <div className="euromillions-board__config__body__combination-content specials">
            {combinatories.map((value, idx) => {
              return (
                <div
                  key={`specials ${idx + 1}`}
                  className={classNames(
                    "euromillions-board__config__body__combination-item",
                    { selected: isSelected(value.maxSpecials) }
                  )}
                  onClick={(evt) => onClickHandler(evt, value.maxSpecials)}
                >
                  <div className="euromillions-board__config__body__combination-item__header">
                    <div className="special">{value.maxSpecials}</div>
                  </div>
                  <div className="euromillions-board__config__body__combination-item__body">
                    <div className="specials">
                      {t(
                        "euromillions.body.config.body.specials.num_specialsWithCount",
                        { count: value.maxSpecials }
                      )}
                    </div>
                    <div className="bets">
                      {t(
                        "euromillions.body.config.body.specials.num_betsWithCount",
                        { count: value.bets }
                      )}
                    </div>
                  </div>
                  <div className="euromillions-board__config__body__combination-item__footer">
                    <div className="icon icon-star"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const EuroMillionsConfigHeader = (props) => {
    return (
      <div className="euromillions-board__config__header">
        <div className="euromillions-board__config__header__title-container">
          <div className="euromillions-board__config__header__title-content">
            {t("euromillions.body.config.header.title")}
          </div>
        </div>
        <div className="euromillions-board__config__header__subtitle-container">
          <div className="euromillions-board__config__header__subtitle-content">
            {t("euromillions.body.config.header.subtitle")}
          </div>
        </div>
      </div>
    );
  };

  const EuromillionsConfigBody = (props) => {
    const { maxNumbers, onChangeMaxNumbers, maxSpecials, onChangeMaxSpecials } =
      props;

    const onChangeMaxNumbersHandler = useCallback(
      (evt, value) => onChangeMaxNumbers(evt, value),
      [onChangeMaxNumbers]
    );

    const onChangeMaxSpecialsHandler = useCallback(
      (evt, value) => onChangeMaxSpecials(evt, value),
      [onChangeMaxSpecials]
    );

    return (
      <div className="euromillions-board__config__body">
        <NumberCombinationContainer
          maxNumbers={maxNumbers}
          onChangeMaxNumbers={(evt, value) =>
            onChangeMaxNumbersHandler(evt, value)
          }
        />
        <SpecialCombinationContainer
          maxSpecials={maxSpecials}
          onChangeMaxSpecials={(evt, value) =>
            onChangeMaxSpecialsHandler(evt, value)
          }
        />
      </div>
    );
  };

  const onChangeMaxNumbersHandler = useCallback(
    (evt, value) => {
      const modifiedConfig = { ...config, maxNumbers: value };
      onChangeConfig(evt, modifiedConfig);
      onRandomNumbers(evt, modifiedConfig);
    },
    [config, onChangeConfig, onRandomNumbers]
  );

  const onChangeMaxSpecialsHandler = useCallback(
    (evt, value) => {
      const modifiedConfig = { ...config, maxSpecials: value };
      onChangeConfig(evt, modifiedConfig);
      onRandomSpecials(evt, modifiedConfig);
    },
    [config, onChangeConfig, onRandomSpecials]
  );

  const maxNumbers = useMemo(() => {
    return !isEmpty(config) ? config.maxNumbers : 0;
  }, [config]);

  const maxSpecials = useMemo(() => {
    return !isEmpty(config) ? config.maxSpecials : 0;
  }, [config]);

  return (
    <div className="euromillions-board__config">
      <EuroMillionsConfigHeader />
      <EuromillionsConfigBody
        maxNumbers={maxNumbers}
        onChangeMaxNumbers={(evt, value) =>
          onChangeMaxNumbersHandler(evt, value)
        }
        maxSpecials={maxSpecials}
        onChangeMaxSpecials={(evt, value) =>
          onChangeMaxSpecialsHandler(evt, value)
        }
      />
    </div>
  );
}

export default EuromillionsMultipleContainer;
