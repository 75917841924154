import { createSlice } from "@reduxjs/toolkit";

import { logDebug } from "../../utils/logger";
import { equalsIgnoringCase, isEmpty } from "../../utils";

const migrations = {
  0: (state) => {
    return {
      ...state,
      cart: {
        items: [],
      },
    };
  },
};
const currentVersion = 0;

const slice = createSlice({
  name: "cart",
  initialState: {
    cart: {
      items: [],
    },
  },
  reducers: {
    setItems: (state, action) => {
      state.cart.items = action.payload;
      logDebug("cartReducer", "setItems", { items: state.cart.items });
    },
    clearItems: (state, action) => {
      state.cart.items = [];
      logDebug("cartReducer", "clearItems", { items: state.cart.items });
    },
    addItem: (state, action) => {
      state.cart.items = [...state.cart.items, action.payload];
      logDebug("cartReducer", "addItem", { items: state.cart.items });
    },
    removeItem: (state, action) => {
      const { id } = action.payload;
      if (!isEmpty(id)) {
        const idx = state.cart.items.findIndex((i) =>
          equalsIgnoringCase(id, i.id)
        );
        if (idx >= 0) {
          const cartItems = [...state.cart.items];
          cartItems.splice(idx, 1);
          state.cart.items = cartItems;
        }
      }
      logDebug("cartReducer", "removeItem", { items: state.cart.items });
    },
  },
});

export const { setItems, clearItems, addItem, removeItem } = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
