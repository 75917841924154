import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath.js";

import { Link, useLocation } from "react-router-dom";
import BlogPost from "../../../components/blog-post/index.jsx";
import BlogFilter from "../../../components/blog-filter";

import { isEmpty } from "../../../utils/index.js";
import Loading from "../../../components/loading/index.jsx";

function BlogPostBody(props) {
  const {
    loading,
    disabled,
    loadingPosts,
    post,
    loadingCategories,
    categories,
    loadingParentCategory,
    parentCategory,
    loadingSelectedCategory,
    selectedCategory,
  } = props;

  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const Breadcrumb = (props) => {
    const locale = useMemo(() => {
      return !isEmpty(pathname) ? pathname.split("/")[1] : "";
    }, []);

    const breadCrumbInit = useMemo(() => {
      return t("routes.public.landing.index");
    }, []);

    const breadcrumbBlog = useMemo(() => {
      return t("routes.public.blog.index");
    }, []);

    const breadcrumbPostCategory = useMemo(() => {
      return !isEmpty(selectedCategory) && !isEmpty(selectedCategory.name)
        ? selectedCategory.name
        : "";
    }, []);

    const breadcrumbPostTitle = useMemo(() => {
      return !isEmpty(post) &&
        !isEmpty(post.title) &&
        !isEmpty(post.title.rendered)
        ? post.title.rendered
        : "";
    }, []);

    const categorySlug = useMemo(() => {
      return !isEmpty(selectedCategory) && !isEmpty(selectedCategory.slug)
        ? selectedCategory.slug.replace(`${locale}-`, "")
        : "";
    }, [locale]);

    const loadingComponent = useMemo(() => {
      return loading || loadingSelectedCategory || loadingPosts;
    }, []);

    return (
      <div className="breadcrumb__container">
        <>
          {loadingComponent ? (
            <div className="breadcrumb__loading">
              <Loading />
            </div>
          ) : (
            <div className="breadcrumb__content">
              <Link
                className="breadcrumb__item link"
                title={breadCrumbInit}
                to={getLocalePath("/")}
              >
                <span className="icon icon-home" />
                <span className="label">{breadCrumbInit}</span>
              </Link>
              <span className="breadcrumb__separator icon icon-chevron-right" />
              <Link
                className="breadcrumb__item link"
                title={breadcrumbBlog}
                to={`${getLocalePath(t("routes.public.blog.index"))}/`}
              >
                <span className="label">{breadcrumbBlog}</span>
              </Link>
              <span className="breadcrumb__separator icon icon-chevron-right" />
              <Link
                className="breadcrumb__item link"
                title={breadcrumbPostCategory}
                to={`${getLocalePath(
                  t("routes.public.blog.index")
                )}/${categorySlug}/`}
              >
                <span className="label">{breadcrumbPostCategory}</span>
              </Link>
              <span className="breadcrumb__separator icon icon-chevron-right" />
              <div className="breadcrumb__item" title={breadcrumbPostTitle}>
                {breadcrumbPostTitle}
              </div>
            </div>
          )}
        </>
      </div>
    );
  };

  return (
    <div className="blog-post-body">
      <div className="blog-post-body__container">
        <h1 className="blog-post-body__title">{t("blog.body.title")}</h1>
        <div className="blog-post-body__content"></div>
        <Breadcrumb />
        <div className="blog-post-body__grid-container">
          <BlogPost
            loading={loading}
            disabled={disabled}
            loadingPost={loadingPosts}
            post={post}
          />
          <BlogFilter
            loading={loading}
            disabled={disabled}
            showCategoriesFilter
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
            showLastPosts
            showLottery
          />
        </div>
      </div>
    </div>
  );
}

export default BlogPostBody;
