import HeaderPage from "../../../components/header";

function ResultsHeader() {
  return (
    <div className="results-header">
      <div className="results-header__container">
        <div className="results-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default ResultsHeader;
