import HeaderPage from "../../../components/header";

function TemplateSubPageHeader() {
  return (
    <div className="template-subpage__header">
      <div className="template-subpage__header__container">
        <div className="template-subpage__header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default TemplateSubPageHeader;
