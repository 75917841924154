import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../hooks/wp-json-api/useGetPageByPath";

import SEO from "../components/seo";
import Loading from "../components/loading";
import { errorToast } from "../components/toast";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { logDebug, logWarn } from "../utils/logger";
import { isEmpty, equalsIgnoringCase, isEmptyObject } from "../utils";

function GeoBlockPage() {
  const [page, setPage] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("geoblock.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("geoblock.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  const pageRequest = useMemo(() => {
    return { path: pathname };
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageByPath(pageRequest);
  }, [pageRequest, getPageByPath]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("GeoBlockPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "GeoBlockPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("GeoBlockPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  return (
    <>
      <SEO url={url} pageTitle={title} pageDesc={description} />
      <Grid
        container
        spacing={2}
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loadingPageByPath ? (
            <div className="geoblock-body__loading">
              <Loading />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GeoBlockPage;
