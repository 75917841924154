import { useCallback, useMemo } from "react";

import Loading from "../../loading";
import SearchInput from "../../search-input";

function SearchBlogFilter(props) {
  const { loading, disabled, value, onBlur, onChange, onClear } = props;

  const onBlurHandler = useCallback(
    (evt, value) => {
      if (!disabled && onBlur) {
        onBlur(value);
      }
    },
    [disabled, onBlur]
  );

  const onChangeHandler = useCallback(
    (evt, value) => {
      if (!disabled && onChange) {
        onChange(value);
      }
    },
    [disabled, onChange]
  );

  const onClearHandler = useCallback(
    (evt, value) => {
      if (!disabled && onClear) {
        onClear(value);
      }
    },
    [disabled, onClear]
  );

  const onKeyDownHandler = useCallback(
    (evt, value) => {
      if (!disabled) {
        if (evt.key === "Enter") {
          evt.target.blur();
        }
      }
    },
    [disabled]
  );

  const loadingComponent = useMemo(() => {
    return loading;
  }, [loading]);

  return (
    <div className="blog-filter__search__container">
      <>
        {loadingComponent ? (
          <div className="blog-filter__search__loading">
            <Loading />
          </div>
        ) : (
          <div className="blog-filter__search__content">
            <SearchInput
              value={value}
              clearable
              disabled={disabled}
              onBlur={(evt, value) => onBlurHandler(evt, value)}
              onChange={(evt, value) => onChangeHandler(evt, value)}
              onClear={(evt, value) => onClearHandler(evt, value)}
              onKeyDown={(evt, value) => onKeyDownHandler(evt, value)}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default SearchBlogFilter;
