import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetCategoriesByParams from "../../hooks/wp-json-api/useGetCategoriesByParams";

import SEO from "../../components/seo";
import { errorToast } from "../../components/toast";
import BlogCategoryBody from "./blog-category-body";
import BlogCategoryHeader from "./blog-category-header";
import BlogCategoryFooter from "./blog-category-footer";

import {
  isEmpty,
  isEmptyList,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../utils";
import { logError } from "../../utils/logger";

function BlogCategoryPage() {
  const [metadata, setMetadata] = useState(null);
  const [categories, setCategories] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataParentCategory,
    error: errorParentCategory,
    loading: loadingParentCategory,
    getCategoriesByParams: getParentCategoryHandler,
  } = useGetCategoriesByParams();

  const {
    data: dataCategories,
    error: errorCategories,
    loading: loadingCategories,
    getCategoriesByParams,
  } = useGetCategoriesByParams();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : !isEmptyObject(selectedCategory)
      ? t("blog_category.title", { category: selectedCategory.name })
      : "";
  }, [metadata, selectedCategory, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmptyObject(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : !isEmptyObject(selectedCategory)
      ? t("blog_category.description", { category: selectedCategory.name })
      : "";
  }, [metadata, selectedCategory, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const locale = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[1] : "";
  }, [pathname]);

  const categorySlug = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[3] : "";
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getParentCategoryHandler({ parent: 0, search: null, slug: locale });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingParentCategory) {
      if (!isEmpty(errorParentCategory)) {
        logError("BlogCategoryPage", "getParentCategoryHandler", {
          error: errorParentCategory,
          msg: t(`errors.${errorParentCategory}`, t("errors.default")),
        });
        errorToast(
          "BlogCategoryPage - getParentCategoryHandler",
          errorParentCategory,
          t(`errors.${errorParentCategory}`, t("errors.default"))
        );
      } else if (!isEmptyList(dataParentCategory)) {
        setParentCategory({ ...dataParentCategory[0] });
      }
    }
  }, [loadingParentCategory, dataParentCategory, errorParentCategory, t]);

  useEffect(() => {
    if (!isEmpty(parentCategory) && !isEmpty(parentCategory.id)) {
      getCategoriesByParams({
        parent: parentCategory.id,
        search: null,
        slug: null,
      });
    }
  }, [parentCategory, getCategoriesByParams]);

  useEffect(() => {
    if (!loadingCategories) {
      if (!isEmpty(errorCategories)) {
        logError("BlogCategoryPage", "getCategoriesByParams", {
          error: errorCategories,
          msg: t(`errors.${errorCategories}`, t("errors.default")),
        });
        errorToast(
          "BlogCategoryPage - getCategoriesByParams",
          errorCategories,
          t(`errors.${errorCategories}`, t("errors.default"))
        );
      } else if (!isEmpty(dataCategories)) {
        setCategories(dataCategories);
        const category = dataCategories.find((cat) =>
          equalsIgnoringCase(cat.slug.replace(`${locale}-`, ""), categorySlug)
        );
        setSelectedCategory(category);
        setMetadata(category.yoast_head_json);
      }
    }
  }, [
    loadingCategories,
    dataCategories,
    errorCategories,
    categorySlug,
    locale,
    t,
  ]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="blog-category">
        <div className="blog-category__container">
          <BlogCategoryHeader />
          <BlogCategoryBody
            loading={false}
            disabled={false}
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
            loadingSelectedCategory={loadingCategories}
            selectedCategory={selectedCategory}
          />
          <BlogCategoryFooter />
        </div>
      </div>
    </>
  );
}

export default BlogCategoryPage;
