import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import {
  setOpenLoginDialog,
  setOpenRegisterDialog,
} from "../../../redux/reducers/uiReducer";
import { removeUser } from "../../../redux/reducers/userReducer";

import {
  Menu,
  Stack,
  Button,
  Divider,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { successToast } from "../../toast";
import CartButton from "../../cart/cart-button";

import Icon from "@mui/material/Icon";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import tabs from "../../../pages/MyAccountPage/my-account-body/tabs";

import { logDebug } from "../../../utils/logger";
import { equalsIgnoringCase, isEmpty, moneyFormatter } from "../../../utils";

function HeaderButtons() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();

  const { cart } = useSelector((state) => state.cart);
  const { items } = cart;

  const { user } = useSelector((state) => state.user);
  const { user_name, session_token, untransferable_balance } = user;

  const { isOpenLoginDialog, isOpenRegisterDialog } = useSelector(
    (state) => state.ui
  );

  const isProduction = useMemo(() => {
    return equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV);
  }, []);

  const amount = useMemo(() => {
    if (!isEmpty(untransferable_balance)) {
      return moneyFormatter(
        untransferable_balance,
        i18n.resolvedLanguage,
        "EUR"
      );
    }
    return moneyFormatter(0, i18n.resolvedLanguage, "EUR");
  }, [untransferable_balance, i18n.resolvedLanguage]);

  const onClickRegisterHandler = useCallback(
    (evt) => {
      logDebug("HeaderButtons", "onClickRegisterHandler", {
        evt: evt,
      });
      dispatch(setOpenRegisterDialog(!isOpenRegisterDialog));
    },
    [isOpenRegisterDialog, dispatch]
  );

  const onClickLoginHandler = useCallback(
    (evt) => {
      logDebug("HeaderButtons", "onClickLoginHandler", {
        evt: evt,
      });
      dispatch(setOpenLoginDialog(!isOpenLoginDialog));
    },
    [isOpenLoginDialog, dispatch]
  );

  const onClickWalletHandler = useCallback((evt) => {
    logDebug("HeaderButtons", "onClickWalletHandler", { evt: evt });
  }, []);

  const onClickMenuHandler = useCallback((evt) => {
    logDebug("HeaderButtons", "onClickMenuHandler", { evt: evt });
    setAnchorEl(evt.currentTarget);
  }, []);

  const onCloseMenuHandler = useCallback((evt) => {
    logDebug("HeaderButtons", "onCloseMenuHandler", { evt: evt });
    setAnchorEl(null);
  }, []);

  const onClickMenuItemHandler = useCallback(
    (evt, option) => {
      logDebug("HeaderButtons", "onClickMenuItemHandler", {
        evt: evt,
        option: option,
      });
      navigate({
        ...(!isEmpty(option) && { hash: t(option) }),
        pathname: getLocalePath(t("routes.private.index")),
      });
      onCloseMenuHandler(evt);
    },
    [navigate, getLocalePath, t, onCloseMenuHandler]
  );

  const onClickMenuItemLogOutHandler = useCallback(
    (evt) => {
      logDebug("HeaderButtons", "onClickMenuItemLogOutHandler", {
        evt: evt,
        option: "logout",
      });
      dispatch(removeUser());
      navigate(getLocalePath("/"));
      successToast(
        "HeaderButtons",
        "message.log_out",
        t("message.log_out", t("message.default"))
      );
    },
    [dispatch, navigate, getLocalePath, t]
  );

  return (
    <div className="header-buttons">
      <div className="header-buttons__container">
        <div className="header-buttons__content">
          <Stack direction={"row"} spacing={2}>
            {isEmpty(session_token) ? (
              <>
                {!isProduction && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<AccountCircleIcon color="primary" />}
                      title={t("header.toolbar.register")}
                      aria-label={t("header.toolbar.register")}
                      onClick={(evt) => onClickRegisterHandler(evt)}
                    >
                      {t("header.toolbar.register")}
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<LoginIcon color="contrastText" />}
                      title={t("header.toolbar.login")}
                      aria-label={t("header.toolbar.login")}
                      onClick={(evt) => onClickLoginHandler(evt)}
                    >
                      {t("header.toolbar.login")}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {!isProduction && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<WalletIcon />}
                      title={t("header.toolbar.wallet_tooltip", {
                        amount: amount,
                      })}
                      aria-label={t("header.toolbar.wallet_tooltip", {
                        amount: amount,
                      })}
                      onClick={(evt) => onClickWalletHandler(evt)}
                    >
                      {t("header.toolbar.wallet", { amount: amount })}
                    </Button>
                    <Button
                      variant="outlined"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      aria-controls={open ? "basic-menu" : undefined}
                      startIcon={<AccountCircleIcon color="primary" />}
                      key={t("header.toolbar.user", {
                        user_name: user_name,
                      })}
                      title={t("header.toolbar.user", {
                        user_name: user_name,
                      })}
                      aria-label={t("header.toolbar.user", {
                        user_name: user_name,
                      })}
                      onClick={(evt) => onClickMenuHandler(evt)}
                    >
                      {user_name}
                    </Button>
                  </>
                )}
                <Menu
                  id="basic-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  open={open}
                  onClose={(evt) => onCloseMenuHandler(evt)}
                >
                  {tabs.map((tab) => {
                    return (
                      <MenuItem
                        key={t(tab.label)}
                        title={t(tab.title)}
                        aria-label={t(tab.label)}
                        onClick={(evt) =>
                          onClickMenuItemHandler(evt, tab.anchor)
                        }
                      >
                        <ListItemIcon>
                          <Icon color="primary">{tab.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography color="primary" variant="subheading">
                            {t(tab.label)}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    key={"header.toolbar.logout"}
                    title={t("header.toolbar.logout")}
                    aria-label={t("header.toolbar.logout")}
                    onClick={(evt) => onClickMenuItemLogOutHandler(evt)}
                  >
                    <ListItemIcon>
                      <LogoutIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color="primary" variant="subheading">
                        {t("header.toolbar.logout")}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
            <CartButton items={items} />
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default HeaderButtons;
