import { useCallback, useMemo } from "react";

import Loading from "../../../../components/loading";
import PowerballBoard from "../../../../components/powerball-board";
import { isEmptyList } from "../../../../utils";

function PowerballSectionBoard(props) {
  const {
    loading,
    draws,
    tab,
    onChangeTab,
    config,
    onChangeConfig,
    bets,
    onChangeBets,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const onChangeConfigHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  const onChangeBetsHandler = useCallback(
    (evt, data) => onChangeBets(evt, data),
    [onChangeBets]
  );

  const onChangeTabHandler = useCallback(
    (evt, data) => onChangeTab(evt, data),
    [onChangeTab]
  );

  return (
    <div className="powerball-section-board__container">
      {loading ? (
        <div className="powerball-section-board__loading">
          <Loading />
        </div>
      ) : (
        <div className="powerball-section-board__content">
          <PowerballBoard
            tab={tab}
            onChangeTab={(evt, data) => onChangeTabHandler(evt, data)}
            config={config}
            onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
            bets={bets}
            onChangeBets={(evt, data) => onChangeBetsHandler(evt, data)}
          />
        </div>
      )}
    </div>
  );
}

export default PowerballSectionBoard;
