import HeaderPage from "../../../components/header";

function EuromillionsHeader() {
  return (
    <div className="euromillions-header">
      <div className="euromillions-header__container">
        <div className="euromillions-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default EuromillionsHeader;
