import { createSlice } from "@reduxjs/toolkit";
import { logDebug } from "../../utils/logger";

const slice = createSlice({
  name: "ui",
  initialState: {
    // Common
    isBlur: false,
    isLoading: false,
    isGeoAllowed: undefined,
    // Dialog
    isOpenLoginDialog: false,
    isOpenRegisterDialog: false,
    isOpenForgotPassDialog: false,
  },
  reducers: {
    setBlur: (state, action) => {
      state.is_blur = action.payload;
      logDebug("uiReducer", "setBlur", { isBlur: state.isBlur });
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      logDebug("uiReducer", "setLoading", { isLoading: state.isLoading });
    },
    setGeoAllowed: (state, action) => {
      state.isGeoAllowed = action.payload;
      logDebug("uiReducer", "setGeoAllowed", {
        isGeoAllowed: state.isGeoAllowed,
      });
    },
    setOpenLoginDialog: (state, action) => {
      state.isOpenLoginDialog = action.payload;
      logDebug("uiReducer", "setOpenLoginDialog", {
        isOpenLoginDialog: state.isOpenLoginDialog,
      });
    },
    setOpenRegisterDialog: (state, action) => {
      state.isOpenRegisterDialog = action.payload;
      logDebug("uiReducer", "setOpenRegisterDialog", {
        isOpenRegisterDialog: state.isOpenRegisterDialog,
      });
    },
    setOpenForgotPassDialog: (state, action) => {
      state.isOpenForgotPassDialog = action.payload;
      logDebug("uiReducer", "setOpenForgotPassDialog", {
        isOpenForgotPassDialog: state.isOpenForgotPassDialog,
      });
    },
  },
});

export const {
  setBlur,
  setLoading,
  setGeoAllowed,
  setOpenLoginDialog,
  setOpenRegisterDialog,
  setOpenForgotPassDialog,
} = slice.actions;
export default slice.reducer;
