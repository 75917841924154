import { useEffect, useMemo, useState } from "react";

import moment from "moment";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";
import useDrawsStock from "../../hooks/international-api/draws/useDrawsStock";
import useDrawsNationalStock from "../../hooks/international-api/draws/useDrawsNationalStock";

import SEO from "../../components/seo";
import RaffleNavidadBody from "./raffle-navidad-body";
import RaffleNavidadHeader from "./raffle-navidad-header";
import RaffleNavidadFooter from "./raffle-navidad-footer";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../utils";
import { errorToast } from "../../components/toast";
import { logDebug, logError, logWarn } from "../../utils/logger";
import { TYPE_REQUEST } from "../../hooks/international-api/utils";

function RaffleNavidadPage() {
  const [page, setPage] = useState(null);
  const [raffle, setRaffle] = useState(null);
  const [stock, setStock] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const { user } = useSelector((state) => state.user);

  const {
    data: dataDrawsStock,
    error: errorDrawsStock,
    loading: loadingDrawsStock,
    drawsStock,
  } = useDrawsStock();

  const {
    data: dataDrawsNationalStock,
    error: errorDrawsNationalStock,
    loading: loadingDrawsNationalStock,
    drawsNationalStock,
  } = useDrawsNationalStock();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("raffle_navidad.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("raffle_navidad.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  const pageRequest = useMemo(() => {
    return { path: pathname };
  }, [pathname]);

  const drawRequest = useMemo(() => {
    return {
      header: {
        type_request: !isEmptyObject(user)
          ? TYPE_REQUEST.USERNAME
          : TYPE_REQUEST.ANONIM,
        email: !isEmptyObject(user)
          ? user.email
          : process.env.REACT_APP_API_USER,
        token: !isEmptyObject(user)
          ? user.session_token
          : process.env.REACT_APP_API_PASS,
      },
    };
  }, [user]);

  const drawNationalRequest = useMemo(() => {
    return {
      header: {
        type_request: !isEmptyObject(user)
          ? TYPE_REQUEST.USERNAME
          : TYPE_REQUEST.ANONIM,
        email: !isEmptyObject(user)
          ? user.email
          : process.env.REACT_APP_API_USER,
        token: !isEmptyObject(user)
          ? user.session_token
          : process.env.REACT_APP_API_PASS,
      },
    };
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageByPath(pageRequest);
    if (!isEmptyObject(state) && !isEmptyObject(state.item)) {
      setRaffle({ ...state.item });
      drawsNationalStock({
        ...drawNationalRequest,
        body: {
          lotterydraw_oid: state.item.lotterydraw_oid,
        },
      });
    } else {
      drawsStock(drawRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingDrawsStock) {
      if (errorDrawsStock) {
        logError("RaffleNavidadPage", "drawsStock", {
          error: errorDrawsStock,
          msg: t(`errors.${errorDrawsStock}`, t("errors.default")),
        });
        errorToast(
          "RaffleNavidadPage - drawsStock",
          errorDrawsStock,
          t(`errors.${errorDrawsStock}`, t("errors.default"))
        );
      } else if (
        !isEmpty(dataDrawsStock) &&
        !isEmptyList(dataDrawsStock.raffles)
      ) {
        const raffles = dataDrawsStock.raffles;
        const raffleNavidad = raffles.find((raffle) => {
          const drawDate = moment(raffle.draw_date, DATE_FORMAT.US_LONG_FORMAT);
          const day = drawDate.date();
          const month = drawDate.month();
          const navidad = day === 22 && month === 11;
          return navidad && !["300", "600"].includes(raffle.price);
        });
        logDebug("RaffleNavidadPage", "drawsStock", {
          raffle: raffleNavidad,
        });
        setRaffle(raffleNavidad);
        drawsNationalStock({
          ...drawNationalRequest,
          body: {
            lotterydraw_oid: raffleNavidad.lotterydraw_oid,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDrawsStock, dataDrawsStock, errorDrawsStock, t]);

  useEffect(() => {
    if (!loadingDrawsNationalStock) {
      if (errorDrawsNationalStock) {
        logError("RaffleNavidadPage", "drawsNationalStock", {
          error: errorDrawsNationalStock,
          msg: t(`errors.${errorDrawsNationalStock}`, t("errors.default")),
        });
        errorToast(
          "RaffleNavidadPage - drawsNationalStock",
          errorDrawsNationalStock,
          t(`errors.${errorDrawsNationalStock}`, t("errors.default"))
        );
      } else if (!isEmptyList(dataDrawsNationalStock)) {
        setStock(dataDrawsNationalStock);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataDrawsNationalStock,
    errorDrawsNationalStock,
    loadingDrawsNationalStock,
    t,
  ]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("RaffleNavidadPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "RaffleNavidadPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("RaffleNavidadPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="raffle-navidad">
        <div className="raffle-navidad__container">
          <RaffleNavidadHeader />
          <RaffleNavidadBody
            loadingRaffle={loadingDrawsNationalStock}
            raffle={raffle}
            loadingStock={loadingDrawsNationalStock}
            stock={stock}
            loadingContent={loadingPageByPath}
            content={content}
          />
          <RaffleNavidadFooter />
        </div>
      </div>
    </>
  );
}

export default RaffleNavidadPage;
