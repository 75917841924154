import FooterPage from "../../../components/footer";

function HelpFooter() {
  return (
    <div className="help-footer">
      <FooterPage />
    </div>
  );
}

export default HelpFooter;
