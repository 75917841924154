import { useCallback, useEffect, useMemo, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../hooks/useGetLocalePath.js";

import {
  setBets,
  clearBets,
  setDraw,
  clearDraw,
  setConfig,
  clearConfig,
} from "../../../redux/reducers/superenalottoReducer.js";
import { addItem } from "../../../redux/reducers/cartReducer.js";

import { ThemeProvider } from "@emotion/react";
import { superenalotto as theme } from "../../../theme";

import { successToast } from "../../../components/toast/index.js";
import SuperenalottoSectionInfo from "./superenalotto-section-info";
import SuperenalottoSectionTitle from "./superenalotto-section-title";
import SuperenalottoSectionBoard from "./superenalotto-section-board";
import SuperenalottoSectionAddons from "./superenalotto-section-addons";
import SuperenalottoSectionSummary from "./superenalotto-section-summary";

import {
  isEmptyList,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../../utils/index.js";
import { logDebug } from "../../../utils/logger.js";
import { TABS, PRICE_TABLE } from "../../../utils/constants.js";

function SuperenalottoBody(props) {
  const { loadingDraws, draws, loadingContent, content } = props;

  const [tab, setTab] = useState(TABS.SINGLE);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { config, bets } = useSelector((state) => state.superenalotto);

  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const price = useMemo(() => {
    const validBets = bets.filter((bet) => bet.valid);
    if (
      !isEmptyObject(draw) &&
      !isEmptyObject(config) &&
      !isEmptyList(validBets)
    ) {
      const comb_bets = PRICE_TABLE.SUPERENALOTTO.find(
        (i) => i.numbers === config.maxNumbers
      ).comb_bets;
      const superstar = config.superstar ? 100 : 0;
      return validBets.length * comb_bets * parseInt(draw.price) + superstar;
    }
    return 0;
  }, [bets, draw, config]);

  const onChangeConfigHandler = useCallback(
    (evt, data) => {
      logDebug("SuperenalottoBody", "onChangeConfigHandler", {
        evt: evt,
        config: data,
      });
      dispatch(setConfig(data));
    },
    [dispatch]
  );

  const onChangeBetsHandler = useCallback(
    (evt, data) => {
      logDebug("SuperenalottoBody", "onChangeBetsHandler", {
        evt: evt,
        bets: data,
      });
      dispatch(setBets(data));
    },
    [dispatch]
  );

  const onChangeTabHandler = useCallback(
    (evt, data) => {
      const selectedConfig = {
        num_draws: 1,
        type: equalsIgnoringCase(data, TABS.SINGLE)
          ? TABS.SINGLE
          : TABS.MULTIPLE,
        minBets: equalsIgnoringCase(data, TABS.SINGLE) ? 6 : 1,
        maxBets: equalsIgnoringCase(data, TABS.SINGLE) ? 25 : 1,
        numbers: 90,
        maxNumbers: equalsIgnoringCase(data, TABS.SINGLE) ? 6 : 0,
        superstar: false,
      };
      const selectedBets = [];
      [...Array(selectedConfig.minBets).keys()].forEach((bet, idx) => {
        selectedBets.push({
          numbers: [],
          valid: false,
          disabled: idx !== 0,
        });
      });
      logDebug("SuperenalottoBody", "onChangeTabHandler", {
        evt: evt,
        tab: data,
        config: selectedConfig,
        bets: selectedBets,
      });
      setTab(data);
      dispatch(setDraw(draw));
      dispatch(setBets(selectedBets));
      dispatch(setConfig(selectedConfig));
    },
    [draw, dispatch]
  );

  const onClickAddToCartHandler = useCallback(
    (evt) => {
      logDebug("SuperenalottoBody", "onClickAddToCartHandler", {
        evt: evt,
        tab: tab,
        bets: bets,
        draws: draws,
        config: config,
      });
      draws
        .slice(0, config.subscription ? 1 : config.num_draws)
        .forEach((draw) => {
          dispatch(
            addItem({
              id: uuidv4(),
              bets: bets
                .filter((bet) => bet.valid)
                .map((bet) => ({ id: uuidv4(), ...bet })),
              draw: draw,
              config: config,
              price: price,
            })
          );
        });
      successToast(
        "SuperenalottoBody",
        "message.success_add_cart",
        t("message.success_add_cart", t("message.default"))
      );
      dispatch(clearBets());
      dispatch(clearDraw());
      dispatch(clearConfig());
      navigate(getLocalePath(t("routes.public.cart.index")));
    },
    [tab, bets, draws, config, price, navigate, getLocalePath, dispatch, t]
  );

  useEffect(() => {
    if (!isEmptyObject(draw)) {
      dispatch(setDraw(draw));
    }
  }, [draw, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className="superenalotto-body">
        <div className="superenalotto-body__container">
          <div className="superenalotto-body__content">
            <div className="superenalotto-body__content__row board">
              <SuperenalottoSectionTitle loading={loadingDraws} draws={draws} />
              <SuperenalottoSectionBoard
                loading={loadingDraws}
                draws={draws}
                tab={tab}
                onChangeTab={(evt, data) => onChangeTabHandler(evt, data)}
                config={config}
                onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
                bets={bets}
                onChangeBets={(evt, data) => onChangeBetsHandler(evt, data)}
              />
              <SuperenalottoSectionAddons
                loading={loadingDraws}
                draws={draws}
                config={config}
                onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
              />
              <SuperenalottoSectionSummary
                loading={loadingDraws}
                draws={draws}
                config={config}
                bets={bets}
                price={price}
                onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
                onClickAddToCart={(evt) => onClickAddToCartHandler(evt)}
              />
            </div>
            <div className="superenalotto-body__content__row info">
              <SuperenalottoSectionInfo
                loading={loadingContent}
                content={content}
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default SuperenalottoBody;
