import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Landing
import LandingPage from "../pages/LandingPage";

// Lotteries
import LotteriesPage from "../pages/LotteriesPage";
import EuromillionsPage from "../pages/EuromillionsPage";
import BonolotoPage from "../pages/BonolotoPage";
import PrimitivaPage from "../pages/PrimitivaPage";
import GordoPage from "../pages/GordoPage";
import QuinielaPage from "../pages/QuinielaPage";
import EurodreamsPage from "../pages/EurodreamsPage";
import SuperenalottoPage from "../pages/SuperenalottoPage";
import MegamillionsPage from "../pages/MegamillionsPage";
import PowerballPage from "../pages/PowerballPage";
import TemplateDrawSubPage from "../pages/TemplateDrawSubPage";

// Raffles
import RafflesPage from "../pages/RafflesPage";
import RaffleThursdayPage from "../pages/RaffleThursdayPage";
import RaffleSaturdayPage from "../pages/RaffleSaturdayPage";
import RaffleExtraPage from "../pages/RaffleExtraPage";
import RaffleNavidadPage from "../pages/RaffleNavidadPage";
import RaffleNinoPage from "../pages/RaffleNinoPage";

// Syndicates
import SyndicatesPage from "../pages/SyndicatesPage";
import SyndicatePage from "../pages/SyndicatePage";

// Cart
import CartPage from "../pages/CartPage";

// Blog
import BlogPage from "../pages/BlogPage";
import BlogCategoryPage from "../pages/BlogCategoryPage";
import BlogPostPage from "../pages/BlogPostPage";

// Results
import ResultsPage from "../pages/ResultsPage";

// Legal Pages
import HelpPage from "../pages/HelpPage";
import LegalPage from "../pages/LegalPage";
import PrivacyPage from "../pages/PrivacyPage";
import CookiesPage from "../pages/CookiesPage";

// Error
import ErrorPage from "../pages/ErrorPage";
import GeoBlockPage from "../pages/GeoBlockPage.jsx";

// My Account
import MyAccountPage from "../pages/MyAccountPage";

// Utils
import LocaleLayout from "../pages/LocaleLayout";
import GeoInfoLayout, {
  loader as geoInfoLayoutLoader,
} from "../pages/GeoInfoLayout";

function Router(props) {
  const { children } = props;

  const { t } = useTranslation();

  const router = createBrowserRouter([
    {
      path: "",
      element: <GeoInfoLayout />,
      loader: geoInfoLayoutLoader,
      children: [
        {
          path: ":locale",
          element: <LocaleLayout />,
          children: [
            {
              path: t("routes.public.geoblock.index"),
              element: <GeoBlockPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.lotteries.index"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <LotteriesPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.euromillions"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <EuromillionsPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.bonoloto"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <BonolotoPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.primitiva"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <PrimitivaPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.gordo"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <GordoPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.quiniela"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <QuinielaPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.eurodreams"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <EurodreamsPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.superenalotto"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <SuperenalottoPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.megamillions"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <MegamillionsPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.lotteries.powerball"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <TemplateDrawSubPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <PowerballPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.syndicates.index"),
              element: <Outlet />,
              children: [
                {
                  path: ":slug",
                  element: <SyndicatePage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <SyndicatesPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.raffles.index"),
              element: <Outlet />,
              children: [
                {
                  path: t("routes.public.raffles.4"),
                  element: <RaffleThursdayPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: t("routes.public.raffles.6"),
                  element: <RaffleSaturdayPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: t("routes.public.raffles.8"),
                  element: <RaffleExtraPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: t("routes.public.raffles.9"),
                  element: <RaffleNavidadPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: t("routes.public.raffles.10"),
                  element: <RaffleNinoPage />,
                  errorElement: <ErrorPage />,
                },
                {
                  path: "",
                  element: <RafflesPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.blog.index"),
              element: <Outlet />,
              children: [
                {
                  path: ":category",
                  element: <Outlet />,
                  children: [
                    {
                      path: ":slug",
                      element: <BlogPostPage />,
                      errorElement: <ErrorPage />,
                    },
                    {
                      path: "",
                      element: <BlogCategoryPage />,
                      errorElement: <ErrorPage />,
                    },
                  ],
                },
                {
                  path: "",
                  element: <BlogPage />,
                  errorElement: <ErrorPage />,
                },
              ],
            },
            {
              path: t("routes.public.results.index"),
              element: <ResultsPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.cart.index"),
              element: <CartPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.help.index"),
              element: <HelpPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.legal.index"),
              element: <LegalPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.privacy.index"),
              element: <PrivacyPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.public.cookies.index"),
              element: <CookiesPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: t("routes.private.index"),
              element: <MyAccountPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: "",
              element: <LandingPage />,
              errorElement: <ErrorPage />,
            },
            {
              path: "*",
              element: <ErrorPage />,
              errorElement: <ErrorPage />,
            },
          ],
        },
        {
          path: "",
          element: <Outlet />,
          errorElement: <ErrorPage />,
        },
        {
          path: "*",
          element: <ErrorPage />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router}>{children}</RouterProvider>;
}

export default Router;
