import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import { isEmptyList, moneyFormatter } from "../../../utils";

function SummaryTotalCart(props) {
  const { items } = props;

  const { t, i18n } = useTranslation();

  const totalAmount = useMemo(() => {
    if (!isEmptyList(items)) {
      return moneyFormatter(
        items.reduce((sum, item) => sum + parseInt(item.price), 0) / 100,
        i18n.resolvedLanguage,
        items[0].draw.price_currency
      );
    }
    return moneyFormatter(0, i18n.resolvedLanguage, "EUR");
  }, [items, i18n.resolvedLanguage]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="body1"
        component="span"
        title={t("cart.body.summary.total", { total: totalAmount })}
        aria-label={t("cart.body.summary.total", { total: totalAmount })}
        sx={{
          width: "100%",
          textAlign: "right",
          fontSize: "20px",
          fontWeight: 600,
        }}
      >
        {t("cart.body.summary.total", { total: totalAmount })}
      </Typography>
    </Box>
  );
}

export default SummaryTotalCart;
