import { createSlice } from "@reduxjs/toolkit";

import { TABS } from "../../utils/constants";
import { logDebug } from "../../utils/logger";

const slice = createSlice({
  name: "primitiva",
  initialState: {
    bets: [
      { numbers: [], specials: [], valid: false, disabled: false },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
      { numbers: [], specials: [], valid: false, disabled: true },
    ],
    draw: {
      class: "",
      close_date: "",
      description: "",
      draw_date: "",
      game_oid: 3,
      is_enabled: false,
      logo: "",
      lotterydraw_oid: "",
      pot: "",
      pot_currency: "",
      pot_value: "",
      price: "",
      price_currency: "",
      title: "",
    },
    config: {
      num_draws: 1,
      type: TABS.SINGLE,
      numbers: 49,
      maxNumbers: 6,
      specials: 10,
      maxSpecials: 1,
      joker: false,
      subscription: false,
    },
  },
  reducers: {
    setBets: (state, action) => {
      state.bets = action.payload;
      logDebug("primitivaReducer", "setBets", { bets: state.bets });
    },
    clearBets: (state, action) => {
      state.bets = [
        { numbers: [], specials: [], valid: false, disabled: false },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
        { numbers: [], specials: [], valid: false, disabled: true },
      ];
      logDebug("primitivaReducer", "clearBets", { bets: state.bets });
    },
    setDraw: (state, action) => {
      state.draw = action.payload;
      logDebug("primitivaReducer", "setDraw", { draw: state.draw });
    },
    clearDraw: (state, action) => {
      state.draw = {
        class: "",
        close_date: "",
        description: "",
        draw_date: "",
        game_oid: 3,
        is_enabled: false,
        logo: "",
        lotterydraw_oid: "",
        pot: "",
        pot_currency: "",
        pot_value: "",
        price: "",
        price_currency: "",
        title: "",
      };
      logDebug("primitivaReducer", "clearDraw", { draw: state.draw });
    },
    setConfig: (state, action) => {
      state.config = action.payload;
      logDebug("primitivaReducer", "setConfig", { config: state.config });
    },
    clearConfig: (state, action) => {
      state.config = {
        num_draws: 1,
        type: TABS.SINGLE,
        numbers: 49,
        maxNumbers: 6,
        specials: 10,
        maxSpecials: 1,
        joker: false,
        subscription: false,
      };
      logDebug("primitivaReducer", "clearConfig", { config: state.config });
    },
  },
});

export const {
  setBets,
  clearBets,
  setDraw,
  clearDraw,
  setConfig,
  clearConfig,
} = slice.actions;
export default slice.reducer;
