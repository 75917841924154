import { createSlice } from "@reduxjs/toolkit";

import { logDebug } from "../../utils/logger";

const migrations = {
  0: (state) => {
    return {
      ...state,
      user: {},
    };
  },
};
const currentVersion = 0;

const slice = createSlice({
  name: "user",
  initialState: {
    user: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      logDebug("userReducer", "setUser", state.user);
    },
    removeUser: (state, action) => {
      state.user = {};
      logDebug("userReducer", "removeUser", state.user);
    },
  },
});

export const { setUser, removeUser } = slice.actions;
export { migrations, currentVersion };
export default slice.reducer;
