import FooterPage from "../../../components/footer";

function PowerballFooter() {
  return (
    <div className="powerball-footer">
      <FooterPage />
    </div>
  );
}

export default PowerballFooter;
