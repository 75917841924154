import FooterPage from "../../../components/footer";

function MegamillionsFooter() {
  return (
    <div className="megamillions-footer">
      <FooterPage />
    </div>
  );
}

export default MegamillionsFooter;
