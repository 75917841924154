import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";
import { isEmpty, isEmptyList, isEmptyObject } from "../../../../utils";

function PrimitivaSectionAddons(props) {
  const { loading, draws, config, onChangeConfig, bets, onChangeBets } = props;

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const PrimitivaRefundContainer = (props) => {
    const { bets, onChangeBets, config } = props;

    const specialsMemo = useMemo(() => {
      return !isEmptyList(bets) &&
        !isEmptyObject(bets[0]) &&
        !isEmptyList(bets[0].specials)
        ? bets[0].specials
        : [];
    }, [bets]);

    const isSelected = useCallback((listItem, item) => {
      return listItem.includes(item);
    }, []);

    const specialClazzes = useCallback(
      (special) => {
        return {
          selected: isSelected(specialsMemo, special),
        };
      },
      [specialsMemo, isSelected]
    );

    const isValidBet = useCallback(
      (numbers, specials) => {
        return config.maxNumbers > 0 && config.maxSpecials > 0
          ? config.maxNumbers === numbers.length &&
              config.maxSpecials === specials.length
          : false;
      },
      [config.maxNumbers, config.maxSpecials]
    );

    const onClickSpecialHandler = useCallback(
      (evt, special) => {
        let modifiedSpecials = [...specialsMemo];
        if (!isEmptyList(specialsMemo)) {
          if (!specialsMemo.includes(special)) {
            if (specialsMemo.length < config.maxSpecials) {
              modifiedSpecials = [...specialsMemo, special];
            }
          } else {
            const idx = specialsMemo.findIndex((item) => item === special);
            modifiedSpecials = [
              ...specialsMemo.slice(0, idx),
              ...specialsMemo.slice(idx + 1),
            ];
          }
        } else {
          if (specialsMemo.length < config.maxSpecials) {
            modifiedSpecials = [...specialsMemo, special];
          }
        }
        const modifiedBets = bets.map((bet) => {
          return {
            ...bet,
            valid: isValidBet(bet.numbers, modifiedSpecials),
            specials: modifiedSpecials,
          };
        });
        onChangeBets(evt, modifiedBets);
      },
      [bets, specialsMemo, config.maxSpecials, isValidBet, onChangeBets]
    );

    return (
      <div className="primitiva-section-addons__refund__container">
        <div className="primitiva-section-addons__refund__content">
          <div className="primitiva-section-addons__refund__title">
            {t("primitiva.body.addons.refund")}
          </div>
          <div className="primitiva-section-addons__refund__list__container">
            <div className="primitiva-section-addons__refund__list__content">
              {[...Array(config.specials).keys()].map((special) => {
                return (
                  <div
                    key={special}
                    data-type="refund"
                    data-value={special}
                    className={classNames(
                      "primitiva-section-addons__refund__list-item",
                      specialClazzes(special)
                    )}
                    onClick={(evt) => onClickSpecialHandler(evt, special)}
                  >
                    {special}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PrimitivaJokerContainer = (props) => {
    const { config, onChangeConfig } = props;

    const joker = useMemo(() => {
      return !isEmptyObject(config) && !isEmpty(config.joker)
        ? config.joker
        : false;
    }, [config]);

    const onClickJokerHandler = useCallback(
      (evt) => {
        onChangeConfig(evt, {
          ...config,
          joker: !joker,
        });
      },
      [config, joker, onChangeConfig]
    );

    return (
      <div className="primitiva-section-addons__joker__container">
        <div className="primitiva-section-addons__joker__content">
          <div
            className="primitiva-section-addons__joker__value"
            onClick={(evt) => onClickJokerHandler(evt)}
          >
            <input
              type="checkbox"
              defaultChecked={joker}
              className="primitiva-section-addons__joker__input"
            />
            <div className="primitiva-section-addons__joker__label">
              {t("primitiva.body.addons.play_joker")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeBetsHandler = useCallback(
    (evt, data) => onChangeBets(evt, data),
    [onChangeBets]
  );

  const onChangeConfigHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  return (
    <div className="primitiva-section-addons__container">
      {loading ? (
        <div className="primitiva-section-addons__loading">
          <Loading />
        </div>
      ) : (
        <div className="primitiva-section-addons__content">
          <PrimitivaRefundContainer
            bets={bets}
            onChangeBets={(evt, data) => onChangeBetsHandler(evt, data)}
            config={config}
          />
          <PrimitivaJokerContainer
            config={config}
            onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
          />
        </div>
      )}
    </div>
  );
}

export default PrimitivaSectionAddons;
