import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";
import { isEmpty, isEmptyList } from "../../../../utils";

function GordoSectionAddons(props) {
  const { loading, draws, config, bets, onChangeBets } = props;

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const GordoKeyNumberContainer = (props) => {
    const { bets, onChangeBets, config } = props;

    const specialsMemo = useMemo(() => {
      return !isEmptyList(bets) &&
        !isEmpty(bets[0]) &&
        !isEmptyList(bets[0].specials)
        ? bets[0].specials
        : [];
    }, [bets]);

    const isSelected = useCallback((listItem, item) => {
      return listItem.includes(item);
    }, []);

    const specialClazzes = useCallback(
      (special) => {
        return {
          selected: isSelected(specialsMemo, special),
        };
      },
      [specialsMemo, isSelected]
    );

    const isValidBet = useCallback(
      (numbers, specials) => {
        return config.maxNumbers > 0 && config.maxSpecials > 0
          ? config.maxNumbers === numbers.length &&
              config.maxSpecials === specials.length
          : false;
      },
      [config.maxNumbers, config.maxSpecials]
    );

    const onClickSpecialHandler = useCallback(
      (evt, special) => {
        let modifiedSpecials = [...specialsMemo];
        if (!isEmptyList(specialsMemo)) {
          if (!specialsMemo.includes(special)) {
            if (specialsMemo.length < config.maxSpecials) {
              modifiedSpecials = [...specialsMemo, special];
            }
          } else {
            const idx = specialsMemo.findIndex((item) => item === special);
            modifiedSpecials = [
              ...specialsMemo.slice(0, idx),
              ...specialsMemo.slice(idx + 1),
            ];
          }
        } else {
          if (specialsMemo.length < config.maxSpecials) {
            modifiedSpecials = [...specialsMemo, special];
          }
        }
        const modifiedBets = bets.map((bet) => {
          return {
            ...bet,
            valid: isValidBet(bet.numbers, modifiedSpecials),
            specials: modifiedSpecials,
          };
        });
        onChangeBets(evt, modifiedBets);
      },
      [bets, specialsMemo, config.maxSpecials, isValidBet, onChangeBets]
    );

    return (
      <div className="gordo-section-addons__key-number__container">
        <div className="gordo-section-addons__key-number__content">
          <div className="gordo-section-addons__key-number__title">
            {t("gordo.body.addons.key_number")}
          </div>
          <div className="gordo-section-addons__key-number__list__container">
            <div className="gordo-section-addons__key-number__list__content">
              {[...Array(config.specials).keys()].map((special) => {
                return (
                  <div
                    key={special}
                    data-type="key_number"
                    data-value={special}
                    className={classNames(
                      "gordo-section-addons__key-number__list-item",
                      specialClazzes(special)
                    )}
                    onClick={(evt) => onClickSpecialHandler(evt, special)}
                  >
                    {special}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeBetsHandler = useCallback(
    (evt, data) => onChangeBets(evt, data),
    [onChangeBets]
  );

  return (
    <div className="gordo-section-addons__container">
      {loading ? (
        <div className="gordo-section-addons__loading">
          <Loading />
        </div>
      ) : (
        <div className="gordo-section-addons__content">
          <GordoKeyNumberContainer
            bets={bets}
            onChangeBets={(evt, data) => onChangeBetsHandler(evt, data)}
            config={config}
          />
        </div>
      )}
    </div>
  );
}

export default GordoSectionAddons;
