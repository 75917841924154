import { useCallback, useMemo } from "react";

import moment from "moment";

import { useTranslation } from "react-i18next";

import Media from "react-media";
import LotterySlide from "./lottery-slide";
import { Navigation } from "swiper/modules";
import Loading from "../../../components/loading";
import { Swiper, SwiperSlide } from "swiper/react";
import LotteryCard from "../../../components/lottery-card";

import { DATE_FORMAT, isEmpty, isEmptyList } from "../../../utils";

function BodyLotterySection(props) {
  const { loading, draws } = props;

  const { t } = useTranslation();

  const isClosedDate = useCallback((item) => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    return moment(now).isAfter(moment(closeDate));
  }, []);

  const queriesMemo = useMemo(() => {
    return {
      xs: "(min-width: 0px) and (max-width: 576px)",
      sm: "(min-width: 577px) and (max-width: 768px)",
      md: "(min-width: 769px) and (max-width: 992px)",
      lg: "(min-width: 993px) and (max-width: 1200px)",
      xl: "(min-width: 1201px) and (max-width: 1400px)",
      xxl: "(min-width: 1401px)",
    };
  }, []);

  const lotteries = useMemo(() => {
    if (!loading) {
      if (!isEmpty(draws) && !isEmptyList(draws.lotteries)) {
        const gameOidList = [];
        return draws.lotteries
          .filter((lottery) => lottery.is_enabled && !isClosedDate(lottery))
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date))
          .filter((lottery) => {
            if (!gameOidList.includes(lottery.game_oid)) {
              gameOidList.push(lottery.game_oid);
              return true;
            }
            return false;
          })
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
      }
    }
    return [];
  }, [loading, draws, isClosedDate]);

  const TitleComponent = (props) => {
    return (
      <div className="lottery-title__container">
        <div className="lottery-title__content">
          <Media queries={queriesMemo}>
            {(matches) =>
              [matches.xs, matches.sm].some((i) => i === true) ? (
                <h2>{t("landing.body.section.lottery.title")}</h2>
              ) : null
            }
          </Media>
        </div>
      </div>
    );
  };

  const CardsComponent = (props) => {
    const getLotteriesByOid = useCallback((draws, game_oid) => {
      if (!isEmptyList(draws) && !isEmpty(game_oid)) {
        return draws
          .filter(
            (draw) =>
              draw.is_enabled &&
              !isClosedDate(draw) &&
              draw.game_oid === game_oid
          )
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
      }
      return [];
    }, []);

    return (
      <div className="lottery-cards__container">
        <div className="lottery-cards__content">
          {!isEmptyList(lotteries) && (
            <Media queries={queriesMemo}>
              {(matches) =>
                [matches.xs, matches.sm].some((i) => i === true) ? (
                  lotteries.map((item, idx) => {
                    return (
                      <LotteryCard
                        key={idx}
                        item={item}
                        draws={getLotteriesByOid(
                          draws.lotteries,
                          item.game_oid
                        )}
                      />
                    );
                  })
                ) : (
                  <Swiper
                    modules={[Navigation]}
                    navigation={true}
                    slidesPerView={5}
                    spaceBetween={150}
                    breakpoints={{
                      768: {
                        slidesPerView: 5,
                        spaceBetween: 8,
                      },
                      992: {
                        slidesPerView: 6,
                        spaceBetween: 5,
                      },
                      1200: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    {lotteries.map((item, idx) => {
                      return (
                        <SwiperSlide key={idx}>
                          <LotterySlide
                            item={item}
                            draws={getLotteriesByOid(
                              draws.lotteries,
                              item.game_oid
                            )}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )
              }
            </Media>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="body-lottery-section__container">
      <div className="body-lottery-section__content">
        <TitleComponent />
        {loading ? <Loading /> : <CardsComponent />}
      </div>
    </div>
  );
}

export default BodyLotterySection;
