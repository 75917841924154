import FooterPage from "../../../components/footer";

function SuperenalottoFooter() {
  return (
    <div className="superenalotto-footer">
      <FooterPage />
    </div>
  );
}

export default SuperenalottoFooter;
