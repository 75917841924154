import { useMemo } from "react";
import { isEmpty } from "../../utils";
import Loading from "../loading";

function BlogPost(props) {
  const { loading, loadingPost, post } = props;

  const selectedPost = useMemo(() => {
    return !isEmpty(post) &&
      !isEmpty(post.content) &&
      !isEmpty(post.content.rendered)
      ? post.content.rendered
      : "";
  }, [post]);

  const loadingComponent = useMemo(() => {
    return loading || loadingPost;
  }, [loading, loadingPost]);

  return (
    <div className="blog-post-component__container">
      {loadingComponent ? (
        <div className="blog-post-component__loading">
          <Loading />
        </div>
      ) : (
        <div
          className="blog-post-component__content"
          dangerouslySetInnerHTML={{ __html: selectedPost }}
        />
      )}
    </div>
  );
}

export default BlogPost;
