import { useCallback, useMemo } from "react";

import moment from "moment";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";
import CountdownComponent from "../countdown-component";

import { GAME_OID } from "../../utils/constants";
import { DATE_FORMAT, isEmpty, isEmptyList, nFormatter } from "../../utils";

function LotteryCard(props) {
  const { item, draws } = props;

  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const closeDate = useMemo(() => {
    return moment(item.close_date).format(DATE_FORMAT.US_LONG_FORMAT);
  }, [item.close_date]);

  const disabled = useMemo(() => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    const expired = moment(now).isAfter(moment(closeDate));
    return !item.is_enabled || expired;
  }, [item.is_enabled, item.close_date]);

  const getLotteryPath = useCallback(
    (game_oid) => {
      switch (game_oid) {
        case GAME_OID.EUROMILLIONS:
          return getLocalePath(`${t("routes.public.lotteries.euromillions")}`);
        case GAME_OID.PRIMITIVA:
          return getLocalePath(`${t("routes.public.lotteries.primitiva")}`);
        case GAME_OID.BONOLOTO:
          return getLocalePath(`${t("routes.public.lotteries.bonoloto")}`);
        case GAME_OID.EL_GORDO:
          return getLocalePath(`${t("routes.public.lotteries.gordo")}`);
        //case GAME_OID.QUINIELA:
        //  return getLocalePath(`${t("routes.public.lotteries.quiniela")}`);
        case GAME_OID.POWERBALL:
          return getLocalePath(`${t("routes.public.lotteries.powerball")}`);
        case GAME_OID.MEGAMILLIONS:
          return getLocalePath(`${t("routes.public.lotteries.megamillions")}`);
        case GAME_OID.SUPERENALOTTO:
          return getLocalePath(`${t("routes.public.lotteries.superenalotto")}`);
        case GAME_OID.EURODREAMS:
          return getLocalePath(`${t("routes.public.lotteries.eurodreams")}`);
        default:
          return "";
      }
    },
    [getLocalePath, t]
  );

  const logoMemo = useMemo(() => {
    return !isEmpty(item.logo)
      ? item.logo
      : "../../../assets/img/lottery_default.png";
  }, [item.logo]);

  const linkMemo = useMemo(() => {
    return disabled ? "" : getLotteryPath(item.game_oid);
  }, [disabled, item.game_oid, getLotteryPath]);

  const PotContainerComponent = (props) => {
    const Pot = (props) => {
      const { amount, symbol } = nFormatter(parseFloat(item.pot), 2, i18n);
      return (
        <span className="pot" title={`${amount} ${symbol}`}>
          <span className="amount">{amount}</span>
          <span className="unit">{symbol}</span>
        </span>
      );
    };

    const PotEurodreams = (props) => {
      const amount = 20000;
      const symbol = t("lottery_card.per_month");
      const formatter = Intl.NumberFormat(i18n.language, { style: "decimal" });

      return (
        <span
          className="pot pot-eurodreams"
          title={`${formatter.format(amount)}${symbol}`}
        >
          <span className="amount">{formatter.format(amount)}</span>
          <span className="unit">{symbol}</span>
        </span>
      );
    };

    const WaitingPot = (props) => {
      return (
        <span className="waiting" title={t("message.waiting_pot")}>
          {t("message.waiting_pot")}
        </span>
      );
    };

    return (
      <div className="lottery-card__body__pot__content">
        <>
          {item.game_oid === GAME_OID.EURODREAMS ? (
            <PotEurodreams />
          ) : (
            <>
              {!isEmpty(item.pot) && parseFloat(item.pot) > 0 ? (
                <Pot />
              ) : (
                <WaitingPot />
              )}
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <Link
      className={classNames("lottery-card__container", {
        [`${item.class}-lottery-card`]: item.class,
        disabled: disabled,
      })}
      title={item.title}
      to={linkMemo}
      state={{ draws: !isEmptyList(draws) ? [...draws] : [] }}
    >
      <div className="lottery-card__header">
        <div className="lottery-card__header__logo__container">
          <img
            className="logo"
            src={logoMemo}
            alt={item.title}
            title={item.title}
            loading="eager"
            width="auto"
            height="auto"
          />
        </div>
      </div>
      <div className="lottery-card__body">
        <div className="lottery-card__body__pot__container">
          <PotContainerComponent />
        </div>
        <div className="lottery-card__body__countdown__container">
          <span className="icon icon-clock"></span>
          <CountdownComponent date={closeDate} />
        </div>
      </div>
      <div className="lottery-card__footer">
        <div className="lottery-card__footer__button__container">
          <div className="button" title={t("button.play")}>
            {t("button.play")}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default LotteryCard;
