import { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import { isEmptyList, moneyFormatter } from "../../../utils";

function SummaryListCart(props) {
  const { items } = props;

  const { i18n } = useTranslation();

  const getFormattedValue = useCallback(
    (value, currency) => {
      return moneyFormatter(value, i18n.resolvedLanguage, currency);
    },
    [i18n.resolvedLanguage]
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {!isEmptyList(items) &&
        items.map((item) => (
          <Box
            key={item.id}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              component="span"
              title={item.draw.title}
              aria-label={item.draw.title}
            >
              {item.draw.title}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              title={getFormattedValue(
                item.price / 100,
                item.draw.price_currency
              )}
              aria-label={getFormattedValue(
                item.price / 100,
                item.draw.price_currency
              )}
              sx={{
                display: "flex",
                flex: 1,
                textAlign: "right",
                alignItems: "baseline",
                "&::before": {
                  content: '""',
                  borderBottom: (theme) =>
                    `1px dotted ${theme.palette.common.black}`,
                  flex: 1,
                  margin: "0 10px",
                },
              }}
            >
              {getFormattedValue(item.price / 100, item.draw.price_currency)}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default SummaryListCart;
