import { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";
import "moment/locale/es";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useGetPostByParams from "../../../hooks/wp-json-api/useGetPostByParams";

import { errorToast, warnToast } from "../../toast";
import Loading from "../../loading";

import { logError, logWarn } from "../../../utils/logger";
import { equalsIgnoringCase, isEmpty, isEmptyList } from "../../../utils";

function BlogLastPostsFilter(props) {
  const {
    loading,
    disabled,
    loadingCategories,
    categories,
    loadingParentCategory,
    parentCategory,
    maxPosts,
  } = props;

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const locale = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[1] : "";
  }, [pathname]);

  const [posts, setPosts] = useState(null);
  const paramPosts = useMemo(() => {
    if (!loadingParentCategory && !isEmpty(parentCategory)) {
      return {
        filter: { category_id: parentCategory.id, search: null, slug: null },
        pagination: { index: 1, pageSize: maxPosts },
      };
    }
    return null;
  }, [loadingParentCategory, parentCategory, maxPosts]);
  const {
    loading: loadingPosts,
    data: dataPosts,
    error: errorPosts,
    getPostByParams,
  } = useGetPostByParams();
  useEffect(() => {
    if (!disabled && !isEmpty(paramPosts)) {
      getPostByParams(paramPosts);
    }
  }, [disabled, paramPosts, getPostByParams]);
  useEffect(() => {
    if (!disabled && !loadingPosts) {
      if (errorPosts) {
        if (equalsIgnoringCase(errorPosts, "rest_post_invalid_page_number")) {
          logWarn("BlogLastPostsFilter", "getPostByParams", {
            error: errorPosts,
            msg: t("errors.no_more_posts", t("errors.default")),
          });
          warnToast(
            "BlogLastPostsFilter - getPostByParams",
            errorPosts,
            t("errors.no_more_posts", t("errors.default"))
          );
        } else {
          logError("BlogLastPostsFilter", "getPostByParams", {
            error: errorPosts,
            msg: t(`errors.${errorPosts}`, t("errors.default")),
          });
          errorToast(
            "BlogLastPostsFilter - getPostByParams",
            errorPosts,
            t(`errors.${errorPosts}`, t("errors.default"))
          );
        }
      } else if (dataPosts) {
        setPosts(!isEmptyList(dataPosts) ? [...dataPosts] : []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosts, dataPosts, errorPosts, t]);

  const loadingComponent = useMemo(() => {
    return loading || loadingCategories || loadingPosts;
  }, [loading, loadingCategories, loadingPosts]);

  const getPostTitle = useCallback((post) => {
    return !isEmpty(post) &&
      !isEmpty(post.title) &&
      !isEmpty(post.title.rendered)
      ? post.title.rendered
      : "";
  }, []);

  const getPostDate = useCallback(
    (post) => {
      const datePost = moment(post.date);
      const formatDate = equalsIgnoringCase(locale, "es")
        ? "DD/MM/YYYY"
        : "Mo MMMM YYYY";
      return datePost.locale(locale).format(formatDate);
    },
    [locale]
  );

  const postCategory = useCallback(
    (post) => {
      if (
        !isEmpty(post) &&
        !isEmptyList(post.categories) &&
        !isEmptyList(categories)
      ) {
        const categoryIds = categories.map((cat) => cat.id);
        const postCategories = post.categories.filter((id) => {
          return categoryIds.includes(id);
        });

        return categories.find((cat) => cat.id === postCategories[0]);
      }
      return null;
    },
    [categories]
  );

  const postCategoryName = useCallback(
    (post) => (!isEmptyList(categories) ? postCategory(post).name : ""),
    [categories, postCategory]
  );

  const getPostLink = useCallback(
    (post) => {
      if (!disabled && !isEmpty(post) && !isEmptyList(categories)) {
        const categorySlug = postCategory(post).slug.replace(`${locale}-`, "");
        const postSlug = post.slug.replace(`${locale}-`, "");
        return getLocalePath(
          `${t("routes.public.blog.index")}/${categorySlug}/${postSlug}/`
        );
      }
      return "";
    },
    [disabled, categories, locale, postCategory, getLocalePath, t]
  );

  const getCategoryPostLink = useCallback(
    (post) => {
      if (!disabled && !isEmpty(post) && !isEmptyList(categories)) {
        const categorySlug = postCategory(post).slug.replace(`${locale}-`, "");
        return getLocalePath(
          `${t("routes.public.blog.index")}/${categorySlug}/`
        );
      }
      return "";
    },
    [disabled, categories, locale, postCategory, getLocalePath, t]
  );

  return (
    <div className="blog-filter__last-posts__container">
      <div className="blog-filter__last-posts__content">
        <div className="blog-filter__last-posts__title">
          {t("blog.body.last_posts")}
        </div>
        <>
          {loadingComponent ? (
            <div className="blog-filter__last-posts__loading">
              <Loading />
            </div>
          ) : (
            <div className="blog-filter__last-posts__list">
              {!isEmptyList(posts) &&
                posts.map((post) => {
                  return (
                    <div key={post.id} className="last-posts__list-item">
                      <div className="row">
                        <Link
                          className="last-posts__list-item__title ellipsis"
                          title={getPostTitle(post)}
                          to={getPostLink(post)}
                          state={{
                            categories: categories,
                            category: postCategory(post),
                            post: post,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: post.title.rendered,
                          }}
                        />
                      </div>
                      <div className="row">
                        <span className="last-posts__list-item__date">
                          <span className="icon icon-calendar" />
                          <span
                            className="label"
                            dangerouslySetInnerHTML={{
                              __html: getPostDate(post.date),
                            }}
                          />
                        </span>
                        <span className="last-posts__list-item__category">
                          <span className="icon icon-folder" />
                          <Link
                            className="label"
                            title={postCategoryName(post)}
                            to={getCategoryPostLink(post)}
                            state={{
                              categories: categories,
                              category: postCategory(post),
                            }}
                          >
                            {postCategoryName(post)}
                          </Link>
                        </span>
                      </div>
                      <div className="row">
                        <span
                          className="last-posts__list-item__excerpt"
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default BlogLastPostsFilter;
