import { useCallback, useMemo } from "react";

import moment from "moment";

import { useTranslation } from "react-i18next";

import Loading from "../../../components/loading";
import RaffleCard from "../../../components/raffle-card";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  equalsIgnoringCase,
} from "../../../utils";

function RafflesBody(props) {
  const { loadingRaffles, raffles, loadingContent, content } = props;

  const { t } = useTranslation();

  const isClosedDate = useCallback((item) => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    return moment(now).isAfter(moment(closeDate));
  }, []);

  const ListRaffleCards = (props) => {
    const rafflesList = useMemo(() => {
      if (!loadingRaffles && !isEmptyList(raffles)) {
        const rafflesList = [];
        const rafflesArray = Array.from(raffles);
        return rafflesArray
          .filter((raffle) => raffle.is_enabled && !isClosedDate(raffle))
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date))
          .filter((raffle) => {
            const searchHandler = (list, date, price) => {
              return list.find((item) => {
                const itemDate = moment(
                  item.close_date,
                  "DD/MM/YYYY HH:mm:ss"
                ).format("DD/MM/YYYY HH:mm:ss");
                const paramDate = moment(date, "DD/MM/YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                );
                return (
                  equalsIgnoringCase(itemDate, paramDate) &&
                  item.price === price
                );
              });
            };
            const item = searchHandler(
              rafflesList,
              raffle.close_date,
              raffle.price
            );
            if (isEmpty(item)) {
              rafflesList.push({
                close_date: moment(raffle.close_date, "DD/MM/YYYY HH:mm:ss"),
                price: raffle.price,
              });
              return true;
            }
            return false;
          })
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
      }
      return [];
    }, []);

    return (
      <>
        {rafflesList.map((item, idx) => (
          <RaffleCard key={idx} item={item} />
        ))}
      </>
    );
  };

  return (
    <div className="raffles-body">
      <div className="raffles-body__container">
        <h1 className="raffles-body__title">{t("raffles.body.title")}</h1>
        <div className="raffles-body__content">
          {loadingRaffles ? (
            <div className="raffles-body__loading">
              <Loading />
            </div>
          ) : (
            <div className="raffles-body__cards">
              <ListRaffleCards />
            </div>
          )}
          {loadingContent ? (
            <div className="raffles-body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className="raffles-body__info"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RafflesBody;
