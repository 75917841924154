import HeaderPage from "../../../components/header";

function RaffleNavidadHeader() {
  return (
    <div className="raffle-navidad-header">
      <div className="raffle-navidad-header__container">
        <div className="raffle-navidad-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default RaffleNavidadHeader;
