import { useCallback, useMemo, useState } from "react";
import { equalsIgnoringCase, isEmpty } from "../../utils";

const useGetCategoriesByParams = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUrl = useCallback((params) => {
    const urlBase = `${process.env.REACT_APP_WP_BASE_URL}/wp-json/wp/v2/categories`;
    if (!isEmpty(params)) {
      const parent = !isEmpty(params.parent) ? `parent=${params.parent}` : null;
      const slug = !isEmpty(params.slug) ? `slug=${params.slug}` : null;
      const search = !isEmpty(params.search) ? `search=${params.search}` : null;
      const page = "page=1";
      const perPage = "per_page=100";

      const filters = [parent, slug, search, page, perPage]
        .filter((item) => !isEmpty(item))
        .join("&");
      return `${urlBase}?${filters}`;
    }
    return urlBase;
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_WP_USER}:${process.env.REACT_APP_WP_PASS}`
        )}`,
      },
    };
  }, []);

  const getCategoriesByParams = useCallback(
    (params) => {
      try {
        setLoading(true);
        fetch(getUrl(params), config)
          .then((response) => response.json())
          .then((data) => {
            if (
              !isEmpty(data.code) &&
              equalsIgnoringCase(data.code, "rest_post_invalid_id")
            ) {
              setData(null);
              setError(data.code);
            } else {
              setData(data);
              setError(null);
            }
            setLoading(false);
          })
          .catch((error) => {
            setData(null);
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setData(null);
        setError(error);
        setLoading(false);
      }
    },
    [getUrl, config]
  );
  return { loading, data, error, getCategoriesByParams };
};

export default useGetCategoriesByParams;
