import * as yup from "yup";

import { NO_WHITE_SPACES_REGEX, PASSWORD_FORMAT_REGEX } from "../utils";

const getUserRegisterSchema = (t) => {
  return yup.object().shape({
    user_name: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(40, t("validators.maxLength", { value: 40 })),
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(60, t("validators.maxLength", { value: 60 })),
    pass1: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(18, t("validators.maxLength", { value: 18 }))
      .matches(NO_WHITE_SPACES_REGEX, t("validators.no_whitespaces"))
      .matches(PASSWORD_FORMAT_REGEX, t("validators.password_mismatch")),
    pass2: yup
      .string()
      .required(t("validators.notEmpty"))
      .oneOf([yup.ref("pass1"), null], t("validators.compareFields")),
    termsOfUse: yup.bool().oneOf([true], t("validators.checkTrue")),
    //"g-recaptcha-response": yup.string().required(t("validators.notEmpty")),
  });
};

export default getUserRegisterSchema;
