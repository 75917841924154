import { useCallback, useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";

import CartBody from "./cart-body";
import SEO from "../../components/seo";
import CartHeader from "./cart-header";
import CartFooter from "./cart-footer";
import { errorToast } from "../../components/toast";

import { logDebug, logWarn } from "../../utils/logger";
import { equalsIgnoringCase, isEmpty, isEmptyObject } from "../../utils";

function CartPage() {
  const [page, setPage] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("cart.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("cart.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  const pageRequest = useMemo(() => {
    return { path: pathname };
  }, [pathname]);

  const onclickBuyHandler = useCallback((evt) => {
    logDebug("CartPage", "onclickBuyHandler", { evt: evt });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageByPath(pageRequest);
  }, [pageRequest, getPageByPath]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("CartPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "CartPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("CartPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="cart">
        <div className="cart__container">
          <CartHeader />
          <CartBody
            loadingContent={loadingPageByPath}
            content={content}
            onClickBuy={(evt) => onclickBuyHandler(evt)}
          />
          <CartFooter />
        </div>
      </div>
    </>
  );
}

export default CartPage;
