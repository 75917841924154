export const TYPE_REQUEST = {
  ANONIM: "anonim",
  USERNAME: "username",
  FACEBOOK: "facebook",
};

export const parseDataUrl = (data) => {
  const urlEncodedDataPairs = [];
  for (const [name, value] of Object.entries(data)) {
    urlEncodedDataPairs.push(
      `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    );
  }
  return urlEncodedDataPairs.join("&").replace(/%20/g, "+");
};

export const parseDataJson = (data) => {
  return JSON.stringify(data);
};
