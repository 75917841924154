/**
 * Date Formats
 */
export const DATE_FORMAT = {
  US_LONG_FORMAT: "YYYY-MM-DD HH:mm:ss",
  US_SHORT_FORMAT: "YYYY-MM-DD",
  ES_LONG_FORMAT: "DD/MM/YYYY HH:mm:ss",
  ES_SHORT_FORMAT: "DD/MM/YYYY",
};

/**
 * Function that validates a value is empty
 *
 * @param {string} value Value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmpty = (value) => {
  return (
    value === undefined || value === null || value === "" || value === "null"
  );
};

/**
 * Function that validates an object is empty
 *
 * @param {Object} value Object value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmptyObject = (value) => {
  return isEmpty(value) || Object.keys(value).length === 0;
};

/**
 * Function that validates a array is empty
 *
 * @param {Array} value Value to validate
 * @returns True if value is empty, false otherwise
 */
export const isEmptyList = (value) => {
  return (
    isEmpty(value) || (Array.isArray(value) && Array.from(value).length === 0)
  );
};

/**
 * Function that validate that two string are the same ignoring case
 *
 * @param {string} value1 First value to validate
 * @param {string} value2 Second value to validate
 * @returns True is two values are tue same, false otherwhise
 */
export const equalsIgnoringCase = (value1, value2) => {
  if (!isEmpty(value1) && !isEmpty(value2)) {
    return value1.toUpperCase().localeCompare(value2.toUpperCase()) === 0;
  } else {
    return value1 === value2;
  }
};

/**
 * Function that format a number
 *
 * @param {number} num value to be formatted
 * @param {int} digits amount of digits of decimal part to be formatted
 * @param {any} locale i18next locale reference
 * @returns True is two values are tue same, false otherwhise
 */
export const nFormatter = (num, digits, locale) => {
  const lookup = [
    { value: 1, symbol: locale.t("format.number.hundred") },
    { value: 1e3, symbol: locale.t("format.number.kilo") },
    { value: 1e6, symbol: locale.t("format.number.mega") },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  if (item) {
    const amount = (num / item.value).toFixed(digits).replace(rx, "$1");
    const formatter = Intl.NumberFormat(locale.language, {
      style: "decimal",
    });

    return {
      amount: formatter.format(amount),
      symbol: item.symbol,
    };
  } else {
    return {
      amount: 0,
      symbol: "",
    };
  }
};

/**
 * Function that format an amount of money
 *
 * @param {number} value Amount of money to use in formatting process
 * @param {string} lng ISO 639 language code
 * @param {string} currency Currency to use in formatting process
 *
 * @returns {string} formatted amount of money
 */
export const moneyFormatter = (value, lng, currency) => {
  return Intl.NumberFormat(lng, {
    style: "currency",
    currency: currency,
  }).format(value);
};
