import HeaderPage from "../../../components/header";

function EurodreamsHeader() {
  return (
    <div className="eurodreams-header">
      <div className="eurodreams-header__container">
        <div className="eurodreams-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default EurodreamsHeader;
