import FooterPage from "../../../components/footer";

function GordoFooter() {
  return (
    <div className="gordo-footer">
      <FooterPage />
    </div>
  );
}

export default GordoFooter;
