import { useCallback, useMemo, useState } from "react";

import crypto from "crypto-js";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  isEmptyObject,
} from "../../../utils";
import { TYPE_REQUEST } from "../utils";
import { logError } from "../../../utils/logger";
import { GAME_OID } from "../../../utils/constants";

const useDrawsStock = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({
    raffles: [],
    lotteries: [],
    syndicates: [],
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getRandStringHandler = useCallback((length = 16) => {
    let result = "";
    const chars = "0123456789abcdef";
    let counter = 0;
    while (counter < length) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
      counter++;
    }
    return result;
  }, []);

  const encryptHandler = useCallback((secret) => {
    return btoa(crypto.SHA1(secret).toString(crypto.enc.Latin1));
  }, []);

  const vendor = useMemo(() => `YDRAY-${process.env.REACT_APP_API_USER}`, []);

  const deviceToken = useMemo(() => {
    return process.env.REACT_APP_API_USER;
  }, []);

  const getXWSSE = useCallback(
    (
      type_request = TYPE_REQUEST.ANONIM,
      email = process.env.REACT_APP_API_USER,
      token = process.env.REACT_APP_API_PASS
    ) => {
      const randomStr = getRandStringHandler(16);
      const username = email;
      const password = token;
      const encoded_nonce = btoa(randomStr);
      const createdDate = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
      const type = type_request;
      const secret = `${atob(encoded_nonce)}${createdDate}${password}`;
      const passwordDigest = encryptHandler(secret);

      return `UsernameToken Username="${username}", PasswordDigest="${passwordDigest}", Nonce="${encoded_nonce}", Created="${createdDate}", Type="${type}"`;
    },
    [getRandStringHandler, encryptHandler]
  );

  const url = useMemo(
    () => `${process.env.REACT_APP_API_PUBLIC_URL}/secured/draws/stock`,
    []
  );

  const getLogo = useCallback(
    (info) => {
      if (!isEmpty(info)) {
        if (info.game_oid !== GAME_OID.LOTERIA_NAC) {
          return t(`draws.${info.game_oid}.logo`);
        } else if (!isEmpty(info.draw_date) && !isEmpty(info.price)) {
          const drawDate = moment(info.draw_date, DATE_FORMAT.US_LONG_FORMAT);
          const dayOfTheWeek = drawDate.isoWeekday();
          if (
            [4, 6].includes(dayOfTheWeek) &&
            ["300", "600"].includes(info.price)
          ) {
            return t(`draws.${GAME_OID.LOTERIA_NAC}.logo.${dayOfTheWeek}`);
          } else {
            const day = drawDate.date();
            const month = drawDate.month();
            if (day === 22 && month === 11) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.logo.${9}`);
            } else if (day === 6 && month === 0) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.logo.${10}`);
            } else {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.logo.${8}`);
            }
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    [t]
  );

  const getClass = useCallback(
    (info) => {
      if (!isEmpty(info)) {
        if (info.game_oid !== GAME_OID.LOTERIA_NAC) {
          return t(`draws.${info.game_oid}.class`);
        } else if (!isEmpty(info.draw_date) && !isEmpty(info.price)) {
          const drawDate = moment(info.draw_date, DATE_FORMAT.US_LONG_FORMAT);
          const dayOfTheWeek = drawDate.isoWeekday();
          if (
            [4, 6].includes(dayOfTheWeek) &&
            ["300", "600"].includes(info.price)
          ) {
            return t(`draws.${GAME_OID.LOTERIA_NAC}.class.${dayOfTheWeek}`);
          } else {
            const day = drawDate.date();
            const month = drawDate.month();
            if (day === 22 && month === 11) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.class.${9}`);
            } else if (day === 6 && month === 0) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.class.${10}`);
            } else {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.class.${8}`);
            }
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    [t]
  );

  const getTitle = useCallback(
    (info) => {
      if (!isEmpty(info)) {
        if (info.game_oid !== GAME_OID.LOTERIA_NAC) {
          return t(`draws.${info.game_oid}.title`);
        } else if (!isEmpty(info.draw_date) && !isEmpty(info.price)) {
          const drawDate = moment(info.draw_date, DATE_FORMAT.US_LONG_FORMAT);
          const dayOfTheWeek = drawDate.isoWeekday();
          if (
            [4, 6].includes(dayOfTheWeek) &&
            ["300", "600"].includes(info.price)
          ) {
            return t(`draws.${GAME_OID.LOTERIA_NAC}.title.${dayOfTheWeek}`);
          } else {
            const day = drawDate.date();
            const month = drawDate.month();
            if (day === 22 && month === 11) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.title.${9}`);
            } else if (day === 6 && month === 0) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.title.${10}`);
            } else {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.title.${8}`);
            }
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    [t]
  );

  const getDescription = useCallback(
    (info) => {
      if (!isEmpty(info)) {
        if (info.game_oid !== GAME_OID.LOTERIA_NAC) {
          return t(`draws.${info.game_oid}.description`);
        } else if (!isEmpty(info.draw_date) && !isEmpty(info.price)) {
          const drawDate = moment(info.draw_date, DATE_FORMAT.US_LONG_FORMAT);
          const dayOfTheWeek = drawDate.isoWeekday();
          if (
            [4, 6].includes(dayOfTheWeek) &&
            ["300", "600"].includes(info.price)
          ) {
            return t(
              `draws.${GAME_OID.LOTERIA_NAC}.description.${dayOfTheWeek}`
            );
          } else {
            const day = drawDate.date();
            const month = drawDate.month();
            if (day === 22 && month === 11) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.description.${9}`);
            } else if (day === 6 && month === 0) {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.description.${10}`);
            } else {
              return t(`draws.${GAME_OID.LOTERIA_NAC}.description.${8}`);
            }
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    [t]
  );

  const drawsStock = useCallback(
    (params) => {
      try {
        setLoading(true);
        fetch(url, {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "device-token": deviceToken,
            vendor: vendor,
            "X-WSSE": getXWSSE(
              params.header.type_request,
              params.header.email,
              params.header.token
            ),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (!isEmptyObject(data) && !isEmptyList(data.draws)) {
              const draws = [];
              Array.from(data.draws).forEach((item) => {
                Array.from(item.info).forEach((info) => {
                  draws.push({
                    logo: getLogo(info),
                    title: getTitle(info),
                    class: getClass(info),
                    description: getDescription(info),
                    ...info,
                  });
                });
              });
              const lotteries = draws.filter((draw) =>
                [
                  GAME_OID.EUROMILLIONS,
                  GAME_OID.PRIMITIVA,
                  GAME_OID.BONOLOTO,
                  GAME_OID.EL_GORDO,
                  GAME_OID.QUINIELA,
                  GAME_OID.POWERBALL,
                  GAME_OID.SUPERENALOTTO,
                  GAME_OID.MEGAMILLIONS,
                  GAME_OID.EURODREAMS,
                ].includes(draw.game_oid)
              );
              const raffles = draws.filter((draw) =>
                [1].includes(draw.game_oid)
              );
              const syndicates = [...data.syndicates];
              setData({
                raffles: raffles,
                lotteries: lotteries,
                syndicates: syndicates,
              });
            }
          })
          .catch((error) => {
            logError("drawsStock", "fetch", { error: error });
            setError("default");
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        logError("drawsStock", "fetch", { error: error });
        setError("default");
        setLoading(false);
      }
    },
    [
      url,
      vendor,
      deviceToken,
      getClass,
      getLogo,
      getTitle,
      getXWSSE,
      getDescription,
    ]
  );

  return { loading, data, error, drawsStock };
};

export default useDrawsStock;
