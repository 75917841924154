import FooterPage from "../../../components/footer";

function RafflesExtraFooter() {
  return (
    <div className="raffle-extra-footer">
      <FooterPage />
    </div>
  );
}

export default RafflesExtraFooter;
