import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import Loading from "../../loading";

import { isEmpty, isEmptyList } from "../../../utils";
import { Link, useLocation } from "react-router-dom";

function CategoryBlogFilter(props) {
  const { loading, disabled, loadingCategories, parentCategory, categories } =
    props;

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const categoryList = useMemo(() => {
    let result = [];
    if (!isEmptyList(categories)) {
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    }
    return result;
  }, [categories]);

  const locale = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[1] : "";
  }, [pathname]);

  const getCategoryLink = useCallback(
    (category) => {
      if (!disabled && !isEmpty(category)) {
        return getLocalePath(
          `${t("routes.public.blog.index")}/${category.slug.replace(
            `${locale}-`,
            ""
          )}/`
        );
      }
      return "";
    },
    [disabled, locale, getLocalePath, t]
  );

  const loadingComponent = useMemo(() => {
    return loading || loadingCategories;
  }, [loading, loadingCategories]);

  return (
    <>
      <div className="blog-filter__category__container">
        <div className="blog-filter__category__content">
          <div className="blog-filter__category__title">
            {t("blog.body.categories")}
          </div>
          <>
            {loadingComponent ? (
              <div className="blog-filter__category__loading">
                <Loading />
              </div>
            ) : (
              <div className="blog-filter__category__list">
                {categoryList.map((category) => {
                  return (
                    <Link
                      key={category.id}
                      className="blog-filter__category__list-item ellipsis"
                      title={category.description}
                      to={getCategoryLink(category)}
                      state={{
                        categories: categories,
                        parentCategory: parentCategory,
                        selectedCategory: category,
                      }}
                    >
                      {category.name}
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}

export default CategoryBlogFilter;
