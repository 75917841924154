import { useCallback, useEffect, useState } from "react";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import { Box, Tab, Tabs } from "@mui/material";

import { logDebug } from "../../../utils/logger";
import { isEmpty, isEmptyObject } from "../../../utils";

function MyAccountBody(props) {
  const { tabs, tabIndex } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const [value, setValue] = useState(0);

  const labelProps = useCallback(
    (tab) => {
      return {
        label: t(tab.label),
        title: t(tab.title),
        "aria-label": t(tab.label),
      };
    },
    [t]
  );

  const a11yProps = useCallback((index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }, []);

  const onChangeTabsHandler = useCallback(
    (evt, newValue) => {
      logDebug("MyAccountBody", "onChangeTabsHandler", {
        evt: evt,
        oldValue: value,
        newValue: newValue,
      });
      setValue(newValue);
      const selectedTab = tabs.find((tab) => newValue === tab.index);
      if (!isEmptyObject(selectedTab)) {
        navigate({
          ...(!isEmpty(selectedTab.anchor) && { hash: t(selectedTab.anchor) }),
          pathname: getLocalePath(t("routes.private.index")),
        });
      }
    },
    [value, tabs, navigate, getLocalePath, t]
  );

  useEffect(() => {
    if (tabIndex > -1) setValue(tabIndex);
  }, [tabIndex]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ width: "100%" }}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: 3,
              width: "100%",
              minHeight: "500px",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  };

  return (
    <div className="my-account-body">
      <div className="my-account-body__container">
        <h1 className="my-account-body__title">{t("account.body.title")}</h1>
        <div className="my-account-body__content">
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              bgcolor: "background.paper",
            }}
            flexDirection={isUpperMd ? "row" : "column"}
          >
            <Tabs
              style={{ minWidth: isUpperMd ? "200px" : null }}
              aria-label={`${
                isUpperMd ? "vertical" : "horizontal"
              } tabs example`}
              scrollButtons="auto"
              allowScrollButtonsMobile
              variant={isUpperMd ? "fullWidth" : "scrollable"}
              orientation={isUpperMd ? "vertical" : "horizontal"}
              value={value}
              onChange={(evt, value) => onChangeTabsHandler(evt, value)}
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              {tabs.map((tab, idx) => {
                return (
                  <Tab
                    key={idx}
                    icon={tab.icon}
                    iconPosition="start"
                    {...a11yProps(idx)}
                    {...labelProps(tab)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  />
                );
              })}
            </Tabs>
            {tabs.map((tab, idx) => {
              return (
                <TabPanel key={idx} value={value} index={idx}>
                  {tab.tab}
                </TabPanel>
              );
            })}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default MyAccountBody;
