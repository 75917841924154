import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPostByParams from "../../hooks/wp-json-api/useGetPostByParams";
import useGetCategoriesByParams from "../../hooks/wp-json-api/useGetCategoriesByParams";

import SEO from "../../components/seo";
import BlogPostBody from "./blog-post-body";
import BlogPostHeader from "./blog-post-header";
import BlogPostFooter from "./blog-post-footer";
import { errorToast, warnToast } from "../../components/toast";

import {
  isEmpty,
  isEmptyList,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../utils";
import { logError, logWarn } from "../../utils/logger";

function BlogPostPage() {
  const [metadata, setMetadata] = useState(null);
  const [categories, setCategories] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [post, setPost] = useState(null);
  const [paramsPost, setParamsPost] = useState({
    filter: { category_id: "", search: "" },
    pagination: { index: 1, pageSize: 1 },
  });

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataParentCategory,
    error: errorParentCategory,
    loading: loadingParentCategory,
    getCategoriesByParams: getParentCategoryHandler,
  } = useGetCategoriesByParams();

  const {
    data: dataCategories,
    error: errorCategories,
    loading: loadingCategories,
    getCategoriesByParams,
  } = useGetCategoriesByParams();

  const {
    data: dataPosts,
    error: errorPosts,
    loading: loadingPosts,
    getPostByParams,
  } = useGetPostByParams();

  const locale = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[1] : "";
  }, [pathname]);

  const categorySlug = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[3] : "";
  }, [pathname]);

  const postSlug = useMemo(() => {
    return !isEmpty(pathname) ? pathname.split("/")[4] : "";
  }, [pathname]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("blog_post.title", {
          category: !isEmptyObject(selectedCategory)
            ? selectedCategory.name
            : "",
          title:
            !isEmptyObject(post) && isEmptyObject(post.title)
              ? post.title.rendered
              : "",
        });
  }, [metadata, selectedCategory, post, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("blog_post.description", {
          category: !isEmptyObject(selectedCategory)
            ? selectedCategory.name
            : "",
          title:
            !isEmptyObject(post) && isEmptyObject(post.title)
              ? post.title.rendered
              : "",
        });
  }, [metadata, selectedCategory, post, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getParentCategoryHandler({ parent: 0, search: null, slug: locale });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingParentCategory) {
      if (!isEmpty(errorParentCategory)) {
        logError("BlogPostPage", "getParentCategoryHandler", {
          error: errorParentCategory,
          msg: t(`errors.${errorParentCategory}`, t("errors.default")),
        });
        errorToast(
          "BlogPostPage - getParentCategoryHandler",
          errorParentCategory,
          t(`errors.${errorParentCategory}`, t("errors.default"))
        );
      } else if (!isEmptyList(dataParentCategory)) {
        setParentCategory({ ...dataParentCategory[0] });
      }
    }
  }, [loadingParentCategory, dataParentCategory, errorParentCategory, t]);

  useEffect(() => {
    if (!isEmpty(parentCategory) && !isEmpty(parentCategory.id)) {
      getCategoriesByParams({
        parent: parentCategory.id,
        search: null,
        slug: null,
      });
    }
  }, [parentCategory, getCategoriesByParams]);

  useEffect(() => {
    if (!loadingCategories) {
      if (!isEmpty(errorCategories)) {
        logError("BlogPostPage", "getCategoriesByParams", {
          error: errorCategories,
          msg: t(`errors.${errorCategories}`, t("errors.default")),
        });
        errorToast(
          "BlogPostPage - getCategoriesByParams",
          errorCategories,
          t(`errors.${errorCategories}`, t("errors.default"))
        );
      } else if (!isEmpty(dataCategories)) {
        setCategories(dataCategories);
        const category = dataCategories.find((cat) =>
          equalsIgnoringCase(cat.slug.replace(`${locale}-`, ""), categorySlug)
        );
        setSelectedCategory(category);
      }
    }
  }, [
    loadingCategories,
    errorCategories,
    dataCategories,
    categorySlug,
    locale,
    t,
  ]);

  useEffect(() => {
    if (!isEmpty(selectedCategory) && !isEmpty(selectedCategory.id)) {
      const params = {
        filter: {
          category_id: selectedCategory.id,
          slug: `${locale}-${postSlug}`,
        },
        pagination: { ...paramsPost.pagination, index: 1 },
      };
      setPost(null);
      setParamsPost(params);
      getPostByParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, getPostByParams]);

  useEffect(() => {
    if (
      !isEmpty(paramsPost) &&
      !isEmpty(paramsPost.filter) &&
      (!isEmpty(paramsPost.filter.category_id) ||
        !isEmpty(paramsPost.filter.search))
    ) {
      getPostByParams(paramsPost);
    }
  }, [paramsPost, getPostByParams]);

  useEffect(() => {
    if (!loadingPosts) {
      if (errorPosts) {
        if (equalsIgnoringCase(errorPosts, "rest_post_invalid_page_number")) {
          logWarn("BlogPostPage", "getPostByParams", {
            error: errorPosts,
            msg: t("errors.no_more_posts", t("errors.default")),
          });
          warnToast(
            "BlogPostPage - getPostByParams",
            errorPosts,
            t("errors.no_more_posts", t("errors.default"))
          );
        } else {
          logError("BlogPostPage", "getPostByParams", {
            error: errorPosts,
            msg: t(`errors.${errorPosts}`, t("errors.default")),
          });
          errorToast(
            "BlogPostPage - getPostByParams",
            errorPosts,
            t(`errors.${errorPosts}`, t("errors.default"))
          );
        }
      } else if (!isEmptyList(dataPosts)) {
        setPost(dataPosts[0]);
        setMetadata(dataPosts[0].yoast_head_json);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosts, dataPosts, errorPosts, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="blog-post-page">
        <div className="blog-post-page__container">
          <BlogPostHeader />
          <BlogPostBody
            loading={false}
            disabled={false}
            loadingPosts={loadingPosts}
            post={post}
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
            loadingSelectedCategory={loadingCategories}
            selectedCategory={selectedCategory}
          />
          <BlogPostFooter />
        </div>
      </div>
    </>
  );
}

export default BlogPostPage;
