import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";

import "./i18n/index.js";
import store from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
    </ReduxProvider>
  </React.StrictMode>
);
