import FooterPage from "../../../components/footer";

function CookiesFooter() {
  return (
    <div className="cookies-footer">
      <FooterPage />
    </div>
  );
}

export default CookiesFooter;
