import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import SEO from "../../components/seo";
import MyAccountBody from "./my-account-body";
import MyAccountHeader from "./my-account-header";
import MyAccountFooter from "./my-account-footer";
import { errorToast } from "../../components/toast";

import tabs from "./my-account-body/tabs";
import { logError } from "../../utils/logger";
import { isEmpty, equalsIgnoringCase, isEmptyObject } from "../../utils";

function MyAccountPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const [isAllowed, setIsAllowed] = useState(undefined);

  const { user } = useSelector((state) => state.user);

  const tabIndex = useMemo(() => {
    if (!isEmpty(hash)) {
      const idx = tabs.findIndex((tab) => {
        if (!isEmpty(tab.anchor)) {
          return equalsIgnoringCase(t(tab.anchor), hash.replace("#", ""));
        } else {
          return false;
        }
      });
      return idx;
    }
    return 0;
  }, [hash, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const title = useMemo(() => {
    return t("account.title");
  }, [t]);

  const description = useMemo(() => {
    return t("account.description");
  }, [t]);

  useEffect(() => {
    if (isEmptyObject(user) && isEmpty(user.session_token)) {
      setIsAllowed(false);
      logError("MyAccountPage", "check_user_session", {
        error: "expired_session",
        msg: t("errors.expired_session", t("errors.default")),
      });
      errorToast(
        "MyAccountPage",
        "expired_session",
        t("errors.expired_session", t("errors.default"))
      );
    } else {
      setIsAllowed(true);
    }
  }, [user, getLocalePath, navigate, t]);

  return (
    <>
      {isAllowed !== undefined && (
        <>
          {isAllowed ? (
            <>
              <SEO url={url} pageTitle={title} pageDesc={description} />
              <div className="my-account">
                <div className="my-account__container">
                  <MyAccountHeader />
                  <MyAccountBody tabs={tabs} tabIndex={tabIndex} />
                  <MyAccountFooter />
                </div>
              </div>
            </>
          ) : (
            <Navigate to={getLocalePath("/")} replace />
          )}
        </>
      )}
    </>
  );
}

export default MyAccountPage;
