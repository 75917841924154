import { useCallback, useEffect, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath.js";
import useGetPostByParams from "../../../hooks/wp-json-api/useGetPostByParams";

import { Link } from "react-router-dom";
import BlogList from "../../../components/blog-list";
import BlogFilter from "../../../components/blog-filter";
import Loading from "../../../components/loading/index.jsx";
import { errorToast, warnToast } from "../../../components/toast/index.js";

import { logDebug, logError, logWarn } from "../../../utils/logger.js";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../../utils/index.js";

function BlogCategoryBody(props) {
  const {
    loading,
    disabled,
    loadingCategories,
    categories,
    loadingParentCategory,
    parentCategory,
    loadingSelectedCategory,
    selectedCategory,
  } = props;

  const { t } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  const [posts, setPosts] = useState([]);
  const [initSearchPosts, setInitSearchPosts] = useState(false);
  const [paramsPosts, setParamsPosts] = useState({
    filter: { category_id: "", search: "" },
    pagination: { index: 1, pageSize: 5 },
  });

  const {
    loading: loadingPosts,
    data: dataPosts,
    error: errorPosts,
    getPostByParams,
  } = useGetPostByParams();

  useEffect(() => {
    if (
      initSearchPosts &&
      !isEmpty(paramsPosts) &&
      !isEmpty(paramsPosts.filter) &&
      (!isEmpty(paramsPosts.filter.category_id) ||
        !isEmpty(paramsPosts.filter.search))
    ) {
      getPostByParams(paramsPosts);
    }
  }, [initSearchPosts, paramsPosts, getPostByParams]);

  useEffect(() => {
    if (!loadingSelectedCategory) {
      if (!isEmpty(selectedCategory) && !isEmpty(selectedCategory.id)) {
        const params = {
          filter: {
            ...paramsPosts.filter,
            category_id: selectedCategory.id,
          },
          pagination: { ...paramsPosts.pagination, index: 1 },
        };
        setPosts([]);
        setParamsPosts(params);
        setInitSearchPosts(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSelectedCategory, selectedCategory]);
  useEffect(() => {
    if (!loadingPosts) {
      if (errorPosts) {
        if (equalsIgnoringCase(errorPosts, "rest_post_invalid_page_number")) {
          logWarn("BlogCategoryBody", "getPostByParams", {
            error: errorPosts,
            msg: t("errors.no_more_posts", t("errors.default")),
          });
          warnToast(
            "BlogCategoryBody - getPostByParams",
            errorPosts,
            t("errors.no_more_posts", t("errors.default"))
          );
        } else {
          logError("BlogCategoryBody", "getPostByParams", {
            error: errorPosts,
            msg: t(`errors.${errorPosts}`, t("errors.default")),
          });
          errorToast(
            "BlogCategoryBody - getPostByParams",
            errorPosts,
            t(`errors.${errorPosts}`, t("errors.default"))
          );
        }
      } else if (dataPosts) {
        setPosts(!isEmptyList(dataPosts) ? [...posts, ...dataPosts] : []);
      }
      setInitSearchPosts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosts, dataPosts, errorPosts, t]);

  const onChangePageHandler = useCallback((evt, value) => {
    const modParams = {
      filter: { ...value.filter },
      pagination: { ...value.pagination },
    };
    setParamsPosts(modParams);
    setInitSearchPosts(true);
    logDebug("BlogCategoryBody", "onChangePageHandler", {
      evt: evt,
      params: modParams,
    });
  }, []);

  const onChangeParamsHandler = useCallback((evt, value) => {
    const modParams = {
      filter: { ...value.filter },
      pagination: { ...value.pagination, index: 1 },
    };
    setParamsPosts(modParams);

    if (["blur", "clear"].includes(evt)) {
      setPosts([]);
      setInitSearchPosts(true);
    }

    logDebug("BlogCategoryBody", "onChangeParamsHandler", {
      evt: evt,
      params: modParams,
    });
  }, []);

  const Breadcrumb = (props) => {
    const breadCrumbInit = useMemo(() => {
      return t("routes.public.landing.index");
    }, []);

    const breadcrumbBlog = useMemo(() => {
      return t("routes.public.blog.index");
    }, []);

    const breadcrumbPostCategory = useMemo(() => {
      return !isEmpty(selectedCategory) && !isEmpty(selectedCategory.name)
        ? selectedCategory.name
        : "";
    }, []);

    const loadingComponent = useMemo(() => {
      return loading || loadingSelectedCategory || loadingPosts;
    }, []);

    return (
      <div className="breadcrumb__container">
        <>
          {loadingComponent ? (
            <div className="breadcrumb__loading">
              <Loading />
            </div>
          ) : (
            <div className="breadcrumb__content">
              <Link
                className="breadcrumb__item link"
                title={breadCrumbInit}
                to={getLocalePath("/")}
              >
                <span className="icon icon-home" />
                <span className="label">{breadCrumbInit}</span>
              </Link>
              <span className="breadcrumb__separator icon icon-chevron-right" />
              <Link
                className="breadcrumb__item link"
                title={breadcrumbBlog}
                to={`${getLocalePath(t("routes.public.blog.index"))}/`}
              >
                <span className="label">{breadcrumbBlog}</span>
              </Link>
              <span className="breadcrumb__separator icon icon-chevron-right" />
              <div className="breadcrumb__item" title={breadcrumbPostCategory}>
                <span className="label">{breadcrumbPostCategory}</span>
              </div>
            </div>
          )}
        </>
      </div>
    );
  };

  return (
    <div className="blog-category-body">
      <div className="blog-category-body__container">
        <h1 className="blog-category-body__title">{t("blog.body.title")}</h1>
        <div className="blog-category-body__content">
          <Breadcrumb />
          <div className="blog-category-body__grid-container">
            <BlogList
              loading={loading}
              disabled={disabled}
              loadingPosts={loadingPosts}
              posts={posts}
              loadingCategories={loadingCategories}
              categories={categories}
              loadingSelectedCategory={loadingSelectedCategory}
              selectedCategory={selectedCategory}
              params={paramsPosts}
              onChangeParams={(evt, value) => onChangePageHandler(evt, value)}
            />
            <BlogFilter
              loading={loading}
              disabled={disabled}
              showSearchFilter
              params={paramsPosts}
              onChangeParams={(evt, value) => onChangeParamsHandler(evt, value)}
              showCategoriesFilter
              loadingCategories={loadingCategories}
              categories={categories}
              loadingParentCategory={loadingParentCategory}
              parentCategory={parentCategory}
              showLastPosts
              showLottery
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCategoryBody;
