import { useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils";

function SearchInput({
  name,
  placeholder,
  value,
  hidden,
  disabled,
  required,
  clearable,
  onBlur,
  onChange,
  onKeyDown,
  onClear,
}) {
  const { t } = useTranslation();

  const pHolder = useMemo(() => {
    return !isEmpty(placeholder) ? placeholder : t("input.search.placeholder");
  }, [placeholder, t]);

  const title = useMemo(() => {
    return !isEmpty(value) ? value : pHolder;
  }, [pHolder, value]);

  const showClearable = useMemo(() => {
    return clearable && !isEmpty(value);
  }, [clearable, value]);

  return (
    <div
      className={classNames("search-input__container", {
        disabled: disabled,
        hidden: hidden,
      })}
    >
      <span className="search-input__icon icon-search"></span>
      <input
        id={name}
        type="text"
        role="search"
        className="search-input__input"
        name={name}
        placeholder={pHolder}
        value={value}
        title={title}
        disabled={disabled}
        hidden={hidden}
        required={required}
        onBlur={onBlur && ((evt) => onBlur(evt, evt.target.value))}
        onChange={onChange && ((evt) => onChange(evt, evt.target.value))}
        onKeyDown={onKeyDown && ((evt) => onKeyDown(evt, evt.target.value))}
      ></input>
      {showClearable && (
        <span
          className="search-input__icon icon-delete-circled-outline"
          onClick={onClear && ((evt) => onClear(evt, ""))}
        ></span>
      )}
    </div>
  );
}

export default SearchInput;
