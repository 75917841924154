import { useCallback, useMemo } from "react";

import moment from "moment";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Button, ButtonGroup } from "@mui/material";
import Loading from "../../../../components/loading";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../../../utils";

function GordoSectionSummary(props) {
  const {
    loading,
    draws,
    config,
    bets,
    price,
    onChangeConfig,
    onClickAddToCart,
  } = props;

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : [];
  }, [draws]);

  const onChangeConfigHandler = useCallback(
    (evt, data) => onChangeConfig(evt, { ...data }),
    [onChangeConfig]
  );

  const onClickAddToCartHandler = useCallback(
    (evt) => onClickAddToCart(evt),
    [onClickAddToCart]
  );

  const LoadingContainer = (props) => {
    return (
      <div className="gordo-section-summary__loading">
        <Loading color={"#ffffff"} />
      </div>
    );
  };

  const NumDrawsContainer = (props) => {
    const { config, onChangeNumDraws } = props;

    const onClickNumDrawsHandler = useCallback(
      (evt, value) => {
        onChangeNumDraws(evt, {
          ...config,
          num_draws: value !== 99999 ? value : 1,
          subscription: value === 99999,
        });
      },
      [config, onChangeNumDraws]
    );

    return (
      <div className="gordo-section-summary__num-draws__container">
        <div className="gordo-section-summary__num-draws__content">
          <div className="gordo-section-summary__num-draws__title__container">
            <span className="gordo-section-summary__num-draws__title__content">
              {t("gordo.body.summary.num_draws.title")}
            </span>
          </div>
          <div className="gordo-section-summary__num-draws__list">
            {!isEmptyList(draws) && (
              <ButtonGroup
                color="primary"
                variant="outlined"
                orientation={isUpperMd ? "horizontal" : "vertical"}
              >
                {draws.slice(0, 5).map((drawItem, idx) => {
                  return (
                    <Button
                      key={drawItem.lotterydraw_oid}
                      variant={
                        !config.subscription && idx + 1 === config.num_draws
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      title={idx + 1}
                      aria-label={idx + 1}
                      onClick={(evt) => onClickNumDrawsHandler(evt, idx + 1)}
                    >
                      {idx + 1}
                    </Button>
                  );
                })}
                <Button
                  variant={config.subscription ? "contained" : "outlined"}
                  color="primary"
                  title={t("button.subscription")}
                  aria-label={t("button.subscription")}
                  onClick={(evt) => onClickNumDrawsHandler(evt, 99999)}
                >
                  {t("button.subscription")}
                </Button>
              </ButtonGroup>
            )}
          </div>
        </div>
      </div>
    );
  };

  const TotalContainer = (props) => {
    const { draw, config } = props;

    const amount = useMemo(() => {
      const formatedAmount = new Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency:
          !isEmpty(draw) && !isEmpty(draw.price_currency)
            ? draw.price_currency
            : "EUR",
      }).format(config.num_draws * (price / 100));

      return t("gordo.body.summary.total.price", {
        amount: formatedAmount,
      });
    }, [draw, config]);

    const drawData = useMemo(() => {
      if (!isEmptyObject(config)) {
        return t("gordo.body.summary.total.num_draws", {
          value: config.subscription
            ? t("button.subscription")
            : config.num_draws,
        });
      }
      return "";
    }, [config]);

    const date = useMemo(() => {
      if (!isEmptyObject(config)) {
        const format = equalsIgnoringCase(i18n.resolvedLanguage, "es")
          ? DATE_FORMAT.ES_SHORT_FORMAT
          : DATE_FORMAT.EN_SHORT_FORMAT;

        const initDate = !isEmptyList(draws)
          ? moment(draws[0].draw_date).format(format)
          : "";

        const endDate = !isEmptyList(draws)
          ? !config.subscription
            ? moment(draws[config.num_draws - 1].draw_date).format(format)
            : moment(draws[0].draw_date).format(format)
          : "";

        if (config.subscription || config.num_draws === 1) {
          return !isEmpty(initDate)
            ? t("gordo.body.summary.total.draw_date", { date: initDate })
            : "";
        } else {
          return !isEmpty(initDate) && !isEmpty(endDate)
            ? t("gordo.body.summary.total.range_draw_date", {
                init_date: initDate,
                end_date: endDate,
              })
            : "";
        }
      }
      return "";
    }, [config]);

    return (
      <div className="gordo-section-summary__total__container">
        <div className="gordo-section-summary__total__content">
          <div className="gordo-section-summary__total__price__container">
            <div className="gordo-section-summary__total__price__content">
              <span className="value">{amount}</span>
            </div>
          </div>
          <div className="gordo-section-summary__total__draw-data__container">
            <div className="gordo-section-summary__total__draw-data__content">
              <span
                className="value"
                dangerouslySetInnerHTML={{ __html: drawData }}
              ></span>
            </div>
          </div>
          <div className="gordo-section-summary__total__date__container">
            <div className="gordo-section-summary__total__date__content">
              <span className="value">{date}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const AddToCardContainer = (props) => {
    const { draw, num_valid_bets, onClickAddToCart } = props;

    const isExpiredDate = useMemo(() => {
      if (!isEmpty(draw)) {
        const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
        const closeDate = moment(draw.close_date).format(
          DATE_FORMAT.US_LONG_FORMAT
        );
        const expired = moment(now).isAfter(moment(closeDate));
        return expired;
      }
      return true;
    }, [draw]);

    const disabledBtn = useMemo(() => {
      if (!isEmpty(draw)) {
        const validBets = num_valid_bets >= 1;
        return !draw.is_enabled || isExpiredDate || !validBets;
      }
      return true;
    }, [draw, isExpiredDate, num_valid_bets]);

    const onClickAddToCartHandler = useCallback(
      (evt) => {
        if (!disabledBtn) {
          onClickAddToCart(evt);
        }
      },
      [disabledBtn, onClickAddToCart]
    );

    return (
      <div className="gordo-section-summary__buttons__container">
        <div className="gordo-section-summary__buttons__content">
          <Button
            variant="contained"
            disabled={disabledBtn}
            title={t("button.add_cart")}
            aria-label={t("button.add_cart")}
            sx={{
              color: "primary.contrastText",
              backgroundColor: "primary.main",
            }}
            startIcon={
              <ShoppingCartIcon
                color={disabledBtn ? "disabled" : "contrastText"}
              />
            }
            onClick={(evt) => onClickAddToCartHandler(evt)}
          >
            {t("button.add_cart")}
          </Button>
        </div>
      </div>
    );
  };

  const SummaryContainer = (props) => {
    const { config, bets, onChangeConfig, onClickAddToCart } = props;

    const num_valid_bets = useMemo(() => {
      return !isEmptyList(bets) ? bets.filter((bet) => bet.valid).length : 0;
    }, [bets]);

    const onChangeNumDrawsHandler = useCallback(
      (evt, data) => onChangeConfig(evt, data),
      [onChangeConfig]
    );

    const onClickAddToCartHandler = useCallback(
      (evt) => {
        onClickAddToCart(evt);
      },
      [onClickAddToCart]
    );

    return (
      <div className="gordo-section-summary__container">
        <div className="gordo-section-summary__content">
          <div className="column">
            <NumDrawsContainer
              config={config}
              onChangeNumDraws={(evt, data) =>
                onChangeNumDrawsHandler(evt, data)
              }
            />
          </div>

          <div className="column">
            <TotalContainer draw={draw} config={config} />
            <AddToCardContainer
              draw={draw}
              num_valid_bets={num_valid_bets}
              onClickAddToCart={(evt) => onClickAddToCartHandler(evt)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <SummaryContainer
          config={config}
          bets={bets}
          onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
          onClickAddToCart={(evt) => onClickAddToCartHandler(evt)}
        />
      )}
    </>
  );
}

export default GordoSectionSummary;
