import FooterPage from "../../../components/footer";

function MyAccountFooter() {
  return (
    <div className="help-footer">
      <FooterPage />
    </div>
  );
}

export default MyAccountFooter;
