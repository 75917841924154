import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useUserLogin from "../../../hooks/international-api/user/useUserLogin";

import {
  setOpenLoginDialog,
  setOpenRegisterDialog,
  setOpenForgotPassDialog,
} from "../../../redux/reducers/uiReducer";
import { setUser, removeUser } from "../../../redux/reducers/userReducer";

import {
  Link,
  Dialog,
  Backdrop,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { UserLoginForm } from "../../form";
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from "@mui/icons-material/Close";
import { errorToast, successToast } from "../../toast";

import { logDebug, logError } from "../../../utils/logger";
import { equalsIgnoringCase, isEmptyObject } from "../../../utils";

function LoginDialog(props) {
  const { isOpen, onClose } = props;

  const [dataForm, setDataForm] = useState({
    user_name: "",
    email: "",
    pass1: "",
    pass2: "",
    termsOfUse: false,
    "g-recaptcha-response": "",
  });

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const {
    data: dataUserLogin,
    error: errorUserLogin,
    loading: loadingUserLogin,
    userLogin,
  } = useUserLogin();

  const dispatch = useDispatch();
  const { isOpenLoginDialog, isOpenRegisterDialog, isOpenForgotPassDialog } =
    useSelector((state) => state.ui);

  const disabled = useMemo(() => {
    return loadingUserLogin;
  }, [loadingUserLogin]);

  const onCloseHandler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("LoginDialog", "onCloseHandler", {
          evt: evt,
        });
        onClose && onClose(evt);
      }
    },
    [disabled, onClose]
  );

  const asyncScriptOnLoadHandler = useCallback(
    (data) => {
      if (!disabled) {
        logDebug("LoginDialog", "asyncScriptOnLoadHandler", {
          data: data,
        });
        setLoaded(data.loaded);
      }
    },
    [disabled]
  );

  const DialogHeader = (props) => {
    return (
      <>
        <DialogTitle>
          <Typography
            component="span"
            sx={{
              width: "100%",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {t("login.header.title")}
          </Typography>
        </DialogTitle>
        <IconButton
          title={t("button.close")}
          aria-label={"button.close"}
          onClick={(evt) => onCloseHandler(evt)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };

  const DialogBody = (props) => {
    const onValidHandler = useCallback(async (data, evt) => {
      if (!disabled) {
        logDebug("LoginDialog.DialogBody", "onValidHandler", {
          data: data,
          evt: evt,
        });
        setDataForm({ ...data });
        userLogin({
          header: {},
          body: {
            email: data.email,
            password: data.password,
            "g-recaptcha-response": data["g-recaptcha-response"],
          },
        });
      }
    }, []);

    const onInvalidHandler = useCallback(async (errors, evt) => {
      if (!disabled) {
        logDebug("LoginDialog.DialogBody", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        errorToast(
          "LoginDialog.DialogBody - onInvalidHandler",
          "error_form",
          t("errors.error_form", t("errors.default"))
        );
      }
    }, []);

    const changeRecaptchaHandler = useCallback((token) => {
      try {
        if (!disabled) {
          logDebug("LoginDialog.DialogBody", "changeRecaptchaHandler", {
            "g-recaptcha-response": token,
          });
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": token,
          });
        }
      } catch (error) {
        logError("LoginDialog.DialogBody", "changeRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "LoginDialog.DialogBody - changeRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    const resetRecaptchaHandler = useCallback((evt) => {
      try {
        if (!disabled) {
          logDebug("LoginDialog.DialogBody", "resetRecaptchaHandler", {
            evt: evt,
          });
          recaptchaRef.current.reset();
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": "",
          });
        }
      } catch (error) {
        logError("LoginDialog.DialogBody", "resetRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "LoginDialog.DialogBody - resetRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    return (
      <DialogContent dividers>
        <UserLoginForm
          loading={loadingUserLogin}
          disabled={disabled}
          dataForm={dataForm}
          recaptcha={{
            loaded: loaded,
            ref: recaptchaRef.current,
            resetRecaptcha: resetRecaptchaHandler,
            changeRecaptcha: changeRecaptchaHandler,
          }}
          onValid={(data, evt) => onValidHandler(data, evt)}
          onInvalid={(errors, evt) => onInvalidHandler(errors, evt)}
        />
      </DialogContent>
    );
  };

  const DialogFooter = (props) => {
    const ForgetPasswordText = (props) => {
      const onClickHandler = useCallback((evt) => {
        if (!disabled) {
          logDebug("LoginForm.ForgetPasswordText", "onClickHandler", {
            evt: evt,
          });
        }
        dispatch(setOpenLoginDialog(!isOpenLoginDialog));
        dispatch(setOpenForgotPassDialog(!isOpenForgotPassDialog));
      }, []);

      return (
        <Typography variant="body1">
          <Trans
            i18nKey="login.body.form.forget_password_msg"
            components={{
              link1: (
                <Link
                  type="button"
                  variant="body1"
                  component="button"
                  title={t("login.body.form.forgot_password_btn")}
                  onClick={(evt) => onClickHandler(evt)}
                />
              ),
            }}
          />
        </Typography>
      );
    };

    const GoToCreateAccountText = (props) => {
      const onClickHandler = useCallback((evt) => {
        if (!disabled) {
          logDebug("LoginForm.GoToCreateAccountText", "onClickHandler", {
            evt: evt,
          });
          dispatch(setOpenLoginDialog(!isOpenLoginDialog));
          dispatch(setOpenRegisterDialog(!isOpenRegisterDialog));
        }
      }, []);

      return (
        <Typography variant="body1">
          <Trans
            i18nKey="login.body.form.create_account_msg"
            components={{
              link1: (
                <Link
                  type="button"
                  variant="body1"
                  component="button"
                  title={t("register.header.title")}
                  onClick={(evt) => onClickHandler(evt)}
                />
              ),
            }}
          />
        </Typography>
      );
    };

    return (
      <DialogContent dividers>
        <ForgetPasswordText />
        <GoToCreateAccountText />
      </DialogContent>
    );
  };

  useEffect(() => {
    if (!loadingUserLogin) {
      if (!isEmptyObject(dataUserLogin)) {
        logDebug("LoginDialog", "userLogin", {
          data: { ...dataUserLogin },
        });
        dispatch(setUser({ ...dataUserLogin }));
        dispatch(setOpenLoginDialog(false));
        navigate(getLocalePath(t("routes.private.index")));
        successToast(
          "LoginDialog - userLogin",
          "message.success_login",
          t("message.success_login")
        );
      } else if (!isEmptyObject(errorUserLogin)) {
        dispatch(removeUser());
        navigate(getLocalePath("/"));
        if (equalsIgnoringCase(errorUserLogin, "expired_session")) {
          logError("LoginDialog", "expired_session", {
            error: "expired_session",
            msg: t(`errors.expired_session`, t("errors.default")),
          });
          errorToast(
            "LoginDialog",
            "expired_session",
            t(`errors.expired_session`, t("errors.default"))
          );
        } else {
          logError("LoginDialog", "userLogin", {
            error: errorUserLogin,
            msg: t(`errors.${errorUserLogin}`, t("errors.default")),
          });
          errorToast(
            "LoginDialog",
            errorUserLogin,
            t(`errors.${errorUserLogin}`, t("errors.default"))
          );
        }
      }
    }
  }, [
    loadingUserLogin,
    dataUserLogin,
    errorUserLogin,
    dispatch,
    navigate,
    getLocalePath,
    t,
  ]);

  return (
    <>
      <Dialog open={isOpen} onClose={(evt) => onCloseHandler(evt)}>
        <DialogHeader />
        <DialogBody />
        <DialogFooter />
      </Dialog>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="recaptcha-container"
        size="invisible"
        badge="bottomright"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        asyncScriptOnLoad={(data) => asyncScriptOnLoadHandler(data)}
      />
      <Backdrop
        open={loadingUserLogin}
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" thickness={10} />
      </Backdrop>
    </>
  );
}

export default LoginDialog;
