import { useCallback, useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { getUserLoginSchema } from "../../../schemas";

import { DevTool } from "@hookform/devtools";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, IconButton, InputAdornment } from "@mui/material";

import { logDebug } from "../../../utils/logger";

function UserLoginForm(props) {
  const { loading, disabled, dataForm, recaptcha, onValid, onInvalid } = props;

  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      email: "",
      password: "",
      "g-recaptcha-response": "",
    };
  }, []);

  const [showPass, setShowPass] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    values: dataForm,
    defaultValues: defaultValues,
    resolver: yupResolver(getUserLoginSchema(t)),
  });
  const { resetRecaptcha, changeRecaptcha } = recaptcha;

  const clickShowPassHandler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("LoginForm", "clickShowPassHandler", { evt: evt });
        setShowPass(!showPass);
      }
    },
    [disabled, showPass]
  );

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("LoginForm", "onValidHandler", { data: data, evt: evt });
        onValid(data, evt);
      }
    },
    [disabled, onValid]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("LoginForm", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        onInvalid(errors, evt);
      }
    },
    [disabled, onInvalid]
  );

  return (
    <>
      <form
        id={"login-user-form"}
        noValidate
        onSubmit={handleSubmit(onValidHandler, onInvalidHandler)}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              name="email"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("login.body.form.email")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "email",
                    form: {
                      autocomplete: "email",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="password"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("login.body.form.password")}
                  type={showPass ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "password",
                    form: {
                      autocomplete: "password",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => clickShowPassHandler(evt)}
                        >
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Controller
            name="g-recaptcha-response"
            control={control}
            onError={() => resetRecaptcha()}
            onExpired={() => resetRecaptcha()}
            onChange={(token) => changeRecaptcha(token)}
            render={({ field }) => <input {...field} hidden required />}
          />
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoadingButton
              loading={loading}
              title={t("login.body.form.log_in_btn")}
              size="large"
              type="submit"
              margin="none"
              color="primary"
              variant="contained"
            >
              {t("login.body.form.log_in_btn")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <DevTool control={control} />
    </>
  );
}

export default UserLoginForm;
