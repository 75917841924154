import * as yup from "yup";

import { NO_WHITE_SPACES_REGEX, PASSWORD_FORMAT_REGEX } from "../utils";

const getUserPasswordChangeSchema = (t) => {
  return yup.object().shape({
    email: yup.string().email(t("validators.email")).notRequired(),
    password: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(18, t("validators.maxLength", { value: 18 }))
      .matches(NO_WHITE_SPACES_REGEX, t("validators.no_whitespaces"))
      .matches(PASSWORD_FORMAT_REGEX, t("validators.password_mismatch")),
    newPassword1: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(8, t("validators.minLength", { value: 8 }))
      .max(18, t("validators.maxLength", { value: 18 }))
      .matches(NO_WHITE_SPACES_REGEX, t("validators.no_whitespaces"))
      .matches(PASSWORD_FORMAT_REGEX, t("validators.password_mismatch")),
    newPassword2: yup
      .string()
      .required(t("validators.notEmpty"))
      .oneOf([yup.ref("newPassword1"), null], t("validators.compareFields")),
  });
};

export default getUserPasswordChangeSchema;
