import FooterPage from "../../../components/footer";

function RafflesNinoFooter() {
  return (
    <div className="raffle-nino-footer">
      <FooterPage />
    </div>
  );
}

export default RafflesNinoFooter;
