import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import Loading from "../../../../components/loading";

import { isEmpty, isEmptyList, isEmptyObject } from "../../../../utils";

function SuperenalottoSectionAddons(props) {
  const { loading, draws, config, onChangeConfig } = props;

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const draw = useMemo(() => {
    return !isEmptyList(draws) ? draws[0] : {};
  }, [draws]);

  const SuperenalottoSuperstarContainer = (props) => {
    const { config, onChangeConfig } = props;

    const superstar = useMemo(() => {
      return !isEmptyObject(config) && !isEmpty(config.superstar)
        ? config.superstar
        : false;
    }, [config]);

    const onClickSuperstarHandler = useCallback(
      (evt) => {
        onChangeConfig(evt, {
          ...config,
          superstar: !superstar,
        });
      },
      [config, superstar, onChangeConfig]
    );

    return (
      <div className="superenalotto-section-addons__superstar__container">
        <div className="superenalotto-section-addons__superstar__content">
          <div
            className="superenalotto-section-addons__superstar__value"
            onClick={(evt) => onClickSuperstarHandler(evt)}
          >
            <input
              type="checkbox"
              defaultChecked={superstar}
              className="superenalotto-section-addons__superstar__input"
            />
            <div className="superenalotto-section-addons__superstar__label">
              {t("superenalotto.body.addons.play_superstar")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeConfigHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  return (
    <div className="superenalotto-section-addons__container">
      {loading ? (
        <div className="superenalotto-section-addons__loading">
          <Loading />
        </div>
      ) : (
        <div className="superenalotto-section-addons__content">
          <SuperenalottoSuperstarContainer
            config={config}
            onChangeConfig={(evt, data) => onChangeConfigHandler(evt, data)}
          />
        </div>
      )}
    </div>
  );
}

export default SuperenalottoSectionAddons;
