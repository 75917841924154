import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS as es } from "./es";
import { TRANSLATIONS as en } from "./en";

import { equalsIgnoringCase } from "../utils";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV),
    resources: {
      es: { translation: es },
      en: { translation: en },
    },
    fallbackLng: ["es", "en"],
    supportedLngs: ["es", "en"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    returnObjects: true,
  });

export default i18n;
