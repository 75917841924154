import FooterPage from "../../../components/footer";

function RaffleSaturdayFooter() {
  return (
    <div className="raffle-saturday-footer">
      <FooterPage />
    </div>
  );
}

export default RaffleSaturdayFooter;
