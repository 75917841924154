import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function MyAccountSubscriptionTab() {
  const { t } = useTranslation();

  return (
    <Typography variant="subtitle1" component={"span"}>
      {t("account.body.tabs.subscription.title")}
    </Typography>
  );
}

export default MyAccountSubscriptionTab;
