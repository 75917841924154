import Loading from "../../../components/loading";

function TemplateSubPageBody(props) {
  const { loading, content } = props;

  return (
    <div className="template-subpage__body">
      <div className="template-subpage__body__container">
        <div className="template-subpage__body__content">
          {loading ? (
            <div className="template-subpage__body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TemplateSubPageBody;
