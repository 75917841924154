import { useEffect } from "react";

import detectBrowserLanguage from "detect-browser-language";

import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { equalsIgnoringCase, isEmpty } from "../utils";

function LocaleLayout() {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { locale } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const dbl = detectBrowserLanguage();
    let userLocale = dbl.split("-")[0];
    if (!isEmpty(locale)) {
      if (!i18n.languages.includes(locale)) {
        if (!i18n.languages.includes(userLocale)) {
          userLocale = "en";
        }
        if (!equalsIgnoringCase(i18n.resolvedLanguage, userLocale)) {
          i18n.changeLanguage(userLocale);
        }
        const path = pathname.replace(`/${locale}`, `/${userLocale}`);
        navigate(path, { replace: true });
      }
    } else {
      if (!i18n.languages.includes(userLocale)) {
        userLocale = "en";
      }
      if (!equalsIgnoringCase(i18n.resolvedLanguage, userLocale)) {
        i18n.changeLanguage(userLocale);
      }
      const path = `/${userLocale}/`;
      navigate(path, { replace: true });
    }
  }, [locale, i18n, pathname, navigate]);

  return <Outlet />;
}

export default LocaleLayout;
