import { useTranslation } from "react-i18next";
import Slider from "../../../components/slider";
import slides from "./mock.json";

function BodySliderSection() {
  const { t } = useTranslation();

  return (
    <div className="body-slider-section__container">
      <h1 className="body-slider-section__title">
        {t("landing.body.section.slider.title")}
      </h1>
      <Slider slides={slides} />
    </div>
  );
}

export default BodySliderSection;
