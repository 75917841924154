import HeaderPage from "../../../components/header";

function MegamillionsHeader() {
  return (
    <div className="megamillions-header">
      <div className="megamillions-header__container">
        <div className="megamillions-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default MegamillionsHeader;
