import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";

import ErrorPage from "../ErrorPage";
import SEO from "../../components/seo";
import TemplateSubPageBody from "./template-subpage-body";
import TemplateSubPageHeader from "./template-subpage-header";
import TemplateSubPageFooter from "./template-subpage-footer";

import { logDebug, logWarn } from "../../utils/logger.js";
import { equalsIgnoringCase, isEmpty, isEmptyObject } from "../../utils";

function TemplateDrawSubPage() {
  const [page, setPage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("template.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("template.description");
  }, [metadata, t]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  useEffect(() => {
    getPageByPath({ path: pathname });
  }, [pathname, getPageByPath]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (!equalsIgnoringCase(errorPageByPath, "rest_post_invalid_id")) {
          logWarn(
            "TemplatePage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
        setIsError(true);
      } else if (dataPageByPath) {
        logDebug("TemplatePage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      {isError ? (
        <ErrorPage />
      ) : (
        <div className="template-subpage">
          <div className="template-subpage__container">
            <TemplateSubPageHeader />
            <TemplateSubPageBody
              loading={loadingPageByPath}
              content={content}
            />
            <TemplateSubPageFooter />
          </div>
        </div>
      )}
    </>
  );
}

export default TemplateDrawSubPage;
