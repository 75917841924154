import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  setOpenLoginDialog,
  setOpenRegisterDialog,
} from "../../../redux/reducers/uiReducer";

import { Trans, useTranslation } from "react-i18next";
import { Link, Card, Stack, Typography, CardContent } from "@mui/material";

import { logDebug } from "../../../utils/logger";

function SummaryAccount() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isOpenLoginDialog, isOpenRegisterDialog } = useSelector(
    (state) => state.ui
  );

  const onClickRegisterHandler = useCallback(
    (evt) => {
      logDebug("SummaryAccount", "onClickRegisterHandler", {
        evt: evt,
      });
      dispatch(setOpenRegisterDialog(!isOpenRegisterDialog));
    },
    [dispatch, isOpenRegisterDialog]
  );

  const onClickLoginHandler = useCallback(
    (evt) => {
      logDebug("SummaryAccount", "onClickLoginHandler", {
        evt: evt,
      });
      dispatch(setOpenLoginDialog(!isOpenLoginDialog));
    },
    [dispatch, isOpenLoginDialog]
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack>
          <Typography
            variant="body1"
            component="span"
            title={t("cart.body.login.title")}
            aria-label={t("cart.body.login.title")}
            sx={{
              width: "100%",
              fontSize: "24px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {t("cart.body.login.title")}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            sx={{
              width: "100%",
              padding: "12px 0",
              textAlign: "center",
            }}
          >
            <Trans
              i18nKey="cart.body.login.subtitle"
              components={{
                login: (
                  <Link
                    type="button"
                    variant="body1"
                    component="button"
                    title={t("button.login")}
                    aria-label={t("button.login")}
                    onClick={(evt) => onClickLoginHandler(evt)}
                  />
                ),
                register: (
                  <Link
                    type="button"
                    variant="body1"
                    component="button"
                    title={t("button.register")}
                    aria-label={t("button.register")}
                    onClick={(evt) => onClickRegisterHandler(evt)}
                  />
                ),
              }}
              values={{
                loginLabelBtn: t("button.login"),
                registerLabelBtn: t("button.register"),
              }}
            />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SummaryAccount;
