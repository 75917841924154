import FooterPage from "../../../components/footer";

function EurodreamsFooter() {
  return (
    <div className="eurodreams-footer">
      <FooterPage />
    </div>
  );
}

export default EurodreamsFooter;
