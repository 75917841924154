import { useCallback } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";
import Loading from "../../../components/loading";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CartItem from "../../../components/cart/cart-item";
import SummaryCart from "../../../components/cart/summary-cart";
import SummaryAccount from "../../../components/cart/summary-account";

import { isEmpty } from "../../../utils";

function CartBody(props) {
  const { loadingContent, content, onClickBuy } = props;

  const { t } = useTranslation();

  const { user } = useSelector((state) => state.user);
  const { session_token } = user;

  const { cart } = useSelector((state) => state.cart);
  const { items } = cart;

  const onClickBuyHandler = useCallback(
    (evt) => onClickBuy && onClickBuy(evt),
    [onClickBuy]
  );

  const CartItemsContent = (props) => {
    const { items } = props;
    return (
      <>
        {items.map((item) => (
          <CartItem key={item.id} item={item} />
        ))}
      </>
    );
  };

  const SummaryContent = (props) => {
    const { items, onClickBuy } = props;

    const onClickBuyHandler = useCallback(
      (evt) => onClickBuy && onClickBuy(evt),
      [onClickBuy]
    );

    return (
      <>
        {!isEmpty(session_token) ? (
          <SummaryCart
            items={items}
            onClickBuy={(evt) => onClickBuyHandler(evt)}
          />
        ) : (
          <SummaryAccount />
        )}
      </>
    );
  };

  const CartContent = (props) => {
    const { items, onClickBuy } = props;

    const onClickBuyHandler = useCallback(
      (evt) => onClickBuy && onClickBuy(evt),
      [onClickBuy]
    );

    return (
      <Paper elevation={1} sx={{ width: "100%", p: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <CartItemsContent items={items} />
          </Grid>
          <Grid xs={12} md={4}>
            <SummaryContent
              items={items}
              onClickBuy={(evt) => onClickBuyHandler(evt)}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <div className="cart-body">
      <div className="cart-body__container">
        <h1 className="cart-body__title">{t("cart.body.title")}</h1>
        <div className="cart-body__content">
          <CartContent
            items={items}
            onClickBuy={(evt) => onClickBuyHandler(evt)}
          />
          {loadingContent ? (
            <div className="cart-body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className="cart-body__info"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartBody;
