import { useCallback, useMemo } from "react";

import moment from "moment";

import BodySliderSection from "../body-slider-section";
import BodyRaffleSection from "../body-raffle-section";
import BodyLotterySection from "../body-lottery-section";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  equalsIgnoringCase,
} from "../../../utils";

function LandingBody(props) {
  const { loadingDraws, draws } = props;

  const isClosedDate = useCallback((item) => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    return moment(now).isAfter(moment(closeDate));
  }, []);

  const rafflesMemo = useMemo(() => {
    if (!loadingDraws) {
      if (!isEmpty(draws) && !isEmptyList(draws.raffles)) {
        const rafflesList = [];
        const raffles = Array.from(draws.raffles)
          .filter((raffle) => raffle.is_enabled && !isClosedDate(raffle))
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date))
          .filter((raffle) => {
            const searchHandler = (list, date, price) => {
              return list.find((item) => {
                const itemDate = moment(
                  item.close_date,
                  "DD/MM/YYYY HH:mm:ss"
                ).format("DD/MM/YYYY HH:mm:ss");
                const paramDate = moment(date, "DD/MM/YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                );
                return (
                  equalsIgnoringCase(itemDate, paramDate) &&
                  item.price === price
                );
              });
            };
            const item = searchHandler(
              rafflesList,
              raffle.close_date,
              raffle.price
            );
            if (isEmpty(item)) {
              rafflesList.push({
                close_date: moment(raffle.close_date, "DD/MM/YYYY HH:mm:ss"),
                price: raffle.price,
              });
              return true;
            }

            return false;
          })
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));

        return raffles;
      }
    }
    return [];
  }, [loadingDraws, draws, isClosedDate]);

  return (
    <div className="landing-body">
      <div className="landing-body__container">
        <BodySliderSection />
        <BodyLotterySection loading={loadingDraws} draws={draws} />
        <BodyRaffleSection loading={loadingDraws} raffles={rafflesMemo} />
      </div>
    </div>
  );
}

export default LandingBody;
