import CasinoIcon from "@mui/icons-material/Casino";
import AddCardIcon from "@mui/icons-material/AddCard";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PasswordIcon from "@mui/icons-material/Password";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import MyAccountBetsTab from "./my-account-bets-tab";
import MyAccountDepositTab from "./my-account-deposit-tab";
import MyAccountPasswordTab from "./my-account-password-tab";
import MyAccountPersonalTab from "./my-account-personal-tab";
import MyAccountWithdrawTab from "./my-account-withdraw-tab";
import MyAccountMovementsTab from "./my-account-movements-tab";
import MyAccountSubscriptionTab from "./my-account-subscription-tab";

const tabs = [
  // Datos Personales
  {
    index: 0,
    label: "account.body.tabs.personal.label",
    title: "account.body.tabs.personal.title",
    anchor: "account.body.tabs.personal.anchor",
    icon: <AccountCircleIcon />,
    tab: <MyAccountPersonalTab />,
  },
  // Cambiar Contraseña
  {
    index: 1,
    label: "account.body.tabs.password.label",
    title: "account.body.tabs.password.title",
    anchor: "account.body.tabs.password.anchor",
    icon: <PasswordIcon />,
    tab: <MyAccountPasswordTab />,
  },
  // Apuestas
  {
    index: 2,
    label: "account.body.tabs.bets.label",
    title: "account.body.tabs.bets.title",
    anchor: "account.body.tabs.bets.anchor",
    icon: <CasinoIcon />,
    tab: <MyAccountBetsTab />,
  },
  // Movimientos
  {
    index: 3,
    label: "account.body.tabs.movements.label",
    title: "account.body.tabs.movements.title",
    anchor: "account.body.tabs.movements.anchor",
    icon: <PublishedWithChangesIcon />,
    tab: <MyAccountMovementsTab />,
  },
  // Depositar Dinero
  {
    index: 4,
    label: "account.body.tabs.deposit.label",
    title: "account.body.tabs.deposit.title",
    anchor: "account.body.tabs.deposit.anchor",
    icon: <AddCardIcon />,
    tab: <MyAccountDepositTab />,
  },
  // Retirar Dinero
  {
    index: 5,
    label: "account.body.tabs.withdraw.label",
    title: "account.body.tabs.withdraw.title",
    anchor: "account.body.tabs.withdraw.anchor",
    icon: <AccountBalanceIcon />,
    tab: <MyAccountWithdrawTab />,
  },
  // Subscripciones
  {
    index: 6,
    label: "account.body.tabs.subscription.label",
    title: "account.body.tabs.subscription.title",
    anchor: "account.body.tabs.subscription.anchor",
    icon: <BookmarkIcon />,
    tab: <MyAccountSubscriptionTab />,
  },
];

export default tabs;
