import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";
import useGetCategoriesByParams from "../../hooks/wp-json-api/useGetCategoriesByParams";

import BlogBody from "./blog-body";
import BlogHeader from "./blog-header";
import BlogFooter from "./blog-footer";
import SEO from "../../components/seo";
import { errorToast } from "../../components/toast";

import {
  isEmpty,
  isEmptyList,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../utils";
import { logDebug, logError, logWarn } from "../../utils/logger";

function BlogPage() {
  const [page, setPage] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [categories, setCategories] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const {
    loading: loadingParentCategory,
    data: dataParentCategory,
    error: errorParentCategory,
    getCategoriesByParams: getParentCategoryHandler,
  } = useGetCategoriesByParams();

  const {
    loading: loadingCategories,
    data: dataCategories,
    error: errorCategories,
    getCategoriesByParams,
  } = useGetCategoriesByParams();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("blog.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("blog.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const locale = useMemo(() => {
    return pathname.split("/")[1];
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageByPath({ path: pathname });
    getParentCategoryHandler({ parent: 0, search: null, slug: locale });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("BlogPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "BlogPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("BlogPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  useEffect(() => {
    if (!loadingParentCategory) {
      if (!isEmpty(errorParentCategory)) {
        logError("BlogPage", "getParentCategoryHandler", {
          error: errorParentCategory,
          msg: t(`errors.${errorParentCategory}`, t("errors.default")),
        });
        errorToast(
          "BlogPage - getParentCategoryHandler",
          errorParentCategory,
          t(`errors.${errorParentCategory}`, t("errors.default"))
        );
      } else if (!isEmptyList(dataParentCategory)) {
        setParentCategory({ ...dataParentCategory[0] });
      }
    }
  }, [loadingParentCategory, dataParentCategory, errorParentCategory, t]);

  useEffect(() => {
    if (!isEmpty(parentCategory) && !isEmpty(parentCategory.id)) {
      getCategoriesByParams({
        parent: parentCategory.id,
        search: null,
        slug: null,
      });
    }
  }, [parentCategory, getCategoriesByParams]);

  useEffect(() => {
    if (!loadingCategories) {
      if (!isEmpty(errorCategories)) {
        logError("BlogPage", "getCategoriesByParams", {
          error: errorCategories,
          msg: t(`errors.${errorCategories}`, t("errors.default")),
        });
        errorToast(
          "BlogPage - getCategoriesByParams",
          errorCategories,
          t(`errors.${errorCategories}`, t("errors.default"))
        );
      } else if (!isEmpty(dataCategories)) {
        setCategories(dataCategories);
      }
    }
  }, [loadingCategories, dataCategories, errorCategories, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="blog">
        <div className="blog__container">
          <BlogHeader />
          <BlogBody
            loading={false}
            disabled={false}
            content={content}
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
          />
          <BlogFooter />
        </div>
      </div>
    </>
  );
}

export default BlogPage;
