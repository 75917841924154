import * as yup from "yup";

const getUserUpdateSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(40, t("validators.maxLength", { value: 40 })),
    surname: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(60, t("validators.maxLength", { value: 60 })),
    dni: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(4, t("validators.minLength", { value: 4 }))
      .max(40, t("validators.maxLength", { value: 40 })),
    birthday: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(10, t("validators.maxLength", { value: 10 })),
    phone: yup
      .string()
      .required(t("validators.notEmpty"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(20, t("validators.maxLength", { value: 20 })),
  });
};

export default getUserUpdateSchema;
