import { useCallback, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import { yupResolver } from "@hookform/resolvers/yup";
import { getUserRegisterSchema } from "../../../schemas";

import {
  Link,
  Checkbox,
  TextField,
  IconButton,
  Typography,
  FormControl,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DevTool } from "@hookform/devtools";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { logDebug } from "../../../utils/logger";

function UserRegisterForm(props) {
  const { loading, disabled, dataForm, recaptcha, onValid, onInvalid } = props;

  const { t } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const defaultValues = useMemo(() => {
    return {
      user_name: "",
      email: "",
      pass1: "",
      pass2: "",
      termsOfUse: false,
      "g-recaptcha-response": "",
    };
  }, []);

  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const { watch, control, handleSubmit } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    values: dataForm,
    defaultValues: defaultValues,
    resolver: yupResolver(getUserRegisterSchema(t)),
  });
  const { resetRecaptcha, changeRecaptcha } = recaptcha;

  const onClickShowPass1Handler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("RegisterForm", "onClickShowPass1Handler", {
          evt: evt,
          value: !showPass1,
        });
        setShowPass1(!showPass1);
      }
    },
    [disabled, showPass1]
  );

  const onClickShowPass2Handler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("RegisterForm", "onClickShowPass2Handler", {
          evt: evt,
          value: !showPass2,
        });
        setShowPass2(!showPass2);
      }
    },
    [disabled, showPass2]
  );

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("RegisterForm", "onValidHandler", { data: data, evt: evt });
        onValid(data, evt);
      }
    },
    [disabled, onValid]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("RegisterForm", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        onInvalid(errors, evt);
      }
    },
    [disabled, onInvalid]
  );

  const TermsAndConditionText = (props) => {
    const linkTo1 = useMemo(() => {
      if (!disabled) {
        return getLocalePath(t("routes.public.legal.index"));
      }
      return "";
    }, []);

    const linkTo2 = useMemo(() => {
      if (!disabled) {
        return getLocalePath(t("routes.public.privacy.index"));
      }
      return "";
    }, []);

    const onClickHandler = useCallback((evt, linkTo) => {
      if (!disabled) {
        logDebug("RegisterForm", "onClickHandler", {
          evt: evt,
          to: linkTo,
        });
        const a = document.createElement("a");
        a.setAttribute("href", linkTo);
        a.setAttribute("target", "_blank");
        a.setAttribute("rel", "noopener noreferrer");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }, []);

    return (
      <Typography variant="body1">
        <Trans
          i18nKey="register.body.form.terms"
          components={{
            link1: (
              <Link
                type="button"
                variant="body1"
                component="button"
                title={t("legal.body.title")}
                onClick={(evt) => onClickHandler(evt, linkTo1)}
              />
            ),
            link2: (
              <Link
                type="button"
                variant="body1"
                component="button"
                title={t("privacy.body.title")}
                onClick={(evt) => onClickHandler(evt, linkTo2)}
              />
            ),
          }}
        ></Trans>
      </Typography>
    );
  };

  return (
    <>
      <form
        id={"register-user-form"}
        noValidate
        onSubmit={handleSubmit(onValidHandler, onInvalidHandler)}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              name="user_name"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("register.body.form.user_name")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="email"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("register.body.form.email")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="pass1"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("register.body.form.pass1")}
                  type={showPass1 ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => onClickShowPass1Handler(evt)}
                        >
                          {showPass1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="pass2"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("register.body.form.pass2")}
                  type={showPass2 ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => onClickShowPass2Handler(evt)}
                        >
                          {showPass2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <FormControl>
              <FormControlLabel
                label={<TermsAndConditionText />}
                control={
                  <Controller
                    name="termsOfUse"
                    control={control}
                    disabled={disabled}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Controller
            name="g-recaptcha-response"
            control={control}
            onError={() => resetRecaptcha()}
            onExpired={() => resetRecaptcha()}
            onChange={(token) => changeRecaptcha(token)}
            render={({ field }) => <input {...field} hidden required />}
          />
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoadingButton
              loading={loading}
              disabled={!watch("termsOfUse")}
              title={t("register.body.form.create_account_btn")}
              size="large"
              type="submit"
              margin="none"
              color="primary"
              variant="contained"
            >
              {t("register.body.form.create_account_btn")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <DevTool control={control} />
    </>
  );
}

export default UserRegisterForm;
