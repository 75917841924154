import { useCallback, useMemo, useState } from "react";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import {
  setOpenLoginDialog,
  setOpenRegisterDialog,
  setOpenForgotPassDialog,
} from "../../redux/reducers/uiReducer";
import { removeUser } from "../../redux/reducers/userReducer";

import {
  Box,
  List,
  Drawer,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import StarIcon from "@mui/icons-material/Star";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import WalletIcon from "@mui/icons-material/Wallet";
import LogoutIcon from "@mui/icons-material/Logout";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

import HeaderNav from "./header-nav";
import HeaderLogo from "./header-logo";
import { successToast } from "../toast";
import CartButton from "../cart/cart-button";
import HeaderButtons from "./header-buttons";
import { LoginDialog, RegisterDialog, ForgotPassDialog } from "../dialog";

import {
  isEmpty,
  isEmptyObject,
  moneyFormatter,
  equalsIgnoringCase,
} from "../../utils";
import { logDebug } from "../../utils/logger";

function HeaderPage() {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const { cart } = useSelector((state) => state.cart);
  const { items } = cart;

  const { user } = useSelector((state) => state.user);
  const { user_name, session_token, untransferable_balance } = user;

  const { isOpenLoginDialog, isOpenRegisterDialog, isOpenForgotPassDialog } =
    useSelector((state) => state.ui);

  const isProduction = useMemo(() => {
    return equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV);
  }, []);

  const amount = useMemo(() => {
    if (!isEmpty(untransferable_balance)) {
      return moneyFormatter(
        untransferable_balance,
        i18n.resolvedLanguage,
        "EUR"
      );
    }
    return moneyFormatter(0, i18n.resolvedLanguage, "EUR");
  }, [untransferable_balance, i18n.resolvedLanguage]);

  const onClickWalletHandler = useCallback((evt) => {
    logDebug("HeaderPage", "onClickWalletHandler", { evt: evt });
  }, []);

  const onClickAccountHandler = useCallback(
    (evt) => {
      logDebug("HeaderPage", "onClickAccountHandler", { evt: evt });
      if (isEmpty(session_token)) {
        dispatch(setOpenLoginDialog(!isOpenLoginDialog));
      } else {
        navigate(getLocalePath(t("routes.private.index")));
      }
    },
    [session_token, isOpenLoginDialog, dispatch, navigate, getLocalePath, t]
  );

  const toggleDrawerHandler = useCallback((evt, value) => {
    logDebug("HeaderPage", "toggleDrawerHandler", {
      evt: evt,
      value: value,
    });
    setOpen(value);
  }, []);

  const DrawerMenu = (props) => {
    const items = useMemo(() => {
      return [
        {
          index: 0,
          label: "header.toolbar.lottery",
          title: "header.toolbar.lottery",
          icon: <StarIcon />,
          link: getLocalePath(t("routes.public.lotteries.index")),
        },
        {
          index: 1,
          label: "header.toolbar.raffles",
          title: "header.toolbar.raffles",
          icon: <CardMembershipIcon />,
          link: getLocalePath(t("routes.public.raffles.index")),
        },
        {
          index: 2,
          label: "header.toolbar.syndicates",
          title: "header.toolbar.syndicates",
          icon: <LocalActivityIcon />,
          link: getLocalePath(t("routes.public.syndicates.index")),
        },
        {
          index: 3,
          label: "header.toolbar.blog",
          title: "header.toolbar.blog",
          icon: <AutoStoriesIcon />,
          link: getLocalePath(t("routes.public.blog.index")),
        },
        {
          index: 4,
          label: "header.toolbar.results",
          title: "header.toolbar.results",
          icon: <BackupTableIcon />,
          link: getLocalePath(t("routes.public.results.index")),
        },
        {
          index: 5,
          label: "header.toolbar.help",
          title: "header.toolbar.help",
          icon: <HelpOutlineIcon />,
          link: getLocalePath(t("routes.public.help.index")),
        },
      ];
    }, []);

    const onListItemClickHandler = (evt, item) => {
      logDebug("HeaderPage.DrawerMenu", "onListItemClickHandler", {
        evt: evt,
        item: item,
      });
      if (!isEmptyObject(item)) {
        navigate(item.link);
      }
      toggleDrawerHandler(false);
    };

    const onLogoutClickHandler = useCallback((evt) => {
      logDebug("HeaderPage.DrawerMenu", "onLogoutClickHandler", {
        evt: evt,
      });
      dispatch(removeUser());
      navigate(getLocalePath("/"));
      successToast(
        "HeaderButtons",
        "message.log_out",
        t("message.log_out", t("message.default"))
      );
      toggleDrawerHandler(false);
    }, []);

    return (
      <Drawer
        open={open}
        onClose={(evt) => toggleDrawerHandler(evt, false)}
        anchor={"right"}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={(evt) => toggleDrawerHandler(evt, false)}
        >
          <List>
            <ListItem
              style={{
                display: "grid",
                gridTemplateRows: "1fr",
                gridTemplateColumns: "min-content auto",
              }}
              key={t("button.close")}
              title={t("button.close")}
              aria-label={t("button.close")}
              disablePadding
              onClick={(evt) => toggleDrawerHandler(evt, false)}
            >
              <ListItemButton>
                <CloseIcon color="primary" />
              </ListItemButton>
              <ListItemText
                title={"button.close"}
                aria-label={t("button.close")}
              >
                <Typography color="primary" variant="subheading">
                  {t("button.close")}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider sx={{ my: 0.5 }} />
            {items.map((item) => {
              return (
                <ListItem
                  style={{
                    display: "grid",
                    gridTemplateRows: "1fr",
                    gridTemplateColumns: "min-content auto",
                  }}
                  key={t(item.label)}
                  title={t(item.title)}
                  aria-label={t(item.label)}
                  disablePadding
                  onClick={(evt) => onListItemClickHandler(evt, item)}
                >
                  <ListItemButton>
                    <Icon color="primary">{item.icon}</Icon>
                  </ListItemButton>
                  <ListItemText>
                    <Typography color="primary" variant="subheading">
                      {t(item.label)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
            {!isEmpty(session_token) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <ListItem
                  style={{
                    display: "grid",
                    gridTemplateRows: "1fr",
                    gridTemplateColumns: "min-content auto",
                  }}
                  key={t("header.toolbar.logout")}
                  title={t("header.toolbar.logout")}
                  aria-label={t("header.toolbar.logout")}
                  disablePadding
                  onClick={(evt) => onLogoutClickHandler(evt)}
                >
                  <ListItemButton>
                    <LogoutIcon color="primary" />
                  </ListItemButton>
                  <ListItemText>
                    <Typography color="primary" variant="subheading">
                      {t("header.toolbar.logout")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Drawer>
    );
  };

  const HeaderMenuToolbar = (props) => {
    return (
      <div className="header-menu__toolbar">
        <div className="header-menu__toolbar__container">
          <div className="header-menu__toolbar__content">
            <Box spacing={2}>
              {!isProduction && (
                <>
                  <IconButton
                    color="primary"
                    title={t("header.toolbar.wallet_tooltip", {
                      amount: amount,
                    })}
                    aria-label={t("header.toolbar.wallet_tooltip", {
                      amount: amount,
                    })}
                    onClick={(evt) => onClickWalletHandler(evt)}
                  >
                    <WalletIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    title={
                      isEmpty(session_token)
                        ? t("header.toolbar.account")
                        : t("header.toolbar.user", { user_name: user_name })
                    }
                    aria-label={
                      isEmpty(session_token)
                        ? t("header.toolbar.account")
                        : t("header.toolbar.user", { user_name: user_name })
                    }
                    onClick={(evt) => onClickAccountHandler(evt)}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </>
              )}
              <CartButton items={items} />
              <IconButton
                color="primary"
                title={t("header.toolbar.menu")}
                aria-label={t("header.toolbar.menu")}
                onClick={(evt) => toggleDrawerHandler(evt, true)}
              >
                <MenuIcon />
              </IconButton>
              <DrawerMenu />
            </Box>
          </div>
        </div>
      </div>
    );
  };

  const HeaderMenu = (props) => {
    return (
      <div className="header-menu">
        <HeaderLogo />
        <HeaderButtons />
      </div>
    );
  };

  const HeaderWithNavigation = (props) => {
    return (
      <>
        <HeaderMenu />
        <HeaderNav />
      </>
    );
  };

  const HeaderWithToolbar = (props) => {
    return (
      <div className="header-menu">
        <HeaderLogo />
        <HeaderMenuToolbar />
      </div>
    );
  };

  const onCloseLoginDialogHandler = useCallback(
    (evt) => {
      logDebug("HeaderPage", "onCloseLoginDialogHandler", {
        evt: evt,
      });
      const interval = setInterval(() => {
        dispatch(setOpenLoginDialog(!isOpenLoginDialog));
        clearInterval(interval);
      }, 100);
    },
    [isOpenLoginDialog, dispatch]
  );

  const onCloseRegisterDialogHandler = useCallback(
    (evt) => {
      logDebug("HeaderPage", "onCloseRegisterDialogHandler", {
        evt: evt,
      });
      const interval = setInterval(() => {
        dispatch(setOpenRegisterDialog(!isOpenRegisterDialog));
        clearInterval(interval);
      }, 100);
    },
    [isOpenRegisterDialog, dispatch]
  );

  const onCloseForgotPassDialogHandler = useCallback(
    (evt) => {
      logDebug("HeaderPage", "onCloseForgotPassDialogHandler", {
        evt: evt,
      });
      const interval = setInterval(() => {
        dispatch(setOpenForgotPassDialog(!isOpenForgotPassDialog));
        clearInterval(interval);
      }, 100);
    },
    [isOpenForgotPassDialog, dispatch]
  );

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__content">
          {isUpperMd ? <HeaderWithNavigation /> : <HeaderWithToolbar />}
        </div>
      </div>
      {isOpenLoginDialog && (
        <LoginDialog
          isOpen={isOpenLoginDialog}
          onClose={(evt) => onCloseLoginDialogHandler(evt)}
        />
      )}
      {isOpenRegisterDialog && (
        <RegisterDialog
          isOpen={isOpenRegisterDialog}
          onClose={(evt) => onCloseRegisterDialogHandler(evt)}
        />
      )}
      {isOpenForgotPassDialog && (
        <ForgotPassDialog
          isOpen={isOpenForgotPassDialog}
          onClose={(evt) => onCloseForgotPassDialogHandler(evt)}
        />
      )}
    </div>
  );
}

export default HeaderPage;
