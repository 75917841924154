import { ThemeProvider } from "@emotion/react";

import Router from "./router";
import { main as theme } from "./theme";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="app"></div>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
