import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createMigrate from "redux-persist/es/createMigrate";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import uiReducer from "./uiReducer";

import euromillionsReducer from "./euromillionsReducer";
import primitivaReducer from "./primitivaReducer";
import bonolotoReducer from "./bonolotoReducer";
import gordoReducer from "./gordoReducer";
import quinielaReducer from "./quinielaReducer";
import powerballReducer from "./powerballReducer";
import megamillionsReducer from "./megamillionsReducer";
import superenalottoReducer from "./superenalottoReducer";
import eurodreamsReducer from "./eurodreamsReducer";

import userReducer, {
  currentVersion as userCurrentVersion,
  migrations as userMigrations,
} from "./userReducer";

import cartReducer, {
  currentVersion as cartCurrentVersion,
  migrations as cartMigrations,
} from "./cartReducer";

import { equalsIgnoringCase } from "../../utils";

const persistedUserReducer = persistReducer(
  {
    key: "user",
    storage: storage,
    whitelist: ["user"],
    version: userCurrentVersion,
    migrate: createMigrate(userMigrations, {
      debug: !equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: !equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV),
  },
  userReducer
);

const persistedCartReducer = persistReducer(
  {
    key: "cart",
    storage: storage,
    whitelist: ["cart"],
    version: cartCurrentVersion,
    migrate: createMigrate(cartMigrations, {
      debug: !equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV),
    }),
    stateReconciler: autoMergeLevel2,
    debug: !equalsIgnoringCase("PRODUCTION", process.env.REACT_APP_ENV),
  },
  cartReducer
);

export {
  uiReducer,
  euromillionsReducer,
  primitivaReducer,
  bonolotoReducer,
  gordoReducer,
  quinielaReducer,
  powerballReducer,
  megamillionsReducer,
  superenalottoReducer,
  eurodreamsReducer,
  persistedUserReducer as userReducer,
  persistedCartReducer as cartReducer,
};
