import { useCallback, useMemo, useState } from "react";
import { equalsIgnoringCase, isEmpty } from "../../utils";

const useGetPostByParams = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUrl = useCallback((params) => {
    const urlBase = `${process.env.REACT_APP_WP_BASE_URL}/wp-json/wp/v2/posts`;

    if (!isEmpty(params)) {
      let catId = null;
      let slug = null;
      let search = null;
      if (!isEmpty(params.filter)) {
        catId = !isEmpty(params.filter.category_id)
          ? `categories=${params.filter.category_id}`
          : null;
        slug = !isEmpty(params.filter.slug)
          ? `slug=${params.filter.slug}`
          : null;
        search = !isEmpty(params.filter.search)
          ? `search=${params.filter.search}`
          : null;
      }

      let page = null;
      let perPage = null;
      if (!isEmpty(params.pagination)) {
        page = !isEmpty(params.pagination.index)
          ? `page=${params.pagination.index}`
          : null;
        perPage = !isEmpty(params.pagination.pageSize)
          ? `per_page=${params.pagination.pageSize}`
          : null;
      }

      const filters = [catId, slug, search, page, perPage]
        .filter((item) => !isEmpty(item))
        .join("&");

      return `${urlBase}?${filters}`;
    }
    return urlBase;
  }, []);

  const authMemo = useMemo(() => {
    return `Basic ${btoa(
      `${process.env.REACT_APP_WP_USER}:${process.env.REACT_APP_WP_PASS}`
    )}`;
  }, []);

  const getPostByParams = useCallback(
    (params) => {
      try {
        setLoading(true);
        fetch(getUrl(params), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authMemo,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (
              !isEmpty(data.code) &&
              equalsIgnoringCase(data.code, "rest_post_invalid_page_number")
            ) {
              setData(null);
              setError(data.code);
            } else {
              setData(data);
              setError(null);
            }
            setLoading(false);
          })
          .catch((error) => {
            setData(null);
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setData(null);
        setError(error);
        setLoading(false);
      }
    },
    [getUrl, authMemo]
  );

  return { loading, data, error, getPostByParams };
};

export default useGetPostByParams;
