import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

function CountdonwnComponent(props) {
  const {
    date,
    className,
    onMount,
    onStart,
    onPause,
    onStop,
    onTick,
    onComplete,
  } = props;

  const { t } = useTranslation();

  const Completionist = () => <span>{t("message.finalized_draw")}</span>;

  const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      if (days) {
        return (
          <span className={className}>{`${days}d ${hours}h ${minutes}m`}</span>
        );
      } else if (hours) {
        return (
          <span
            className={className}
          >{`${hours}h ${minutes}m ${seconds}s`}</span>
        );
      } else if (minutes) {
        return <span className={className}>{`${minutes}m ${seconds}s`}</span>;
      }
    }
  };

  return (
    <Countdown
      date={date}
      zeroPadTime={2}
      zeroPadDays={2}
      renderer={renderer}
      onMount={onMount}
      onStart={onStart}
      onPause={onPause}
      onStop={onStop}
      onTick={onTick}
      onComplete={onComplete}
    />
  );
}

export default CountdonwnComponent;
