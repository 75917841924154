import { useCallback, useMemo, useState } from "react";

import moment from "moment/moment";

import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import { removeItem } from "../../../redux/reducers/cartReducer";

import {
  Box,
  Card,
  Stack,
  Paper,
  Button,
  Divider,
  Collapse,
  IconButton,
  Typography,
  CardActions,
  CardContent,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  isEmpty,
  DATE_FORMAT,
  isEmptyList,
  isEmptyObject,
  moneyFormatter,
  equalsIgnoringCase,
} from "../../../utils";
import { TABS } from "../../../utils/constants";
import { logDebug } from "../../../utils/logger";

function CartItem(props) {
  const { item } = props;

  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const format = useMemo(() => {
    return equalsIgnoringCase(i18n.resolvedLanguage, "es")
      ? DATE_FORMAT.ES_SHORT_FORMAT
      : DATE_FORMAT.US_SHORT_FORMAT;
  }, [i18n.resolvedLanguage]);

  const onClickExpandHandler = useCallback(
    (evt, item) => {
      logDebug("CartItem", "onClickExpandHandler", {
        evt: evt,
        item: item,
      });
      setExpanded(!expanded);
    },
    [expanded]
  );

  const onClickDeleteHandler = useCallback(
    (evt, item) => {
      logDebug("CartItem", "onClickDeleteHandler", {
        evt: evt,
        item: item,
      });
      if (!isEmptyObject(item) && !isEmpty(item.id)) {
        dispatch(removeItem(item));
      }
    },
    [dispatch]
  );

  const DrawTitle = (props) => {
    const { item } = props;

    const draw_title = useMemo(() => {
      if (!isEmptyObject(item) && !isEmptyObject(item.draw)) {
        const titleChunks = [item.draw.title];
        if (!isEmptyObject(item.config)) {
          if (item.config.joker) {
            titleChunks.push(t("primitiva.body.addons.joker"));
          }
          if (item.config.powerplay) {
            titleChunks.push(t("powerball.body.addons.powerplay"));
          }
          if (item.config.megaplier) {
            titleChunks.push(t("megamillions.body.addons.megaplier"));
          }
          if (item.config.superstar) {
            titleChunks.push(t("superenalotto.body.addons.superstar"));
          }
        }
        return titleChunks.join(" + ");
      }
      return "";
    }, [item]);

    const draw_date = useMemo(() => {
      return moment(item.draw.draw_date).format(format);
    }, [item]);

    return (
      <Typography
        component="span"
        sx={{
          fontWeight: 600,
          fontSize: "16px",
        }}
        title={t("cart.body.items.draw_title", {
          draw_title: draw_title,
          draw_date: draw_date,
        })}
        aria-label={t("cart.body.items.draw_title", {
          draw_title: draw_title,
          draw_date: draw_date,
        })}
      >
        {t("cart.body.items.draw_title", {
          draw_title: draw_title,
          draw_date: draw_date,
        })}
      </Typography>
    );
  };

  const DrawDate = (props) => {
    const { item } = props;

    return (
      <Typography
        component="span"
        title={t("cart.body.items.draw_date", {
          draw_date: moment(item.draw.draw_date).format(format),
        })}
        aria-label={t("cart.body.items.draw_date", {
          draw_date: moment(item.draw.draw_date).format(format),
        })}
      >
        {t("cart.body.items.draw_date", {
          draw_date: moment(item.draw.draw_date).format(format),
        })}
      </Typography>
    );
  };

  const BetType = (props) => {
    const { item } = props;
    return (
      <Typography
        component="span"
        title={t("cart.body.items.multiple", {
          multiple: t(
            `message.${
              equalsIgnoringCase(TABS.MULTIPLE, item.config.type) ? "yes" : "no"
            }`
          ),
        })}
        aria-label={t("cart.body.items.multiple", {
          multiple: t(
            `message.${
              equalsIgnoringCase(TABS.MULTIPLE, item.config.type) ? "yes" : "no"
            }`
          ),
        })}
      >
        {t("cart.body.items.multiple", {
          multiple: t(
            `message.${
              equalsIgnoringCase(TABS.MULTIPLE, item.config.type) ? "yes" : "no"
            }`
          ),
        })}
      </Typography>
    );
  };

  const BetSubscription = (props) => {
    const { item } = props;
    return (
      <Typography
        component="span"
        title={t("cart.body.items.subscription", {
          subscription: t(`message.${item.config.subscription ? "yes" : "no"}`),
        })}
        aria-label={t("cart.body.items.subscription", {
          subscription: t(`message.${item.config.subscription ? "yes" : "no"}`),
        })}
      >
        {t("cart.body.items.subscription", {
          subscription: t(`message.${item.config.subscription ? "yes" : "no"}`),
        })}
      </Typography>
    );
  };

  const BetAddons = (props) => {
    const { item } = props;

    if (item.config.joker) {
      return (
        <Typography
          component="span"
          title={t("cart.body.items.joker", {
            joker: t(`message.${item.config.joker ? "yes" : "no"}`),
          })}
          aria-label={t("cart.body.items.joker", {
            joker: t(`message.${item.config.joker ? "yes" : "no"}`),
          })}
        >
          {t("cart.body.items.joker", {
            joker: t(`message.${item.config.joker ? "yes" : "no"}`),
          })}
        </Typography>
      );
    }

    if (item.config.powerplay) {
      return (
        <Typography
          component="span"
          title={t("cart.body.items.powerplay", {
            powerplay: t(`message.${item.config.powerplay ? "yes" : "no"}`),
          })}
          aria-label={t("cart.body.items.powerplay", {
            powerplay: t(`message.${item.config.powerplay ? "yes" : "no"}`),
          })}
        >
          {t("cart.body.items.powerplay", {
            powerplay: t(`message.${item.config.powerplay ? "yes" : "no"}`),
          })}
        </Typography>
      );
    }

    if (item.config.megaplier) {
      return (
        <Typography
          component="span"
          title={t("cart.body.items.megaplier", {
            megaplier: t(`message.${item.config.megaplier ? "yes" : "no"}`),
          })}
          aria-label={t("cart.body.items.megaplier", {
            megaplier: t(`message.${item.config.megaplier ? "yes" : "no"}`),
          })}
        >
          {t("cart.body.items.megaplier", {
            megaplier: t(`message.${item.config.megaplier ? "yes" : "no"}`),
          })}
        </Typography>
      );
    }

    if (item.config.superstar) {
      return (
        <Typography
          component="span"
          title={t("cart.body.items.superstar", {
            superstar: t(`message.${item.config.superstar ? "yes" : "no"}`),
          })}
          aria-label={t("cart.body.items.superstar", {
            superstar: t(`message.${item.config.superstar ? "yes" : "no"}`),
          })}
        >
          {t("cart.body.items.superstar", {
            superstar: t(`message.${item.config.superstar ? "yes" : "no"}`),
          })}
        </Typography>
      );
    }
    return <></>;
  };

  const DrawBets = (props) => {
    const { item } = props;

    return (
      <>
        {item.bets.map((bet) => {
          return (
            <Box
              key={bet.id}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              mt={2}
            >
              {!isEmptyList(bet.numbers) &&
                bet.numbers.map((number, idx_number) => {
                  return (
                    <Paper
                      key={`${bet.id}-${idx_number}`}
                      variant="outlined"
                      title={number}
                      aria-label={number}
                      sx={{
                        width: "30px",
                        height: "30px",
                        marginLeft: idx_number === 0 ? 0 : 1 / 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: "primary.main",
                        backgroundColor: "primary.contrastText",
                      }}
                    >
                      {number}
                    </Paper>
                  );
                })}
              {!isEmptyList(bet.specials) &&
                bet.specials.map((special, idx_special) => {
                  return (
                    <Paper
                      key={`${bet.id}-${idx_special}`}
                      variant="outlined"
                      title={special}
                      aria-label={special}
                      sx={{
                        width: "30px",
                        height: "30px",
                        padding: 1 / 2,
                        marginLeft: idx_special === 0 ? 1 : 1 / 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 600,
                        color: "primary.contrastText",
                        backgroundColor: "primary.main",
                      }}
                    >
                      {special}
                    </Paper>
                  );
                })}
            </Box>
          );
        })}
      </>
    );
  };

  const BetPrice = (props) => {
    const { item } = props;

    const formattedBetPrice = useMemo(() => {
      if (!isEmptyObject(item)) {
        const value = item.price / 100;
        const currency = item.draw.price_currency;
        return moneyFormatter(value, i18n.resolvedLanguage, currency);
      }
      return moneyFormatter(0, i18n.resolvedLanguage, "EUR");
    }, [item]);

    return (
      <Typography
        variant="body1"
        component="span"
        sx={{ fontWeight: 600 }}
        title={formattedBetPrice}
        aria-label={formattedBetPrice}
      >
        {formattedBetPrice}
      </Typography>
    );
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Box
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "max-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              src={item.draw.logo}
              alt={item.draw.title}
              aria-label={item.draw.title}
              sx={{
                width: "80px",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginLeft: 2,
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <DrawTitle item={item} />
            <DrawBets item={item} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BetPrice item={item} />
          </Box>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ExpandMore
            expand={String(expanded)}
            aria-expanded={expanded}
            titleAccess={t("button.expand")}
            sx={{
              transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
              }),
            }}
            onClick={(evt) => onClickExpandHandler(evt, item)}
          >
            <ExpandMoreIcon color="primary" aria-label={t("button.expand")} />
          </ExpandMore>
          {isUpperMd ? (
            <Button
              variant="text"
              color="primary"
              title={t("button.remove")}
              aria-label={t("button.remove")}
              startIcon={<DeleteIcon color="primary" />}
              onClick={(evt) => onClickDeleteHandler(evt, item)}
            >
              {t("button.remove")}
            </Button>
          ) : (
            <IconButton
              color="primary"
              title={t("button.remove")}
              aria-label={t("button.remove")}
              onClick={(evt) => onClickDeleteHandler(evt, item)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ pt: 0 }}>
          <Stack>
            <DrawDate item={item} />
            <BetType item={item} />
            <BetSubscription item={item} />
            <BetAddons item={item} />
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default CartItem;
