import FooterPage from "../../../components/footer";

function RafflesNavidadFooter() {
  return (
    <div className="raffle-navidad-footer">
      <FooterPage />
    </div>
  );
}

export default RafflesNavidadFooter;
