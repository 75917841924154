import { useCallback, useMemo } from "react";

import moment from "moment";
import classNames from "classnames";

import { Trans, useTranslation } from "react-i18next";
import useGetLocalePath from "../../../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";

import { GAME_OID } from "../../../../utils/constants";
import { DATE_FORMAT, isEmpty, nFormatter } from "../../../../utils";
import CountdonwnComponent from "../../../../components/countdown-component";

function RaffleSlide(props) {
  const { item } = props;

  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const closeDate = useMemo(() => {
    if (!isEmpty(item.close_date)) {
      return moment(item.close_date).format(DATE_FORMAT.US_LONG_FORMAT);
    }
    return "";
  }, [item.close_date]);

  const disabled = useMemo(() => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const expired = moment(now).isAfter(moment(closeDate));
    return !item.is_enabled || expired;
  }, [item.is_enabled, closeDate]);

  const getRaffleRoute = useCallback((raffle) => {
    if (!isEmpty(raffle.draw_date) && !isEmpty(raffle.price)) {
      const drawDate = moment(raffle.draw_date, DATE_FORMAT.US_LONG_FORMAT);
      const dayOfTheWeek = drawDate.isoWeekday();
      if (
        [4, 6].includes(dayOfTheWeek) &&
        ["300", "600"].includes(raffle.price)
      ) {
        return dayOfTheWeek;
      } else {
        const day = drawDate.date();
        const month = drawDate.month();
        if (day === 22 && month === 11) {
          return 9;
        } else if (day === 6 && month === 0) {
          return 10;
        } else {
          return 8;
        }
      }
    } else {
      return "";
    }
  }, []);

  const getRafflePath = useCallback(
    (raffle) => {
      switch (raffle.game_oid) {
        case GAME_OID.LOTERIA_NAC:
          return getLocalePath(
            `${t("routes.public.raffles.index")}/${t(
              `routes.public.raffles.${getRaffleRoute(raffle)}`
            )}`
          );
        default:
          return "";
      }
    },
    [t, getLocalePath, getRaffleRoute]
  );

  const linkMemo = useMemo(() => {
    return disabled ? "" : getRafflePath(item);
  }, [disabled, item, getRafflePath]);

  const RaffleLogo = (props) => {
    const { item } = props;

    const logoMemo = useMemo(() => {
      return !isEmpty(item.logo)
        ? item.logo
        : "../../../assets/img/raffle_default.png";
    }, [item.logo]);

    return (
      <div className="raffle-slide__logo">
        <div className="raffle-slide__logo__container">
          <div className="raffle-slide__logo__content">
            <img
              src={logoMemo}
              alt={item.title}
              title={item.title}
              loading="eager"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    );
  };

  const PotText = (props) => {
    const { item } = props;

    const currency = "€";
    const { amount, symbol } = nFormatter(parseFloat(item.pot), 2, i18n);

    const Currency = (props) => <span className="currency">{currency}</span>;
    const Amount = (props) => <span className="amount">{amount}</span>;
    const Text = (props) => <span className="text">{symbol}</span>;

    return (
      <div className="raffle-slide__pot">
        <div className="raffle-slide__pot__container">
          <div className="raffle-slide__pot__content">
            <span className="pot">
              <Trans
                i18nKey={"raffle_card.msg_pot"}
                components={{
                  currency: <Currency />,
                  amount: <Amount />,
                  text: <Text />,
                }}
              ></Trans>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const CloseDateCountdownText = (props) => {
    return (
      <div className="raffle-slide__countdown">
        <div className="raffle-slide__countdown__container">
          <div className="raffle-slide__countdown__content">
            <CountdonwnComponent className="countdown" date={closeDate} />
          </div>
        </div>
      </div>
    );
  };

  const Waiting = (props) => {
    return (
      <div className="raffle-slide__waiting">
        <div className="raffle-slide__waiting__container">
          <div className="raffle-slide__waiting__content">
            <span className="waiting">{t("message.waiting_pot")}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Link
      className={classNames("raffle-slide", { disabled: disabled })}
      to={linkMemo}
      state={{ item: item }}
      title={item.title}
    >
      <div className="raffle-slide__container">
        <div className="raffle-slide__content">
          <div className="raffle-slide__header__container">
            <div className="raffle-slide__header__content">
              <RaffleLogo item={item} />
            </div>
          </div>
          <div className="raffle-slide__body__container">
            <div className="raffle-slide__body__content">
              {!isEmpty(item.pot) && parseFloat(item.pot) > 0 ? (
                <>
                  <PotText item={item} />
                  <CloseDateCountdownText item={item} />
                </>
              ) : (
                <Waiting />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default RaffleSlide;
