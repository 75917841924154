export const TRANSLATIONS = {
  app: {
    name: "loteriasonline",
    version: "1.0",
  },

  button: {
    accept: "Aceptar",
    cancel: "Cancelar",
    buy: "Comprar",
    remove: "Quitar",
    login: "Iniciar Sesión",
    register: "Crear Cuenta",
    close: "Cerrar",
    save: "Guardar",
    expand: "Expandir",
    play: "¡Jugar ahora!",
    delete: "Borrar",
    random: "Aleatorio",
    add_cart: "Añadir al carrito",
    load_more: "Cargar más...",
    go_post: "Ver post",
    subscription: "Suscripción",
    cart: "Ir al Carrito",
  },

  input: {
    search: {
      placeholder: "Buscar...",
    },
  },

  format: {
    number: {
      hundred: "",
      kilo: "mil",
      mega: "millones",
    },
  },

  message: {
    default: "La operación se ha realizado correctamente",
    yes: "Sí",
    no: "No",
    warning: "Advertencia",
    unsaved_changes: "Hay datos sin guardar, ¿quieres continuar?",
    finalized_draw: "Sorteo finalizado",
    waiting_pot: "Esperando bote",
    waiting_draw: "Esperando sorteo",
    empty_list:
      "No existen resultados con el criterio de búsqueda seleccionado",
    log_out: "La sesión se ha cerrado correctamente",
    recovery_mail: "Te hemos enviado un email para recuperar tu cuenta",
    success_login: "Bienvenido a tu cuenta",
    success_add_cart: "Añadido al carrito correctamente",
  },

  errors: {
    default: "Ha ocurrido un error, por favor, inténtalo de nuevo más tarde",
    page_not_found: "Página no encontrada",
    no_more_posts: "No hay más posts...",
    rest_post_invalid_id: "El identificador de la entrada no es válido",
    error_form: "Por favor, resuelve los problemas del formulario",
    invalid_email_or_pass: "El correo o la contraseña no son válidos",
    already_registered: "Ya existe un usuario registrado",
    expired_session: "La sesión ha expirado",
    server_error:
      "Ha ocurrido un error en el servidor, por favor, inténtalo de nuevo más tarde",
    password_mismatch: "Formato de contraseña no válido",
    invalid_email: "Email no válido",
    invalid_password: "Contraseña no válida",
  },

  validators: {
    notEmpty: "El campo no puede estar vacío",
    email: "El campo no es un email válido",
    minLength: "El campo no puede ser menor que {{value}} caracteres",
    maxLength: "El campo no puede ser mayor que {{value}} caracteres",
    no_whitespaces: "El campo no puede contener espacios en blanco",
    compareFields: "El valor del campo no concuerda con el anterior",
    lessThan: "El campo no puede ser menor que {{value}}",
    moreThan: "El campo no puede ser mayor que {{value}}",
    checkTrue: "El campo tiene que ser marcado",
    password_mismatch:
      "El campo debe contener al menos un dígito y una letra. Carácteres especiales: !@#$%_-",
  },

  routes: {
    public: {
      geoblock: {
        index: "geobloqueo",
      },
      landing: {
        index: "Inicio",
      },
      lotteries: {
        index: "loterias",
        euromillions: "euromillones",
        primitiva: "la-primitiva",
        bonoloto: "bonoloto",
        gordo: "el-gordo",
        quiniela: "quiniela",
        powerball: "powerball",
        megamillions: "megamillions",
        superenalotto: "superenalotto",
        eurodreams: "eurodreams",
      },
      raffles: {
        index: "rifas",
        4: "nacional-jueves",
        6: "nacional-sabado",
        8: "nacional-extra",
        9: "nacional-navidad",
        10: "nacional-nino",
      },
      syndicates: {
        index: "penyas",
      },
      blog: {
        index: "blog",
        category: "categoria",
      },
      results: {
        index: "resultados",
      },
      help: {
        index: "ayuda",
      },
      legal: {
        index: "legal",
      },
      privacy: {
        index: "privacidad",
      },
      cookies: {
        index: "cookies",
      },
      cart: {
        index: "carrito",
      },
    },
    private: {
      index: "micuenta",
    },
  },

  draws: {
    1: {
      class: {
        4: "national-thursday",
        6: "national-saturday",
        8: "national-extra",
        10: "national-nino",
        9: "national-navidad",
      },
      title: {
        4: "Rifa Nacional Jueves",
        6: "Rifa Nacional Sábado",
        8: "Rifa Nacional Extra",
        9: "Rifa Nacional Navidad",
        10: "Rifa Nacional El Niño",
      },
      description: {
        4: "Rifa Nacional Jueves - Descripción",
        6: "Rifa Nacional Sábado - Descripción",
        8: "Rifa Nacional Extra - Descripción",
        9: "Rifa Nacional Navidad - Descripción",
        10: "Rifa Nacional El Niño - Descripción",
      },
      logo: {
        4: "../../../assets/img/loteria_nat_thurday_es.png",
        6: "../../../assets/img/loteria_nat_saturday_es.png",
        8: "../../../assets/img/loteria_nat_extra_es.png",
        9: "../../../assets/img/loteria_nat_navidad_es.png",
        10: "../../../assets/img/loteria_nat_ninio_es.png",
      },
    },
    2: {
      class: "euromillions",
      title: "Euromillones",
      description: "Euromillones",
      logo: "../../../assets/img/euromillions_es.png",
    },
    3: {
      class: "primitiva",
      title: "La Primitiva",
      description: "La Primitiva",
      logo: "../../../assets/img/primitiva_es.png",
    },
    4: {
      class: "bonoloto",
      title: "Bonoloto",
      description: "Bonoloto",
      logo: "../../../assets/img/bonoloto_es.png",
    },
    5: {
      class: "gordo",
      title: "El Gordo",
      description: "El Gordo",
      logo: "../../../assets/img/gordo_primitiva_es.png",
    },
    6: {
      class: "quiniela",
      title: "Quiniela",
      description: "Quiniela",
      logo: "../../../assets/img/quiniela_es.png",
    },
    7: {
      class: "powerball",
      title: "Powerball",
      description: "Powerball",
      logo: "../../../assets/img/powerball_us.png",
    },
    8: {
      class: "megamillions",
      title: "Megamillions",
      description: "Megamillions",
      logo: "../../../assets/img/megamillions_us.png",
    },
    9: {
      class: "superenalotto",
      title: "Superenalotto",
      description: "Superenalotto",
      logo: "../../../assets/img/superenalotto_it.png",
    },
    10: {
      class: "eurodreams",
      title: "Eurodreams",
      description: "Eurodreams",
      logo: "../../../assets/img/eurodreams_es.png",
    },
  },

  header: {
    toolbar: {
      lottery: "Loterías",
      raffles: "Rifas",
      syndicates: "Peñas",
      results: "Resultados",
      blog: "Blog",
      help: "Ayuda",
      cart: "Carrito",
      login: "Iniciar Sesión",
      register: "Crear Cuenta",
      menu: "Menu",
      account: "Mi Cuenta",
      user: "¡Hola, {{user_name}}!",
      wallet: "{{amount}}",
      wallet_tooltip: "Tu cartera: $t(header.toolbar.wallet)",
      logout: "Cerrar Sesión",
    },
  },

  logo: {
    alt: "Lotería Castillo Logo",
    title: "Inicio",
  },

  footer: {
    legal_age: {
      alt: "Debes ser mayor de 18 años",
      title: "Debes ser mayor de 18 años",
      msg: "Debes ser mayor de 18 años",
    },
    links: {
      legal: "Condiciones",
      privacy: "Privacidad",
      cookies: "Cookies",
      help: "Ayuda",
    },
    socials: {
      youtube: {
        title: "Youtube",
        href: "https://www.youtube.com/@loteriacastillo",
      },
      facebook: {
        title: "Facebook",
        href: "https://www.facebook.com/loteriacastilloalaquas",
      },
      twitter: {
        title: "X (Twitter)",
        href: "https://twitter.com/CastilloAlaquas",
      },
      instagram: {
        title: "Instagram",
        href: "https://www.instagram.com/loteria_castillo/",
      },
      pinterest: {
        title: "Pinterest",
        href: "https://www.pinterest.es/loteriacastillo/",
      },
      linkedIn: {
        title: "LinkedIn",
        href: "http://www.linkedin.com/company/2926533",
      },
    },
  },

  lottery_card: {
    per_month: "/mes",
  },

  raffle_card: {
    pot: "<currency></currency><amount></amount> <text></text>",
    msg_pot: "¡$t(raffle_card.pot) en premios!",
    stock: "stock",
    msg_stock: "<content></content>",
  },

  syndicate_card: {
    bets: "{{num_bets}} apuestas",
  },

  login: {
    title: "Iniciar Sesión - $t(app.name)",
    description: "Iniciar Sesión - $t(app.name) Descripción",
    header: {
      title: "Iniciar Sesión",
      subtitle: "",
    },
    body: {
      form: {
        email: "Email",
        password: "Contraseña",
        log_in_btn: "Iniciar Sesión",
        forgot_password_btn: "Recuperar",
        forget_password_msg:
          "¿Olvidaste la Contraseña? <link1>Recuperar</link1>",
        create_account_msg: "¿No tienes Cuenta? <link1>Crear Cuenta</link1>",
      },
    },
    footer: {},
  },

  register: {
    title: "Crear Cuenta - $t(app.name)",
    description: "Crear Cuenta - $t(app.name) Descripción",
    header: {
      title: "Crear Cuenta",
      subtitle: "",
    },
    body: {
      form: {
        user_name: "Nombre Usuario",
        email: "Email",
        pass1: "Contraseña",
        pass2: "Repite Contraseña",
        terms:
          "Al hacer click, aceptas la política <link1>legal</link1> y la de <link2>privacidad</link2>.",
        create_account_btn: "Crear Cuenta",
        log_in_msg: "¿Tienes una cuenta? <link1>Iniciar Sesión</link1>",
      },
    },
    footer: {},
  },

  forgot_password: {
    title: "Recuperar Cuenta - $t(app.name)",
    description: "Recuperar Cuenta - $t(app.name) Descripción",
    header: {
      title: "Recuperar Cuenta",
    },
    body: {
      subtitle:
        "Por favor, introduce tu email y te enviaremos las instrucciones para crear una nueva contraseña",
      form: {
        email: "Email",
        forgot_pass: "Recuperar Cuenta",
      },
    },
    footer: {},
  },

  update_user: {
    title: "",
    description: "",
    header: {},
    body: {
      form: {
        title: "Información Usuario",
        personal_title: "Datos Personales",
        name: "Nombre",
        surname: "Apellidos",
        dni: "Documento Identidad",
        birthday: "Fecha de Nacimiento",
        phone: "Teléfono",
      },
    },
    footer: {},
  },

  change_password: {
    title: "",
    description: "",
    header: {},
    body: {
      form: {
        title: "Cambiar Contraseña",
        email: "Email",
        password: "Antigua Contraseña",
        newPassword1: "Nueva Contraseña",
        newPassword2: "Repite Nueva Contraseña",
      },
    },
    footer: {},
  },

  legal: {
    title: "Página Política Legal - $t(app.name)",
    description: "Página Política Legal - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Política Legal",
    },
    footer: {},
  },

  privacy: {
    title: "Página Política Privacidad - $t(app.name)",
    description: "Página Política Privacidad - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Política Privacidad",
    },
    footer: {},
  },

  cookies: {
    title: "Página Política Cookies - $t(app.name)",
    description: "Página Política Cookies - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Política Cookies",
    },
    footer: {},
  },

  cart: {
    title: "Página Carrito - $t(app.name)",
    description: "Página Carrito - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Carrito",
      items: {
        draw_title: "{{draw_title}} - {{draw_date}}",
        draw_date: "Fecha del sorteo: {{draw_date}}",
        multiple: "Apuesta múltiple: {{multiple}}",
        subscription: "Suscripción: {{subscription}}",
        joker: "Joker: {{joker}}",
        megaplier: "Megaplier: {{megaplier}}",
        powerplay: "Powerplay: {{powerplay}}",
        superstar: "Superstar: {{superstar}}",
      },
      login: {
        title: "$t(button.login)",
        subtitle:
          "Por favor, identifícate para continuar con la compra. Haz click en <login>{{loginLabelBtn}}</login> o en <register>{{registerLabelBtn}}</register>.",
      },
      summary: {
        title: "Resumen",
        total: "Total: {{total}}",
      },
    },
    footer: {},
  },

  geoblock: {
    title: "Página geobloqueada - $t(app.name)",
    description: "Página geobloqueada - $t(app.name) Descripción",
    header: {},
    body: {},
    footer: {},
  },

  landing: {
    title: "Página Principal - $t(app.name)",
    description: "Página Principal - $t(app.name) Descripción",
    header: {},
    body: {
      section: {
        slider: {
          title: "$t(app.name)",
        },
        lottery: {
          title: "Loterías",
        },
        raffle: {
          title: "Rifas",
        },
        syndicate: {
          title: "Peñas",
        },
      },
    },
    footer: {},
  },

  lotteries: {
    title: "Loterías - $t(app.name)",
    description: "Loterías - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Loterías",
    },
    footer: {},
  },

  raffles: {
    title: "Rifas - $t(app.name)",
    description: "Rifas - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Rifas",
    },
    footer: {},
  },

  raffle_thursday: {
    title: "Rifa Nacional Jueves - $t(app.name)",
    description: "Rifa Nacional Jueves - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
    },
    footer: {},
  },

  raffle_saturday: {
    title: "Rifa Nacional Sábado - $t(app.name)",
    description: "Rifa Nacional Sábado - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
    },
    footer: {},
  },

  raffle_extra: {
    title: "Rifa Nacional Extra - $t(app.name)",
    description: "Rifa Nacional Extra - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
    },
    footer: {},
  },

  raffle_navidad: {
    title: "Rifa Nacional Navidad - $t(app.name)",
    description: "Rifa Nacional Navidad - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
    },
    footer: {},
  },

  raffle_nino: {
    title: "Rifa Nacional El Niño - $t(app.name)",
    description: "Rifa Nacional El Niño - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
    },
    footer: {},
  },

  syndicates: {
    title: "Peñas - $t(app.name)",
    description: "Peñas - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Peñas",
    },
    footer: {},
  },

  // 2
  euromillions: {
    title: "Euromillones - $t(app.name)",
    description: "Euromillones - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
        specials: {
          title: "Estrellas",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números y cuántas estrellas quieras. Sólo tienes que multiplicar las combinaciones de números con las combinaciones de estrellas para saber el total de combinaciones que vas a jugar.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
          specials: {
            title: "Combinaciones de estrellas",
            num_specialsWithCount_one: "{{count}} estrella",
            num_specialsWithCount_other: "{{count}} estrellas",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 3
  primitiva: {
    title: "La Primitiva - $t(app.name)",
    description: "La Primitiva - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
      },
      addons: {
        refund: "Reintegro",
        joker: "Joker",
        play_joker: "Jugar $t(primitiva.body.addons.joker)",
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números quieras. No te olvides de marcar también el número de reintegro.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 4
  bonoloto: {
    title: "Bonoloto - $t(app.name)",
    description: "Bonoloto - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números quieras.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 5
  gordo: {
    title: "El Gordo - $t(app.name)",
    description: "El Gordo - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
      },
      addons: {
        key_number: "Número Clave",
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números quieras. No te olvides de marcar también el número clave.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // &
  quiniela: {
    title: "Quiniela - $t(app.name)",
    description: "Quiniela - $t(app.name) Descripción",
    header: {},
    body: {},
    footer: {},
  },

  // 7
  powerball: {
    title: "Powerball - $t(app.name)",
    description: "Powerball - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
        specials: {
          title: "Powerballs",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números y cuántos powerballs quieras. Sólo tienes que multiplicar las combinaciones de números con las combinaciones de powerballs para saber el total de combinaciones que vas a jugar.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      addons: {
        powerplay: "Powerplay",
        play_powerplay: "Jugar $t(powerball.body.addons.powerplay)",
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 8
  megamillions: {
    title: "Megamillions - $t(app.name)",
    description: "Megamillions - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
        specials: {
          title: "Megaballs",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números y cuántos megaballs quieras. Sólo tienes que multiplicar las combinaciones de números con las combinaciones de megaballs para saber el total de combinaciones que vas a jugar.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      addons: {
        megaplier: "Megaplier",
        play_megaplier: "Jugar $t(megamillions.body.addons.megaplier)",
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 9
  superenalotto: {
    title: "Superenalotto - $t(app.name)",
    description: "Superenalotto - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Bote actual",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números quieras.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      addons: {
        superstar: "Superstar",
        play_superstar: "Jugar $t(superenalotto.body.addons.superstar)",
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 10
  eurodreams: {
    title: "Eurodreams - $t(app.name)",
    description: "Eurodreams - $t(app.name) Descripción",
    header: {},
    body: {
      title: {
        play: "Jugar",
        pot: "Cada mes, durante 30 años",
        closingDate: "Fecha de cierre",
      },
      tabs: {
        single: "Apuesta simple",
        multiple: "Apuesta múltiple",
      },
      board: {
        numbers: {
          title: "Números",
        },
        specials: {
          title: "Sueños",
        },
      },
      config: {
        header: {
          title: "Apuesta automática",
          subtitle:
            "Selecciona tu jugada múltiple aleatoria seleccionando cuántos números y cuántos sueños quieras. Sólo tienes que multiplicar las combinaciones de números con las combinaciones de sueños para saber el total de combinaciones que vas a jugar.",
        },
        body: {
          numbers: {
            title: "Combinaciones de números",
            num_numbersWithCount_one: "{{count}} número",
            num_numbersWithCount_other: "{{count}} números",
            num_betsWithCount_one: "{{count}} apuesta",
            num_betsWithCount_other: "{{count}} apuestas",
          },
        },
      },
      summary: {
        num_draws: {
          title: "¿Cuántos sorteos quieres jugar?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Número de sorteos: {{value}}",
          draw_date: "Fecha del sorteo: {{date}}",
          range_draw_date: "Fecha del sorteo: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  template: {
    title: "Página - $t(app.name)",
    description: "Página - $t(app.name) Descripción",
    header: {},
    body: {},
    footer: {},
  },

  results: {
    title: "Resultados - $t(app.name)",
    description: "Resultados - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Resultados",
    },
    footer: {},
  },

  help: {
    title: "Ayuda - $t(app.name)",
    description: "Ayuda - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Ayuda",
    },
    footer: {},
  },

  blog: {
    title: "Blog - {{category}} - $t(app.name)",
    description: "Blog - {{category}} - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Blog",
      categories: "Categorias",
      last_posts: "Últimos posts",
      next_draws: "Próximos sorteos",
    },
    footer: {},
  },

  blog_category: {
    title: "Blog: Categoría {{category} - $t(app.name)",
    description: "Blog: Categoría {{category}} - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Blog",
      categories: "Categorias",
      last_posts: "Últimos posts",
      next_draws: "Próximos sorteos",
    },
    footer: {},
  },

  blog_post: {
    title: "Blog: Categoría {{category}} - {{title}} - $t(app.name)",
    description:
      "Blog: Categoría {{category}} - {{title}} - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Blog",
      categories: "Categories",
      last_posts: "Últimos posts",
      next_draws: "Próximos sorteos",
    },
    footer: {},
  },

  error_page: {
    title: "",
    description: "",
    header: {
      title: "Página no encontrada",
    },
    body: {
      message_count_down_one: "Redirigiendo en {{count}} segundo...",
      message_count_down_other: "Redirigiendo en {{count}} segundos...",
    },
    footer: {},
  },

  account: {
    title: "Mi Cuenta - $t(app.name)",
    description: "Mi Cuenta - $t(app.name) Descripción",
    header: {},
    body: {
      title: "Mi Cuenta",
      tabs: {
        personal: {
          index: "personal",
          title: "Mi Cuenta",
          label: "Mi Cuenta",
          anchor: "",
        },
        password: {
          index: "password",
          title: "Contraseña",
          label: "Contraseña",
          anchor: "password",
        },
        bets: {
          index: "apuestas",
          title: "Apuestas",
          label: "Apuestas",
          anchor: "apuestas",
        },
        movements: {
          index: "movimientos",
          title: "Movimientos",
          label: "Movimientos",
          anchor: "movimientos",
        },
        deposit: {
          index: "ingresar",
          title: "Ingresar Saldo",
          label: "Ingresar",
          anchor: "ingresar",
        },
        withdraw: {
          index: "retirar",
          title: "Retirar Saldo",
          label: "Retirar",
          anchor: "retirar",
        },
        subscription: {
          index: "suscripciones",
          title: "Suscripciones",
          label: "Suscripciones",
          anchor: "suscripciones",
        },
      },
    },
    footer: {},
  },
};
