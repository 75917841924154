import Loading from "../../../../components/loading";

function GordoSectionInfo(props) {
  const { loading, content } = props;

  return (
    <div className="gordo-section-info__container">
      {loading ? (
        <div className="gordo-section-info__loading">
          <Loading />
        </div>
      ) : (
        <div
          className="gordo-section-info__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
    </div>
  );
}

export default GordoSectionInfo;
