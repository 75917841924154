import { Ring } from "@uiball/loaders";
import { useMemo } from "react";
import { isEmpty } from "../../utils";

function Loading(props) {
  const { color } = props;

  const colorMemo = useMemo(() => {
    return !isEmpty(color) ? color : "#777e8b";
  }, [color]);

  return (
    <div className="loading-container">
      <Ring size={40} lineWeight={5} speed={2} color={colorMemo} />
    </div>
  );
}

export default Loading;
