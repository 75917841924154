import FooterPage from "../../../components/footer";

function PrivacyFooter() {
  return (
    <div className="privacy-footer">
      <FooterPage />
    </div>
  );
}

export default PrivacyFooter;
