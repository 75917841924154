import { useCallback, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  Stack,
  Divider,
  CardMedia,
  Typography,
  IconButton,
  CardContent,
  CardActions,
} from "@mui/material";
import Loading from "../../../components/loading";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RaffleNinoSectionTitle from "./raffle-nino-section-title";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { isEmpty, isEmptyList, isEmptyObject } from "../../../utils";

function RaffleNinoBody(props) {
  const {
    loadingRaffle,
    raffle,
    loadingStock,
    stock,
    loadingContent,
    content,
  } = props;

  const RaffleCard = (props) => {
    const { item } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState(0);

    const maxStock = useMemo(() => {
      if (!isEmptyObject(item) && !isEmpty(item.cantidad)) {
        return item.cantidad;
      }
      return "";
    }, [item]);

    const number = useMemo(() => {
      if (!isEmptyObject(item) && !isEmpty(item.numero)) {
        return item.numero;
      }
      return "";
    }, [item]);

    const image = useMemo(() => {
      if (!isEmptyObject(item) && !isEmpty(item.imagen)) {
        return `${process.env.REACT_APP_API_BASE_URL}/webservice3/web/img/sorteos/loteria-nacional/${item.imagen}`;
      }
      return "";
    }, [item]);

    const disableMinusButton = useMemo(() => {
      return value === 0;
    }, [value]);

    const disablePlusButton = useMemo(() => {
      return value >= item.cantidad;
    }, [value, item]);

    const onClickMinusHandler = useCallback(
      (evt) => setValue(value - 1),
      [value]
    );

    const onClickPlusHandler = useCallback(
      (evt) => setValue(value + 1),
      [value]
    );

    const onClickAddToCartHandler = useCallback((evt) => {}, []);

    const StockContent = (props) => {
      return (
        <div className="max-stock__container">
          <span className="max-stock__value">{maxStock}</span>
          <span className="max-stock__text">{t("raffle_card.stock")}</span>
        </div>
      );
    };

    return (
      <Card>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <CardMedia
            component="img"
            image={image}
            sx={{ maxWidth: "60px", minHeight: "115px" }}
          />
          <CardContent
            sx={{
              width: "100%",
              minHeight: "115px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontWeight: 600,
                  fontSize: "32px",
                }}
              >
                {number}
              </Typography>
            </Box>
          </CardContent>
        </Stack>
        <Divider />
        <CardActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Trans
                i18nKey={"raffle_card.msg_stock"}
                components={{
                  content: <StockContent />,
                }}
              ></Trans>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                color="primary"
                disabled={disableMinusButton}
                onClick={(evt) => onClickMinusHandler(evt)}
              >
                <RemoveCircleIcon />
              </IconButton>
              <Typography
                variant="body1"
                component={"span"}
                sx={{ margin: "0 4px" }}
              >
                {value}
              </Typography>
              <IconButton
                color="primary"
                disabled={disablePlusButton}
                onClick={(evt) => onClickPlusHandler(evt)}
              >
                <AddCircleIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                color="primary"
                disabled={value === 0}
                onClick={(evt) => onClickAddToCartHandler(evt)}
              >
                <AddShoppingCartIcon />
              </IconButton>
            </Box>
          </Box>
        </CardActions>
      </Card>
    );
  };

  const RaffleCards = (props) => {
    const { loadingStock, stock } = props;

    const numbers = useMemo(() => {
      let numbers = [];
      if (!isEmptyList(stock)) {
        stock.forEach((item) => {
          numbers = [...numbers, ...item.numbers];
        });
      }
      return numbers;
    }, [stock]);

    return (
      <>
        {loadingStock ? (
          <div className="raffle-nino-body__loading">
            <Loading />
          </div>
        ) : (
          <>
            {!isEmptyList(numbers) && (
              <Grid container spacing={2} padding={"48px 0"}>
                {numbers.map((item, idx) => (
                  <Grid key={idx} xs={12} md={4} lg={3}>
                    <RaffleCard item={item} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </>
    );
  };

  const RaffleInfo = (props) => {
    const { loadingContent, content } = props;
    return (
      <>
        {loadingContent ? (
          <div className="raffle-nino-body__loading">
            <Loading />
          </div>
        ) : (
          <div
            className="raffle-nino-body__info"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </>
    );
  };

  const RaffleNinoSectionContent = (props) => {
    const { loadingStock, stock, loadingContent, content } = props;

    return (
      <div className="raffle-nino-body__content">
        <RaffleCards loadingStock={loadingStock} stock={stock} />
        <RaffleInfo loadingContent={loadingContent} content={content} />
      </div>
    );
  };

  return (
    <div className="raffle-nino-body">
      <div className="raffle-nino-body__container">
        <RaffleNinoSectionTitle loadingRaffle={loadingRaffle} raffle={raffle} />
        <RaffleNinoSectionContent
          loadingStock={loadingStock}
          stock={stock}
          loadingContent={loadingContent}
          content={content}
        />
      </div>
    </div>
  );
}

export default RaffleNinoBody;
