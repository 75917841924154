import * as yup from "yup";

const getUserForgotPassSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t("validators.notEmpty"))
      .email(t("validators.email"))
      .min(1, t("validators.minLength", { value: 1 }))
      .max(60, t("validators.maxLength", { value: 60 })),
    //"g-recaptcha-response": yup.string().required(t("validators.notEmpty")),
  });
};

export default getUserForgotPassSchema;
