import FooterPage from "../../../components/footer";

function LotteriesFooter() {
  return (
    <div className="lotteries-footer">
      <FooterPage />
    </div>
  );
}

export default LotteriesFooter;
