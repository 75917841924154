import classNames from "classnames";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../hooks/useGetLocalePath";

import { NavLink } from "react-router-dom";

function HeaderNav() {
  const { t } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  return (
    <div className="header-nav">
      <nav className="header-nav__container">
        <ul className="header-nav__content">
          <NavLink
            to={getLocalePath(t("routes.public.lotteries.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.lottery")}
            end
          >
            <span className="label">{t("header.toolbar.lottery")}</span>
          </NavLink>
          <NavLink
            to={getLocalePath(t("routes.public.raffles.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.raffles")}
            end
          >
            <span className="label">{t("header.toolbar.raffles")}</span>
          </NavLink>
          <NavLink
            to={getLocalePath(t("routes.public.syndicates.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.syndicates")}
            end
          >
            <span className="label">{t("header.toolbar.syndicates")}</span>
          </NavLink>
          <NavLink
            to={getLocalePath(t("routes.public.results.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.results")}
            end
          >
            <span className="label">{t("header.toolbar.results")}</span>
          </NavLink>
          <NavLink
            to={getLocalePath(t("routes.public.blog.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.blog")}
            end
          >
            <span className="label">{t("header.toolbar.blog")}</span>
          </NavLink>
          <NavLink
            to={getLocalePath(t("routes.public.help.index"))}
            className={({ isActive }) =>
              classNames("link", { active: isActive })
            }
            title={t("header.toolbar.help")}
            end
          >
            <span className="label">{t("header.toolbar.help")}</span>
          </NavLink>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderNav;
