import HeaderPage from "../../../components/header";

function BlogPostHeader() {
  return (
    <div className="blog-post-header">
      <div className="blog-post-header__container">
        <div className="blog-post-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default BlogPostHeader;
