import { useTranslation } from "react-i18next";

import Loading from "../../../components/loading";

function CookiesBody(props) {
  const { loadingContent, content } = props;

  const { t } = useTranslation();

  return (
    <div className="cookies-body">
      <div className="cookies-body__container">
        <h1 className="cookies-body__title">{t("cookies.body.title")}</h1>
        <div className="cookies-body__content">
          {loadingContent ? (
            <div className="cookies-body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className="cookies-body__info"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CookiesBody;
