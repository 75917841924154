import { useCallback, useMemo } from "react";

import moment from "moment";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import Loading from "../../../components/loading";
import LotteryCard from "../../../components/lottery-card";
import LotterySlide from "../../LandingPage/body-lottery-section/lottery-slide";

import { DATE_FORMAT, isEmpty, isEmptyList } from "../../../utils";

function LotteriesBody(props) {
  const { loadingDraws, draws, loadingContent, content } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const isClosedDate = useCallback((item) => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    return moment(now).isAfter(moment(closeDate));
  }, []);

  const ListDrawCards = (props) => {
    const listDraws = useMemo(() => {
      if (!loadingDraws) {
        if (!isEmptyList(draws)) {
          const gameOidList = [];
          const lotteries = Array.from(draws);
          return lotteries
            .filter((lottery) => lottery.is_enabled && !isClosedDate(lottery))
            .sort((a, b) => new Date(a.close_date) - new Date(b.close_date))
            .filter((lottery) => {
              if (!gameOidList.includes(lottery.game_oid)) {
                gameOidList.push(lottery.game_oid);
                return true;
              }
              return false;
            })
            .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
        }
      }
      return [];
    }, []);

    const getLotteriesByOid = useCallback((draws, game_oid) => {
      if (!isEmptyList(draws) && !isEmpty(game_oid)) {
        return draws
          .filter(
            (draw) =>
              draw.is_enabled &&
              !isClosedDate(draw) &&
              draw.game_oid === game_oid
          )
          .sort((a, b) => new Date(a.close_date) - new Date(b.close_date));
      }
      return [];
    }, []);

    return (
      <>
        {listDraws.map((item, idx) => {
          if (isUpperMd) {
            return (
              <LotterySlide
                key={idx}
                item={item}
                draws={getLotteriesByOid(draws, item.game_oid)}
              />
            );
          } else {
            return (
              <LotteryCard
                key={idx}
                item={item}
                draws={getLotteriesByOid(draws, item.game_oid)}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <div className="lotteries-body">
      <div className="lotteries-body__container">
        <h1 className="lotteries-body__title">{t("lotteries.body.title")}</h1>
        <div className="lotteries-body__content">
          {loadingDraws ? (
            <div className="lotteries-body__loading">
              <Loading />
            </div>
          ) : (
            <div className="lotteries-body__cards">
              <ListDrawCards />
            </div>
          )}
          {loadingContent ? (
            <div className="lotteries-body__loading">
              <Loading />
            </div>
          ) : (
            <div
              className="lotteries-body__info"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LotteriesBody;
