import { useCallback, useMemo, useState } from "react";

import { equalsIgnoringCase, isEmpty, isEmptyObject } from "../../utils";

const useGetPageByPath = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUrl = useCallback((params) => {
    if (!isEmpty(params.path)) {
      return `${process.env.REACT_APP_WP_BASE_URL}/wp-json/castillo-int/v1/path/${params.path}`;
    }
  }, []);

  const config = useMemo(() => {
    return {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_WP_USER}:${process.env.REACT_APP_WP_PASS}`
        )}`,
      },
    };
  }, []);

  const getPageByPath = useCallback(
    (params) => {
      try {
        setLoading(true);
        fetch(getUrl(params), config)
          .then((response) => response.json())
          .then((data) => {
            if (
              !isEmptyObject(data) &&
              !isEmpty(data.code) &&
              equalsIgnoringCase(data.code, "rest_post_invalid_id")
            ) {
              setError(data.code);
            } else {
              setData(data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [getUrl, config]
  );
  return { loading, data, error, getPageByPath };
};

export default useGetPageByPath;
