import HeaderPage from "../../../components/header";

function SyndicateHeader() {
  return (
    <div className="syndicate-header">
      <div className="syndicate-header__container">
        <div className="syndicate-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default SyndicateHeader;
