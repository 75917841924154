import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import moment from "moment";
import classNames from "classnames";

import { Link } from "react-router-dom";
import CountdownComponent from "../countdown-component";

import { GAME_OID } from "../../utils/constants";
import { DATE_FORMAT, isEmpty, nFormatter } from "../../utils";

function RaffleCard(props) {
  const { item } = props;

  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const getRaffleRoute = useCallback((raffle) => {
    if (!isEmpty(raffle.draw_date) && !isEmpty(raffle.price)) {
      const drawDate = moment(raffle.draw_date, DATE_FORMAT.US_LONG_FORMAT);
      const dayOfTheWeek = drawDate.isoWeekday();
      if (
        [4, 6].includes(dayOfTheWeek) &&
        ["300", "600"].includes(raffle.price)
      ) {
        return dayOfTheWeek;
      } else {
        const day = drawDate.date();
        const month = drawDate.month();
        if (day === 22 && month === 11) {
          return 9;
        } else if (day === 6 && month === 0) {
          return 10;
        } else {
          return 8;
        }
      }
    } else {
      return "";
    }
  }, []);

  const getRafflePath = useCallback(
    (raffle) => {
      switch (raffle.game_oid) {
        case GAME_OID.LOTERIA_NAC:
          return getLocalePath(
            `${t("routes.public.raffles.index")}/${t(
              `routes.public.raffles.${getRaffleRoute(raffle)}`
            )}`
          );
        default:
          return "";
      }
    },
    [getLocalePath, getRaffleRoute, t]
  );

  const closeDate = useMemo(() => {
    return moment(item.close_date).format(DATE_FORMAT.US_LONG_FORMAT);
  }, [item.close_date]);

  const disabled = useMemo(() => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    const expired = moment(now).isAfter(moment(closeDate));
    return !item.is_enabled || expired;
  }, [item.is_enabled, item.close_date]);

  const linkMemo = useMemo(() => {
    return disabled ? "" : getRafflePath(item);
  }, [disabled, item, getRafflePath]);

  const logoMemo = useMemo(() => {
    return !isEmpty(item.logo)
      ? item.logo
      : "../../../assets/img/raffle_default.png";
  }, [item.logo]);

  const PotContainerComponent = (props) => {
    const potMemo = useMemo(() => {
      const currency = "€";
      const { amount, symbol } = nFormatter(parseFloat(item.pot), 2, i18n);

      const Currency = (props) => <span className="currency">{currency}</span>;
      const Amount = (props) => <span className="amount">{amount}</span>;
      const Text = (props) => <span className="text">{symbol}</span>;

      return (
        <>
          {item.pot_value <= parseInt(10000000) ? (
            <span className="pot">
              <Trans
                i18nKey={"raffle_card.pot"}
                components={{
                  currency: <Currency />,
                  amount: <Amount />,
                  text: <Text />,
                }}
              ></Trans>
            </span>
          ) : (
            <span className="pot">
              <span className="currency">{currency}</span>
              <span className="amount">{amount}</span>
              <span className="text">{symbol}</span>
            </span>
          )}
        </>
      );
    }, []);

    const waitingPotMemo = useMemo(() => {
      return (
        <span className="waiting" title={t("message.waiting_pot")}>
          {t("message.waiting_pot")}
        </span>
      );
    }, []);

    return (
      <div className="raffle-card__body__pot__content">
        {!isEmpty(item.pot) && parseFloat(item.pot) > 0
          ? potMemo
          : waitingPotMemo}
      </div>
    );
  };

  return (
    <Link
      className={classNames("raffle-card__container", { disabled: disabled })}
      title={item.title}
      to={linkMemo}
      state={{ item: item }}
    >
      <div className="raffle-card__header">
        <div className="raffle-card__header__logo__container">
          <img
            className="logo"
            src={logoMemo}
            alt={item.title}
            title={item.title}
            loading="eager"
            width="auto"
            height="auto"
          />
        </div>
      </div>
      <div className="raffle-card__body">
        <div className="raffle-card__body__pot__container">
          <PotContainerComponent />
        </div>
        <div className="raffle-card__body__countdown__container">
          <span className="icon icon-clock"></span>
          <CountdownComponent date={closeDate} />
        </div>
      </div>
      <div className="raffle-card__footer">
        <div className="raffle-card__footer__button__container">
          <div className="button" title={t("button.play")}>
            {t("button.play")}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default RaffleCard;
