import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserPasswordChangeSchema } from "../../../schemas";

import {
  Backdrop,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";

import SaveIcon from "@mui/icons-material/Save";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { logDebug } from "../../../utils/logger";

function UserChangePasswordForm(props) {
  const { loading, disabled, dataForm, onValid, onInvalid } = props;

  const { t } = useTranslation();

  const { user } = useSelector((state) => state.user);
  const { email } = user;

  const defaultValues = useMemo(() => {
    return {
      email: email,
      password: "",
      newPassword1: "",
      newPassword2: "",
    };
  }, [email]);

  const { control, setValue, handleSubmit } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    values: dataForm,
    defaultValues: defaultValues,
    resolver: yupResolver(getUserPasswordChangeSchema(t)),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword1, setShowNewPassword1] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const clickShowPasswordHandler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("UserPasswordChangeForm", "clickShowPasswordHandler", {
          evt: evt,
          value: showPassword,
        });
        setShowPassword(!showPassword);
      }
    },
    [disabled, showPassword]
  );

  const clickShowNewPassword1Handler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("UserPasswordChangeForm", "clickShowNewPassword1Handler", {
          evt: evt,
          value: !showNewPassword1,
        });
        setShowNewPassword1(!showNewPassword1);
      }
    },
    [disabled, showNewPassword1]
  );

  const clickShowNewPassword2Handler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("UserPasswordChangeForm", "clickShowNewPassword2Handler", {
          evt: evt,
          value: !showNewPassword2,
        });
        setShowNewPassword2(!showNewPassword2);
      }
    },
    [disabled, showNewPassword2]
  );

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("UserPasswordChangeForm", "onValidHandler", {
          data: data,
          evt: evt,
        });
        onValid(data, evt);
      }
    },
    [disabled, onValid]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("UserPasswordChangeForm", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        onInvalid(errors, evt);
      }
    },
    [disabled, onInvalid]
  );

  useEffect(() => {
    setValue("email", email);
  }, [email, setValue]);

  return (
    <>
      <form
        id={"user-change-password-form"}
        noValidate
        onSubmit={handleSubmit(onValidHandler, onInvalidHandler)}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography fontSize={"1.5rem"} fontWeight={600}>
              {t("change_password.body.form.title")}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Controller
              name="email"
              control={control}
              disabled={true}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("change_password.body.form.email")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "email",
                    form: {
                      autocomplete: "email",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="password"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("change_password.body.form.password")}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "password",
                    form: {
                      autocomplete: "password",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={field.disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => clickShowPasswordHandler(evt)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="newPassword1"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("change_password.body.form.newPassword1")}
                  type={showNewPassword1 ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autocomplete: "new-password",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={field.disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => clickShowNewPassword1Handler(evt)}
                        >
                          {showNewPassword1 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="newPassword2"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("change_password.body.form.newPassword2")}
                  type={showNewPassword2 ? "text" : "password"}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autocomplete: "new-password",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          disabled={field.disabled}
                          aria-label="toggle password visibility"
                          onClick={(evt) => clickShowNewPassword2Handler(evt)}
                        >
                          {showNewPassword2 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <LoadingButton
              loading={loading}
              disabled={disabled}
              type="submit"
              title={t("button.save")}
              aria-label={t("button.save")}
              variant="contained"
              color="primary"
              margin="none"
              size="large"
              startIcon={<SaveIcon />}
            >
              {t("button.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        open={loading}
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" thickness={10} />
      </Backdrop>
      <DevTool control={control} />
    </>
  );
}

export default UserChangePasswordForm;
