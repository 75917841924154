import FooterPage from "../../../components/footer";

function RafflesThursdayFooter() {
  return (
    <div className="raffle-thursday-footer">
      <FooterPage />
    </div>
  );
}

export default RafflesThursdayFooter;
