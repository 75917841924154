import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";
import useDrawsStock from "../../hooks/international-api/draws/useDrawsStock";

import SEO from "../../components/seo";
import LotteriesBody from "./lotteries-body";
import LotteriesHeader from "./lotteries-header";
import LotteriesFooter from "./lotteries-footer";
import { errorToast } from "../../components/toast";

import { logDebug, logWarn } from "../../utils/logger";
import { TYPE_REQUEST } from "../../hooks/international-api/utils";
import { equalsIgnoringCase, isEmpty, isEmptyObject } from "../../utils";

function LotteryPage() {
  const [draws, setDraws] = useState([]);
  const [page, setPage] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);

  const {
    data: dataDrawsStock,
    error: errorDrawsStock,
    loading: loadingDrawsStock,
    drawsStock,
  } = useDrawsStock();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("lotteries.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("lotteries.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  const pageRequest = useMemo(() => {
    return { path: pathname };
  }, [pathname]);

  const drawRequest = useMemo(() => {
    return {
      header: {
        type_request: !isEmptyObject(user)
          ? TYPE_REQUEST.USERNAME
          : TYPE_REQUEST.ANONIM,
        email: !isEmptyObject(user)
          ? user.email
          : process.env.REACT_APP_API_USER,
        token: !isEmptyObject(user)
          ? user.session_token
          : process.env.REACT_APP_API_PASS,
      },
      body: {},
    };
  }, [user]);

  useEffect(() => {
    drawsStock(drawRequest);
    getPageByPath(pageRequest);
    window.scrollTo(0, 0);
  }, [pageRequest, drawRequest, drawsStock, getPageByPath]);

  useEffect(() => {
    if (!loadingDrawsStock) {
      if (errorDrawsStock) {
        errorToast(
          "LotteriesPage - drawsStock",
          errorDrawsStock,
          t(`errors.${errorDrawsStock}`, t("errors.default"))
        );
      } else if (dataDrawsStock) {
        setDraws(dataDrawsStock.lotteries);
        logDebug("LotteriesPage", "drawsStock", {
          lotteries: [...dataDrawsStock.lotteries],
        });
      }
    }
  }, [loadingDrawsStock, dataDrawsStock, errorDrawsStock, t]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("LotteriesPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "LotteriesPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("LotteriesPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="lotteries">
        <div className="lotteries__container">
          <LotteriesHeader />
          <LotteriesBody
            loadingDraws={loadingDrawsStock}
            draws={draws}
            loadingContent={loadingPageByPath}
            content={content}
          />
          <LotteriesFooter />
        </div>
      </div>
    </>
  );
}

export default LotteryPage;
