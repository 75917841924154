import { useCallback, useMemo, useState } from "react";

import crypto from "crypto-js";
import moment from "moment/moment";

import { isEmpty } from "../../../utils";
import { parseDataJson } from "../utils";
import { logError } from "../../../utils/logger";

const useUserChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getRandStringHandler = useCallback((length = 16) => {
    let result = "";
    const chars = "0123456789abcdef";
    let counter = 0;
    while (counter < length) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
      counter++;
    }
    return result;
  }, []);

  const encryptHandler = useCallback((secret) => {
    return btoa(crypto.SHA1(secret).toString(crypto.enc.Latin1));
  }, []);

  const deviceToken = useMemo(() => {
    return process.env.REACT_APP_API_USER;
  }, []);

  const vendor = useMemo(() => `YDRAY-${process.env.REACT_APP_API_USER}`, []);

  const getXWSSE = useCallback(
    (type_request, email, token) => {
      if (!isEmpty(type_request) && !isEmpty(email) && !isEmpty(token)) {
        const randomStr = getRandStringHandler(16);
        const username = email;
        const password = token;
        const encoded_nonce = btoa(randomStr);
        const createdDate = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
        const type = type_request;
        const secret = `${atob(encoded_nonce)}${createdDate}${password}`;
        const passwordDigest = encryptHandler(secret);
        return `UsernameToken Username="${username}", PasswordDigest="${passwordDigest}", Nonce="${encoded_nonce}", Created="${createdDate}", Type="${type}"`;
      }
      return "";
    },
    [getRandStringHandler, encryptHandler]
  );

  const url = `${process.env.REACT_APP_API_PUBLIC_URL}/secured/user/password/change`;

  const userChangePassword = useCallback(
    (params) => {
      try {
        setLoading(true);
        fetch(url, {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "device-token": deviceToken,
            vendor: vendor,
            "X-WSSE": getXWSSE(
              params.header.type_request,
              params.header.email,
              params.header.token
            ),
          },
          body: parseDataJson(params.body),
        })
          .then(async (response) => {
            switch (response.status) {
              // 200 - Response OK
              case 200:
                setData("success");
                setError(null);
                break;
              // 401 - Access Denied: Wrong Autenticated User
              case 401:
                setError("expired_session");
                setData(null);
                break;
              // 403 - Password Mismatch
              case 403:
                setError("password_mismatch");
                setData(null);
                break;
              // 406 - Invalid Password
              case 406:
                setError("invalid_password");
                setData(null);
                break;
              // 500 - Server Error
              case 500:
                setError("server_error");
                setData(null);
                break;
              // XXX - Other Status Response
              default:
                setError(response.status);
                setData(null);
                logError("useUserRegister - response", "default", {
                  response: response,
                });
                break;
            }
            setLoading(false);
          })
          .catch((error) => {
            logError("userChangePassword", "fetch", { error: error });
            setError("default");
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        logError("userChangePassword", "fetch", { error: error });
        setError("default");
        setLoading(false);
      }
    },
    [url, deviceToken, vendor, getXWSSE]
  );

  return { loading, data, error, userChangePassword };
};

export default useUserChangePassword;
