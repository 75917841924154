import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet-async";
import { isEmpty } from "../../utils";

function SEO(props) {
  const { url, metadata, pageTitle, pageDesc } = props;

  const { t, i18n } = useTranslation();

  const metadataMemo = useMemo(() => {
    return !isEmpty(metadata) ? metadata : {};
  }, [metadata]);

  const titleMemo = useMemo(() => {
    return !isEmpty(metadataMemo) && !isEmpty(metadataMemo.title)
      ? metadataMemo.title
      : pageTitle;
  }, [metadataMemo, pageTitle]);

  const descriptionMemo = useMemo(() => {
    return !isEmpty(metadataMemo) && !isEmpty(metadataMemo.description)
      ? metadataMemo.description
      : pageDesc;
  }, [metadataMemo, pageDesc]);

  const typeMemo = useMemo(() => {
    return !isEmpty(metadataMemo) && !isEmpty(metadataMemo.og_type)
      ? metadataMemo.og_type
      : "article";
  }, [metadataMemo]);

  const urlMemo = useMemo(() => {
    return !isEmpty(url) ? url : "";
  }, [url]);

  const canonicalMemo = useMemo(() => {
    return !isEmpty(urlMemo) ? urlMemo : "";
  }, [urlMemo]);

  const siteNameMemo = useMemo(() => {
    return !isEmpty(metadataMemo) && !isEmpty(metadataMemo.og_site_name)
      ? metadataMemo.og_site_name
      : t("app.name");
  }, [metadataMemo, t]);

  const imgMemo = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/assets/img/logo.webp`;
  }, []);

  return (
    <Helmet htmlAttributes={{ lang: i18n.resolvedLanguage }}>
      {/* Metatags principales  */}
      <title>{titleMemo}</title>
      <meta name="description" content={descriptionMemo} />
      {/* Links */}
      <link rel="canonical" href={canonicalMemo} />
      {/* Open Graph */}
      <meta property="og:type" content={typeMemo} />
      <meta property="og:title" content={titleMemo} />
      <meta property="og:description" content={descriptionMemo} />
      <meta property="og:url" content={urlMemo} />
      <meta property="og:site_name" content={siteNameMemo} />
      {/* Twitter */}
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:site" content={"@CastilloAlaquas"} />
      <meta name="twitter:creator" content={"@CastilloAlaquas"} />
      <meta name="twitter:title" content={titleMemo} />
      <meta name="twitter:description" content={descriptionMemo} />
      <meta name="twitter:image" content={imgMemo} />
      <meta name="twitter:image:alt" content={titleMemo} />
      <meta name="twitter:url" content={urlMemo} />
    </Helmet>
  );
}

export default SEO;
