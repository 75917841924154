import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../utils";

function GordoMultipleContainer(props) {
  const { config, onChangeConfig, onRandomNumbers } = props;

  const { t } = useTranslation();

  const NumberCombinationContainer = (props) => {
    const { maxNumbers, onChangeMaxNumbers } = props;

    const combinatories = [
      { maxNumbers: 6, bets: 6 },
      { maxNumbers: 7, bets: 21 },
      { maxNumbers: 8, bets: 56 },
      { maxNumbers: 9, bets: 126 },
      { maxNumbers: 10, bets: 252 },
      { maxNumbers: 11, bets: 462 },
    ];

    const isSelected = useCallback(
      (value) => maxNumbers === value,
      [maxNumbers]
    );

    const onClickHandler = useCallback(
      (evt, value) => onChangeMaxNumbers(evt, value),
      [onChangeMaxNumbers]
    );

    return (
      <>
        <div className="gordo-board__config__body__title-container">
          <div className="gordo-board__config__body__title-content">
            {t("gordo.body.config.body.numbers.title")}
          </div>
        </div>
        <div className="gordo-board__config__body__combination-container">
          <div className="gordo-board__config__body__combination-content numbers">
            {combinatories.map((value) => {
              return (
                <div
                  key={value.maxNumbers}
                  className={classNames(
                    "gordo-board__config__body__combination-item",
                    { selected: isSelected(value.maxNumbers) }
                  )}
                  onClick={(evt) => onClickHandler(evt, value.maxNumbers)}
                >
                  <div className="gordo-board__config__body__combination-item__header">
                    <div className="number">{value.maxNumbers}</div>
                  </div>
                  <div className="gordo-board__config__body__combination-item__body">
                    <div className="numbers">
                      {t(
                        "gordo.body.config.body.numbers.num_numbersWithCount",
                        { count: value.maxNumbers }
                      )}
                    </div>
                    <div className="bets">
                      {t("gordo.body.config.body.numbers.num_betsWithCount", {
                        count: value.bets,
                      })}
                    </div>
                  </div>
                  <div className="gordo-board__config__body__combination-item__footer">
                    <div className="icon icon-dice"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const GordoConfigHeader = (props) => {
    return (
      <div className="gordo-board__config__header">
        <div className="gordo-board__config__header__title-container">
          <div className="gordo-board__config__header__title-content">
            {t("gordo.body.config.header.title")}
          </div>
        </div>
        <div className="gordo-board__config__header__subtitle-container">
          <div className="gordo-board__config__header__subtitle-content">
            {t("gordo.body.config.header.subtitle")}
          </div>
        </div>
      </div>
    );
  };

  const GordoConfigBody = (props) => {
    const { maxNumbers, onChangeMaxNumbers } = props;

    const onChangeMaxNumbersHandler = useCallback(
      (evt, value) => onChangeMaxNumbers(evt, value),
      [onChangeMaxNumbers]
    );

    return (
      <div className="gordo-board__config__body">
        <NumberCombinationContainer
          maxNumbers={maxNumbers}
          onChangeMaxNumbers={(evt, value) =>
            onChangeMaxNumbersHandler(evt, value)
          }
        />
      </div>
    );
  };

  const maxNumbers = useMemo(() => {
    return !isEmpty(config) ? config.maxNumbers : 0;
  }, [config]);

  const onChangeMaxNumbersHandler = useCallback(
    (evt, value) => {
      const modifiedConfig = { ...config, maxNumbers: value, maxSpecials: 1 };
      onChangeConfig(evt, modifiedConfig);
      onRandomNumbers(evt, modifiedConfig);
    },
    [config, onChangeConfig, onRandomNumbers]
  );

  return (
    <div className="gordo-board__config">
      <GordoConfigHeader />
      <GordoConfigBody
        maxNumbers={maxNumbers}
        onChangeMaxNumbers={(evt, value) =>
          onChangeMaxNumbersHandler(evt, value)
        }
      />
    </div>
  );
}

export default GordoMultipleContainer;
