import FooterPage from "../../../components/footer";

function RafflesFooter() {
  return (
    <div className="raffles-footer">
      <FooterPage />
    </div>
  );
}

export default RafflesFooter;
