import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useUserForgotPass from "../../../hooks/international-api/user/useUserForgotPass";

import {
  setOpenLoginDialog,
  setOpenForgotPassDialog,
} from "../../../redux/reducers/uiReducer";

import { removeUser } from "../../../redux/reducers/userReducer";

import {
  Link,
  Stack,
  Dialog,
  Backdrop,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { UserForgotPassForm } from "../../form";
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from "@mui/icons-material/Close";
import { errorToast, successToast } from "../../toast";

import { logDebug, logError } from "../../../utils/logger";
import { equalsIgnoringCase, isEmptyObject } from "../../../utils";

function ForgotPassDialog(props) {
  const { isOpen, onClose } = props;

  const [dataForm, setDataForm] = useState(() => {
    return {
      email: "",
      "g-recaptcha-response": "",
    };
  }, []);

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isOpenLoginDialog, isOpenForgotPassDialog } = useSelector(
    (state) => state.ui
  );

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const {
    data: dataUserForgotPass,
    error: errorUserForgotPass,
    loading: loadingUserForgotPass,
    userForgotPass,
  } = useUserForgotPass();

  const disabled = useMemo(() => {
    return loadingUserForgotPass;
  }, [loadingUserForgotPass]);

  const onCloseHandler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("ForgotPassDialog", "onCloseHandler", {
          evt: evt,
        });
        onClose && onClose(evt);
      }
    },
    [disabled, onClose]
  );

  const asyncScriptOnLoadHandler = useCallback(
    (data) => {
      if (!disabled) {
        logDebug("ForgotPassDialog", "asyncScriptOnLoadHandler", {
          data: data,
        });
        setLoaded(data.loaded);
      }
    },
    [disabled]
  );

  const DialogHeader = (props) => {
    return (
      <>
        <DialogTitle>
          <Typography
            component="span"
            sx={{
              width: "100%",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {t("forgot_password.header.title")}
          </Typography>
        </DialogTitle>
        <IconButton
          title={"button.close"}
          aria-label={t("button.close")}
          onClick={(evt) => onCloseHandler(evt)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };

  const DialogBody = (props) => {
    const ForgotPassText = (props) => {
      return (
        <Typography variant="body1">
          {t("forgot_password.body.subtitle")}
        </Typography>
      );
    };

    const onValidHandler = useCallback(async (data, evt) => {
      if (!disabled) {
        logDebug("ForgotPassDialog.DialogBody", "onValidHandler", {
          data: data,
          evt: evt,
        });
        setDataForm({ ...data });
        userForgotPass({
          header: {},
          body: {
            email: data.email,
            "g-recaptcha-response": data["g-recaptcha-response"],
          },
        });
      }
    }, []);

    const onInvalidHandler = useCallback(async (errors, evt) => {
      if (!disabled) {
        logDebug("ForgotPassDialog.DialogBody", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        errorToast(
          "ForgotPassDialog.DialogBody - onInvalidHandler",
          "error_form",
          t("errors.error_form", t("errors.default"))
        );
      }
    }, []);

    const changeRecaptchaHandler = useCallback((token) => {
      try {
        if (!disabled) {
          logDebug("ForgotPassDialog.DialogBody", "changeRecaptchaHandler", {
            "g-recaptcha-response": token,
          });
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": token,
          });
        }
      } catch (error) {
        logError("ForgotPassDialog.DialogBody", "changeRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "ForgotPassDialog.DialogBody - changeRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    const resetRecaptchaHandler = useCallback((evt) => {
      try {
        if (!disabled) {
          logDebug("ForgotPassDialog.DialogBody", "resetRecaptchaHandler", {
            evt: evt,
          });
          recaptchaRef.current.reset();
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": "",
          });
        }
      } catch (error) {
        logError("ForgotPassDialog.DialogBody", "resetRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "ForgotPassDialog.DialogBody - resetRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    return (
      <DialogContent dividers>
        <Stack spacing={2}>
          <ForgotPassText />
          <UserForgotPassForm
            loading={loadingUserForgotPass}
            disabled={disabled}
            dataForm={dataForm}
            recaptcha={{
              loaded: loaded,
              ref: recaptchaRef.current,
              resetRecaptcha: resetRecaptchaHandler,
              changeRecaptcha: changeRecaptchaHandler,
            }}
            onValid={(data, evt) => onValidHandler(data, evt)}
            onInvalid={(errors, evt) => onInvalidHandler(errors, evt)}
          />
        </Stack>
      </DialogContent>
    );
  };

  const DialogFooter = (props) => {
    const GoToLoginText = (props) => {
      const onClickHandler = useCallback((evt) => {
        if (!disabled) {
          logDebug(
            "ForgotPassDialog.DialogFooter.GoToLoginText",
            "onClickHandler",
            {
              evt: evt,
            }
          );
          dispatch(setOpenLoginDialog(!isOpenLoginDialog));
          dispatch(setOpenForgotPassDialog(!isOpenForgotPassDialog));
        }
      }, []);

      return (
        <Typography variant="body1">
          <Trans
            i18nKey="register.body.form.log_in_msg"
            components={{
              link1: (
                <Link
                  type="button"
                  variant="body1"
                  component="button"
                  title={t("login.header.title")}
                  onClick={(evt) => onClickHandler(evt)}
                />
              ),
            }}
          ></Trans>
        </Typography>
      );
    };

    return (
      <DialogContent dividers>
        <GoToLoginText />
      </DialogContent>
    );
  };

  useEffect(() => {
    if (!loadingUserForgotPass) {
      if (!isEmptyObject(dataUserForgotPass)) {
        logDebug("ForgotPassDialog", "userForgotPass", {
          data: dataUserForgotPass,
        });
        successToast(
          "ForgotPassDialog - userForgotPass",
          "message.recovery_mail",
          t("message.recovery_mail")
        );
      } else if (!isEmptyObject(errorUserForgotPass)) {
        if (equalsIgnoringCase(errorUserForgotPass, "expired_session")) {
          dispatch(removeUser());
          navigate(getLocalePath("/"));
          logError("ForgotPassDialog", "expired_session", {
            error: "expired_session",
            msg: t(`errors.expired_session`, t("errors.default")),
          });
          errorToast(
            "ForgotPassDialog",
            "expired_session",
            t(`errors.expired_session`, t("errors.default"))
          );
        } else {
          logError("ForgotPassDialog", "userRegister", {
            error: errorUserForgotPass,
            msg: t(`errors.${errorUserForgotPass}`, t("errors.default")),
          });
          errorToast(
            "ForgotPassDialog",
            errorUserForgotPass,
            t(`errors.${errorUserForgotPass}`, t("errors.default"))
          );
        }
      }
    }
  }, [
    dataUserForgotPass,
    errorUserForgotPass,
    loadingUserForgotPass,
    dispatch,
    navigate,
    getLocalePath,
    t,
  ]);

  return (
    <>
      <Dialog open={isOpen} onClose={(evt) => onCloseHandler(evt)}>
        <DialogHeader />
        <DialogBody />
        <DialogFooter />
      </Dialog>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="recaptcha-container"
        size="invisible"
        badge="bottomright"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        asyncScriptOnLoad={(data) => asyncScriptOnLoadHandler(data)}
      />
      <Backdrop
        open={loadingUserForgotPass}
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" thickness={10} />
      </Backdrop>
    </>
  );
}

export default ForgotPassDialog;
