import FooterPage from "../../../components/footer";

function BlogPostFooter() {
  return (
    <div className="blog-post-footer">
      <FooterPage />
    </div>
  );
}

export default BlogPostFooter;
