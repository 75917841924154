import { useCallback, useMemo } from "react";

import moment from "moment";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";
import CountdonwnComponent from "../../../../components/countdown-component";

import {
  isEmpty,
  nFormatter,
  DATE_FORMAT,
  isEmptyList,
  isEmptyObject,
} from "../../../../utils";
import { GAME_OID } from "../../../../utils/constants";

function LotterySlide(props) {
  const { item, draws } = props;

  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const closeDate = useMemo(() => {
    if (!isEmpty(item.close_date)) {
      return moment(item.close_date).format(DATE_FORMAT.US_LONG_FORMAT);
    }
    return "";
  }, [item.close_date]);

  const disabled = useMemo(() => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const expired = moment(now).isAfter(moment(closeDate));
    return !item.is_enabled || expired;
  }, [item.is_enabled, closeDate]);

  const getLotteryPath = useCallback(
    (game_oid) => {
      switch (game_oid) {
        case GAME_OID.EUROMILLIONS:
          return getLocalePath(`${t("routes.public.lotteries.euromillions")}`);
        case GAME_OID.PRIMITIVA:
          return getLocalePath(`${t("routes.public.lotteries.primitiva")}`);
        case GAME_OID.BONOLOTO:
          return getLocalePath(`${t("routes.public.lotteries.bonoloto")}`);
        case GAME_OID.EL_GORDO:
          return getLocalePath(`${t("routes.public.lotteries.gordo")}`);
        //case GAME_OID.QUINIELA:
        //  return getLocalePath(`${t("routes.public.lotteries.quiniela")}`);
        case GAME_OID.POWERBALL:
          return getLocalePath(`${t("routes.public.lotteries.powerball")}`);
        case GAME_OID.MEGAMILLIONS:
          return getLocalePath(`${t("routes.public.lotteries.megamillions")}`);
        case GAME_OID.SUPERENALOTTO:
          return getLocalePath(`${t("routes.public.lotteries.superenalotto")}`);
        case GAME_OID.EURODREAMS:
          return getLocalePath(`${t("routes.public.lotteries.eurodreams")}`);
        default:
          return "";
      }
    },
    [getLocalePath, t]
  );

  const linkMemo = useMemo(() => {
    return disabled ? "" : getLotteryPath(item.game_oid);
  }, [disabled, item.game_oid, getLotteryPath]);

  const LotteryLogo = (props) => {
    const { item } = props;

    const logoMemo = useMemo(() => {
      const emptyObject = isEmptyObject(item);
      const emptyLogo = isEmpty(item.logo);
      if (!emptyObject && !emptyLogo) {
        return item.logo;
      } else {
        return "../../../assets/img/lottery_default.png";
      }
    }, [item]);

    return (
      <div className="lottery-slide__logo">
        <div className="lottery-slide__logo__container">
          <div className="lottery-slide__logo__content">
            <img
              src={logoMemo}
              alt={item.title}
              title={item.title}
              loading="eager"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    );
  };

  const PotText = (props) => {
    const { item } = props;
    const { amount, symbol } = nFormatter(parseFloat(item.pot), 2, i18n);

    return (
      <div className="lottery-slide__pot">
        <div className="lottery-slide__pot__container">
          <div className="lottery-slide__pot__content">
            {!isEmpty(item.pot) && parseFloat(item.pot) > 0 ? (
              <>
                <div className="currency">€</div>
                <div className="amount">{amount}</div>
                <div className="text">{symbol}</div>
              </>
            ) : (
              <span className="waiting">{t("message.waiting_pot")}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const Playbutton = (props) => {
    return (
      <div className="lottery-slide__button">
        <div className="lottery-slide__button__container">
          <div className="lottery-slide__button__content">
            <CountdonwnComponent className="countdown" date={closeDate} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Link
      className={classNames("lottery-slide", {
        [`${item.class}-slide`]: item.class,
        disabled: disabled,
      })}
      title={item.title}
      to={linkMemo}
      state={{ draws: !isEmptyList(draws) ? [...draws] : [] }}
    >
      <div className="lottery-slide__container">
        <div className="lottery-slide__content">
          <div className="lottery-slide__header">
            <div className="lottery-slide__header__container">
              <div className="lottery-slide__header__content">
                <LotteryLogo item={item} />
              </div>
            </div>
          </div>
          <div className="lottery-slide__body">
            <div className="lottery-slide__body__container">
              <div className="lottery-slide__body__content">
                <PotText item={item} />
              </div>
            </div>
          </div>
          <div className="lottery-slide__footer">
            <div className="lottery-slide__footer__container">
              <div className="lottery-slide__footer__content">
                <Playbutton item={item} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default LotterySlide;
