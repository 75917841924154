import { useCallback, useMemo } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { yupResolver } from "@hookform/resolvers/yup";
import { getUserForgotPassSchema } from "../../../schemas";

import { Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

import { logDebug } from "../../../utils/logger";

function UserForgotPassForm(props) {
  const { loading, disabled, dataForm, recaptcha, onValid, onInvalid } = props;

  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      email: "",
      "g-recaptcha-response": "",
    };
  }, []);

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    values: dataForm,
    defaultValues: defaultValues,
    resolver: yupResolver(getUserForgotPassSchema(t)),
  });
  const { resetRecaptcha, changeRecaptcha } = recaptcha;

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("ForgotPassForm", "onValidHandler", { data: data, evt: evt });
        onValid(data, evt);
      }
    },
    [disabled, onValid]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("ForgotPassForm", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        onInvalid(errors, evt);
      }
    },
    [disabled, onInvalid]
  );

  return (
    <>
      <form
        id={"forgot-password-user-form"}
        noValidate
        onSubmit={handleSubmit(onValidHandler, onInvalidHandler)}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              name="email"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("forgot_password.body.form.email")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "email",
                    form: {
                      autocomplete: "email",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Controller
            name="g-recaptcha-response"
            control={control}
            onChange={(token) => changeRecaptcha(token)}
            onExpired={() => resetRecaptcha()}
            onError={() => resetRecaptcha()}
            render={({ field }) => {
              <input {...field} hidden />;
            }}
          />
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoadingButton
              loading={loading}
              disabled={disabled}
              title={t("forgot_password.body.form.forgot_pass")}
              size="large"
              type="submit"
              margin="none"
              color="primary"
              variant="contained"
            >
              {t("forgot_password.body.form.forgot_pass")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <DevTool control={control} />
    </>
  );
}

export default UserForgotPassForm;
