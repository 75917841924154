import { useMemo } from "react";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import RaffleSlide from "./raffle-slide";
import Loading from "../../../components/loading";
import RaffleCard from "../../../components/raffle-card";

import { isEmptyList } from "../../../utils";

function BodyRaffleSection(props) {
  const { loading, raffles } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const isUpperMd = useMediaQuery(theme.breakpoints.up("md"));

  const rafflesMemo = useMemo(() => {
    return !isEmptyList(raffles) ? raffles : [];
  }, [raffles]);

  const TitleComponent = (props) => {
    return (
      <div className="raffle-title__container">
        <div className="raffle-title__content">
          <h2>{t("landing.body.section.raffle.title")}</h2>
        </div>
      </div>
    );
  };

  const CardsComponent = (props) => {
    return (
      <div className="raffle-cards__container">
        <div className="raffle-cards__content">
          <>
            {rafflesMemo.map((item, idx) => {
              if (isUpperMd) {
                return <RaffleSlide key={idx} item={item} />;
              } else {
                return <RaffleCard key={idx} item={item} />;
              }
            })}
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="body-raffle-section__container">
      <div className="body-raffle-section__content">
        <TitleComponent />
        {loading ? <Loading /> : <CardsComponent />}
      </div>
    </div>
  );
}

export default BodyRaffleSection;
