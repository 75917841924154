import { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetPageByPath from "../../hooks/wp-json-api/useGetPageByPath";
import useDrawsStock from "../../hooks/international-api/draws/useDrawsStock";

import {
  clearBets,
  clearDraw,
  clearConfig,
} from "../../redux/reducers/primitivaReducer";

import SEO from "../../components/seo";
import PrimitivaBody from "./primitiva-body";
import PrimitivaHeader from "./primitiva-header";
import PrimitivaFooter from "./primitiva-footer";
import { errorToast } from "../../components/toast";

import {
  isEmpty,
  isEmptyList,
  DATE_FORMAT,
  isEmptyObject,
  equalsIgnoringCase,
} from "../../utils";
import { GAME_OID } from "../../utils/constants";
import { logDebug, logError, logWarn } from "../../utils/logger";
import { TYPE_REQUEST } from "../../hooks/international-api/utils";

function PrimitivaPage() {
  const [page, setPage] = useState(null);
  const [draws, setDraws] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const {
    data: dataDrawsStock,
    error: errorDrawsStock,
    loading: loadingDrawsStock,
    drawsStock,
  } = useDrawsStock();

  const {
    data: dataPageByPath,
    error: errorPageByPath,
    loading: loadingPageByPath,
    getPageByPath,
  } = useGetPageByPath();

  const title = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.title)
      ? metadata.yoast_head_json.title
      : t("primitiva.title");
  }, [metadata, t]);

  const description = useMemo(() => {
    return !isEmptyObject(metadata) &&
      !isEmptyObject(metadata.yoast_head_json) &&
      !isEmpty(metadata.yoast_head_json.description)
      ? metadata.yoast_head_json.description
      : t("primitiva.description");
  }, [metadata, t]);

  const url = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${pathname}`;
  }, [pathname]);

  const content = useMemo(() => {
    if (
      !loadingPageByPath &&
      !isEmptyObject(page) &&
      !isEmptyObject(page.content) &&
      !isEmpty(page.content.rendered)
    ) {
      return page.content.rendered;
    }
    return "";
  }, [loadingPageByPath, page]);

  const isClosedDate = useCallback((item) => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    return moment(now).isAfter(moment(closeDate));
  }, []);

  const pageRequest = useMemo(() => {
    return { path: pathname };
  }, [pathname]);

  const drawRequest = useMemo(() => {
    return {
      header: {
        type_request: !isEmptyObject(user)
          ? TYPE_REQUEST.USERNAME
          : TYPE_REQUEST.ANONIM,
        email: !isEmptyObject(user)
          ? user.email
          : process.env.REACT_APP_API_USER,
        token: !isEmptyObject(user)
          ? user.session_token
          : process.env.REACT_APP_API_PASS,
      },
      body: {},
    };
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearBets());
    dispatch(clearDraw());
    dispatch(clearConfig());
    logDebug("PrimitivaPage", "Init - cleanBeforeLoad", {
      msg: t("message.default"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmptyObject(state) && !isEmptyList(state.draws)) {
      setDraws([...state.draws]);
      logDebug("PrimitivaPage", "Init - setDraws", {
        draws: [...state.draws],
      });
    } else {
      drawsStock(drawRequest);
      logDebug("PrimitivaPage", "Init - drawsStock", {
        request: { ...drawRequest },
      });
    }
    getPageByPath(pageRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingDrawsStock) {
      if (errorDrawsStock) {
        logError("PrimitivaPage", "drawsStock", {
          key: errorDrawsStock,
          msg: t(`errors.${errorDrawsStock}`, t("errors.default")),
        });
        errorToast(
          "PrimitivaPage - drawsStock",
          errorDrawsStock,
          t(`errors.${errorDrawsStock}`, t("errors.default"))
        );
      } else if (!isEmptyObject(dataDrawsStock)) {
        if (!isEmptyObject(dataDrawsStock)) {
          const lotteries = Array.from(dataDrawsStock.lotteries).filter(
            (item) =>
              item.game_oid === GAME_OID.PRIMITIVA &&
              item.is_enabled &&
              !isClosedDate(item)
          );
          if (!isEmptyList(lotteries)) {
            lotteries.sort(
              (a, b) => new Date(a.close_date) - new Date(b.close_date)
            );
            setDraws(lotteries);
            logDebug("PrimitivaPage", "drawsStock", {
              lotteries: [...lotteries],
            });
          }
        } else if (!isEmpty(errorDrawsStock)) {
          logError("PrimitivaPage", "drawsStock", {
            error: errorDrawsStock,
            msg: t(`errors.${errorDrawsStock}`, t("errors.default")),
          });
          errorToast(
            "PrimitivaPage - drawsStock",
            errorDrawsStock,
            t(`errors.${errorDrawsStock}`, t("errors.default"))
          );
        }
      }
    }
  }, [loadingDrawsStock, dataDrawsStock, errorDrawsStock, isClosedDate, t]);

  useEffect(() => {
    if (!loadingPageByPath) {
      if (errorPageByPath) {
        if (equalsIgnoringCase("rest_post_invalid_id", errorPageByPath)) {
          logWarn("PrimitivaPage", "useGetPageByPath", {
            code: errorPageByPath,
            msg: t(`errors.${errorPageByPath}`),
            id: pathname,
          });
        } else {
          errorToast(
            "PrimitivaPage - useGetPageByPath",
            errorPageByPath,
            t(`errors.${errorPageByPath}`, t("errors.default"))
          );
        }
      } else if (dataPageByPath) {
        logDebug("PrimitivaPage", "useGetPageByPath", {
          ...dataPageByPath,
        });
        setPage(dataPageByPath);
        setMetadata(dataPageByPath.yoast_head_json);
      }
    }
  }, [loadingPageByPath, dataPageByPath, errorPageByPath, pathname, t]);

  return (
    <>
      <SEO
        url={url}
        metadata={metadata}
        pageTitle={title}
        pageDesc={description}
      />
      <div className="primitiva">
        <div className="primitiva__container">
          <PrimitivaHeader />
          <PrimitivaBody
            loadingDraws={loadingDrawsStock}
            draws={draws}
            loadingContent={loadingPageByPath}
            content={content}
          />
          <PrimitivaFooter />
        </div>
      </div>
    </>
  );
}

export default PrimitivaPage;
