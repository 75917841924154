import { useTranslation } from "react-i18next";

import useGetLocalePath from "../../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";
import logoImage from "../../../assets/img/logo.webp";

function HeaderLogo() {
  const { t } = useTranslation();

  const { getLocalePath } = useGetLocalePath();

  return (
    <div className="header-logo">
      <div className="header-logo__container">
        <div className="header-logo__content">
          <Link className="link" to={getLocalePath("/")}>
            <img
              className="header-logo__img"
              src={logoImage}
              alt={t("logo.alt")}
              title={t("logo.title")}
              height="auto"
              width="auto"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderLogo;
