import FooterPage from "../../../components/footer";

function SyndicateFooter() {
  return (
    <div className="raffle-footer">
      <FooterPage />
    </div>
  );
}

export default SyndicateFooter;
