import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";
import LegalAgeLogo from "../../assets/img/legal_age.svg";

function FooterPage() {
  const { t } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  return (
    <div className="footer__container">
      <div className="footer__content">
        <div className="footer__item legal-age">
          <div className="footer__item__container">
            <div className="footer__item__content">
              <img
                className="legal_age_img"
                src={LegalAgeLogo}
                alt={t("footer.legal_age.alt")}
                title={t("footer.legal_age.title")}
                height="auto"
                width="auto"
                loading="lazy"
              />
              <p className="legal_age_msg">{t("footer.legal_age.msg")}</p>
            </div>
          </div>
        </div>
        <div className="footer__item links">
          <div className="footer__item__container">
            <div className="footer__item__content">
              <Link
                className="link"
                title={t("footer.links.legal")}
                to={getLocalePath(t("routes.public.legal.index"))}
              >
                {t("footer.links.legal")}
              </Link>
              <span className="separator">·</span>
              <Link
                className="link"
                title={t("footer.links.privacy")}
                to={getLocalePath(t("routes.public.privacy.index"))}
              >
                {t("footer.links.privacy")}
              </Link>
              <span className="separator">·</span>
              <Link
                className="link"
                title={t("footer.links.cookies")}
                to={getLocalePath(t("routes.public.cookies.index"))}
              >
                {t("footer.links.cookies")}
              </Link>
              <span className="separator">·</span>
              <Link
                className="link"
                title={t("footer.links.help")}
                to={getLocalePath(t("routes.public.help.index"))}
              >
                {t("footer.links.help")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterPage;
