import { useTranslation } from "react-i18next";

function BlogLotteryFilter(props) {
  const { t } = useTranslation();

  return (
    <div className="blog-filter__lottery__container">
      <div className="blog-filter__lottery__content">
        <div className="blog-filter__lottery__title">
          {t("blog.body.next_draws")}
        </div>
        <div className="blog-filter__lottery__list"></div>
      </div>
    </div>
  );
}

export default BlogLotteryFilter;
