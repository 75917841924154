import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useGetPostByParams from "../../../hooks/wp-json-api/useGetPostByParams.js";

import { logDebug, logError, logWarn } from "../../../utils/logger.js";

import BlogList from "../../../components/blog-list";
import BlogFilter from "../../../components/blog-filter";
import { errorToast, warnToast } from "../../../components/toast/index.js";
import {
  equalsIgnoringCase,
  isEmpty,
  isEmptyList,
} from "../../../utils/index.js";

function BlogBody(props) {
  const {
    disabled,
    loadingParentCategory,
    parentCategory,
    loadingCategories,
    categories,
  } = props;

  const { t } = useTranslation();

  const [posts, setPosts] = useState([]);
  const [showTableLoading, setShowTableLoading] = useState(true);
  const [initSearchPosts, setInitSearchPosts] = useState(false);
  const [paramsPosts, setParamsPosts] = useState({
    filter: { category_id: "", search: "" },
    pagination: { index: 1, pageSize: 5 },
  });

  const {
    loading: loadingPosts,
    data: dataPosts,
    error: errorPosts,
    getPostByParams,
  } = useGetPostByParams();

  useEffect(() => {
    if (
      initSearchPosts &&
      !isEmpty(paramsPosts) &&
      !isEmpty(paramsPosts.filter) &&
      (!isEmpty(paramsPosts.filter.category_id) ||
        !isEmpty(paramsPosts.filter.search))
    ) {
      getPostByParams(paramsPosts);
    }
  }, [initSearchPosts, paramsPosts, getPostByParams]);

  useEffect(() => {
    if (!loadingParentCategory) {
      if (!isEmpty(parentCategory) && !isEmpty(parentCategory.id)) {
        const params = {
          filter: {
            ...paramsPosts.filter,
            category_id: parentCategory.id,
          },
          pagination: { ...paramsPosts.pagination, index: 1 },
        };
        setPosts([]);
        setParamsPosts(params);
        setShowTableLoading(true);
        setInitSearchPosts(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingParentCategory, parentCategory]);
  useEffect(() => {
    if (!loadingPosts) {
      if (errorPosts) {
        if (equalsIgnoringCase(errorPosts, "rest_post_invalid_page_number")) {
          logWarn("BlogBody", "getPostByParams", {
            error: errorPosts,
            msg: t("errors.no_more_posts", t("errors.default")),
          });
          warnToast(
            "BlogBody - getPostByParams",
            errorPosts,
            t("errors.no_more_posts", t("errors.default"))
          );
        } else {
          logError("BlogBody", "getPostByParams", {
            error: errorPosts,
            msg: t(`errors.${errorPosts}`, t("errors.default")),
          });
          errorToast(
            "BlogBody - getPostByParams",
            errorPosts,
            t(`errors.${errorPosts}`, t("errors.default"))
          );
        }
      } else if (dataPosts) {
        setPosts(!isEmptyList(dataPosts) ? [...posts, ...dataPosts] : []);
      }
      setShowTableLoading(false);
      setInitSearchPosts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosts, dataPosts, errorPosts, t]);

  const onChangePageHandler = useCallback((evt, value) => {
    const modParams = {
      filter: { ...value.filter },
      pagination: { ...value.pagination },
    };
    setParamsPosts(modParams);
    setShowTableLoading(true);
    setInitSearchPosts(true);
  }, []);

  const onChangeParamsHandler = useCallback((evt, value) => {
    const modParams = {
      filter: { ...value.filter },
      pagination: { ...value.pagination, index: 1 },
    };
    setParamsPosts(modParams);

    if (["blur", "clear"].includes(evt)) {
      setPosts([]);
      setShowTableLoading(true);
      setInitSearchPosts(true);
    }

    logDebug("BlogBody", "onChangeParamsHandler", {
      evt: evt,
      params: modParams,
    });
  }, []);

  return (
    <div className="blog-body">
      <div className="blog-body__container">
        <h1 className="blog-body__title">{t("blog.body.title")}</h1>
        <div className="blog-body__content">
          <div className="blog-body__grid-container">
            <BlogList
              loading={showTableLoading}
              disabled={disabled}
              loadingPosts={loadingPosts}
              posts={posts}
              loadingCategories={loadingCategories}
              categories={categories}
              loadingParentCategory={loadingParentCategory}
              parentCategory={parentCategory}
              loadingSelectedCategory={loadingParentCategory}
              selectedCategory={parentCategory}
              params={paramsPosts}
              onChangeParams={(evt, value) => onChangePageHandler(evt, value)}
            />
            <BlogFilter
              disabled={disabled}
              showCategoriesFilter
              loadingCategories={loadingCategories}
              categories={categories}
              loadingParentCategory={loadingParentCategory}
              parentCategory={parentCategory}
              showSearchFilter
              params={paramsPosts}
              onChangeParams={(evt, value) => onChangeParamsHandler(evt, value)}
              showLastPosts
              showLottery
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogBody;
