import i18n from "../../i18n";

import { createTheme } from "@mui/material";
import { enUS, esES } from "@mui/x-date-pickers/locales";

import { equalsIgnoringCase } from "../../utils";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fc3c3e",
        error: "#f04438",
        warning: "#f79009",
        info: "#2e90fa",
        success: "#12b76a",
        contrastText: "#ffffff",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
  },
  equalsIgnoringCase(i18n.resolvedLanguage, "es") ? esES : enUS
);

export default theme;
