import FooterPage from "../../../components/footer";

function CartFooter() {
  return (
    <div className="cart-footer">
      <FooterPage />
    </div>
  );
}

export default CartFooter;
