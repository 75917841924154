import FooterPage from "../../../components/footer";

function EuromillionsFooter() {
  return (
    <div className="euromillions-footer">
      <FooterPage />
    </div>
  );
}

export default EuromillionsFooter;
