import FooterPage from "../../../components/footer";

function PrimitivaFooter() {
  return (
    <div className="primitiva-footer">
      <FooterPage />
    </div>
  );
}

export default PrimitivaFooter;
