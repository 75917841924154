import { useEffect, useMemo } from "react";

import store from "../redux/store";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../hooks/useGetLocalePath";
import { useLocation, useNavigate } from "react-router-dom";

import { setGeoAllowed } from "../redux/reducers/uiReducer";

import { Outlet } from "react-router-dom";

function GeoInfoLayout() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { getLocalePath } = useGetLocalePath();

  const dispatch = useDispatch();
  const { isGeoAllowed } = useSelector((state) => state.ui);

  const route = useMemo(() => {
    return getLocalePath(
      isGeoAllowed ? pathname : t("routes.public.geoblock.index")
    );
  }, [isGeoAllowed, pathname, getLocalePath, t]);

  useEffect(
    () => navigate(route, { replace: true }),
    [route, dispatch, navigate, getLocalePath, t]
  );

  return <Outlet />;
}

// FALSE (0) --> NO PERMITIDO
// TRUE (1) --> PERMITIDO
export async function loader({ request, params }) {
  const { ui } = store.getState();
  const { isGeoAllowed } = ui;
  if (isGeoAllowed === undefined) {
    if (!["LOCAL", "DEVELOPMENT"].includes(process.env.REACT_APP_ENV)) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_ST1_URL}/geo`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      store.dispatch(setGeoAllowed(Boolean(data)));
    } else {
      /** INIT - MOCK */
      const promise = new Promise((res, rej) => setTimeout(() => res(1), 500));
      const data = await promise;
      store.dispatch(setGeoAllowed(Boolean(data)));
      /** END - MOCK */
    }
  }
  return null;
}

export default GeoInfoLayout;
