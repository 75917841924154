import { useCallback, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import updateLocale from "dayjs/plugin/updateLocale";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DevTool } from "@hookform/devtools";

import { yupResolver } from "@hookform/resolvers/yup";
import { getUserUpdateSchema } from "../../../schemas";

import {
  Backdrop,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import SaveIcon from "@mui/icons-material/Save";

import { logDebug } from "../../../utils/logger";

function UserUpdateForm(props) {
  const { loading, disabled, dataForm, onValid, onInvalid } = props;

  const { t, i18n } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      name: "",
      surname: "",
      dni: "",
      birthday: "",
      phone: "",
    };
  }, []);

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    criteriaMode: "all",
    values: dataForm,
    defaultValues: defaultValues,
    resolver: yupResolver(getUserUpdateSchema(t)),
  });

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("UserUpdateForm", "onValidHandler", { data: data, evt: evt });
        onValid && onValid(data, evt);
      }
    },
    [disabled, onValid]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("UserUpdateForm", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        onInvalid && onInvalid(errors, evt);
      }
    },
    [disabled, onInvalid]
  );

  useEffect(() => {
    dayjs.extend(updateLocale);
    dayjs.updateLocale(i18n.resolvedLanguage, { weekStart: 1 });
  }, [i18n.resolvedLanguage]);

  return (
    <>
      <form
        id={"user-update-form"}
        noValidate
        onSubmit={handleSubmit(onValidHandler, onInvalidHandler)}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography fontSize={"1.5rem"} fontWeight={600}>
              {t("update_user.body.form.title")}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography fontWeight={600}>
              {t("update_user.body.form.personal_title")}
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Controller
              name="name"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("update_user.body.form.name")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "name",
                    form: {
                      autocomplete: "name",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={8}>
            <Controller
              name="surname"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("update_user.body.form.surname")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "family-name",
                    form: {
                      autocomplete: "family-name",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <Controller
              name="dni"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("update_user.body.form.dni")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <Controller
              name="birthday"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.resolvedLanguage}
                >
                  <DatePicker
                    {...field}
                    format={"DD/MM/YYYY"}
                    value={dayjs(field.value)}
                    slotProps={{
                      textField: {
                        label: t("update_user.body.form.birthday"),
                        variant: "outlined",
                        margin: "none",
                        type: "text",
                        fullWidth: true,
                        required: true,
                        error: !!fieldState?.error,
                        helperText: fieldState?.error?.message,
                        InputProps: {
                          autoComplete: "bday",
                          form: {
                            autocomplete: "bday",
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <Controller
              name="phone"
              control={control}
              disabled={disabled}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("update_user.body.form.phone")}
                  variant="outlined"
                  margin="none"
                  type="text"
                  fullWidth
                  required
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    autoComplete: "tel",
                    form: {
                      autocomplete: "tel",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <LoadingButton
              loading={loading}
              disabled={disabled}
              type="submit"
              title={t("button.save")}
              aria-label={t("button.save")}
              variant="contained"
              color="primary"
              margin="none"
              size="large"
              startIcon={<SaveIcon />}
            >
              {t("button.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <Backdrop
        open={loading}
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" thickness={10} />
      </Backdrop>
      <DevTool control={control} />
    </>
  );
}

export default UserUpdateForm;
