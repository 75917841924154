import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../../hooks/useGetLocalePath";
import useUserUpdate from "../../../../hooks/international-api/user/useUserUpdate";

import { removeUser, setUser } from "../../../../redux/reducers/userReducer";

import { errorToast, successToast } from "../../../../components/toast";
import UserUpdateForm from "../../../../components/form/user-update-form";

import { logDebug, logError } from "../../../../utils/logger";
import { equalsIgnoringCase, isEmptyObject } from "../../../../utils";
import { TYPE_REQUEST } from "../../../../hooks/international-api/utils";

function MyAccountPersonalTab() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const { user } = useSelector((state) => state.user);
  const {
    user_name,
    user_surname,
    dni,
    birthday,
    phone,
    email,
    session_token,
  } = user;

  const [dataForm, setDataForm] = useState({
    name: user_name,
    surname: user_surname,
    dni: dni,
    birthday: birthday,
    phone: phone,
  });

  const {
    data: dataUserUpdate,
    error: errorUserUpdate,
    loading: loadingUserUpdate,
    userUpdate,
  } = useUserUpdate();

  const loading = useMemo(() => {
    return [loadingUserUpdate].some((i) => i);
  }, [loadingUserUpdate]);

  const disabled = useMemo(() => {
    return loading;
  }, [loading]);

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("MyAccountPersonalTab", "onValidHandler", {
          data: data,
          evt: evt,
        });
        userUpdate({
          header: {
            email: email,
            token: session_token,
            type_request: TYPE_REQUEST.USERNAME,
          },
          body: {
            user_name: data.name,
            user_surname: data.surname,
            dni: data.dni,
            birthday: dayjs(data.birthday).format("YYYY-MM-DD"),
            phone: data.phone,
          },
        });
      }
    },
    [disabled, email, session_token, userUpdate]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("MyAccountPersonalTab", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        errorToast(
          "MyAccountPersonalTab - onInvalidHandler",
          "error_form",
          t("errors.error_form", t("errors.default"))
        );
      }
    },
    [disabled, t]
  );

  useEffect(() => {
    if (!loadingUserUpdate) {
      if (!isEmptyObject(dataUserUpdate)) {
        logDebug("MyAccountPersonalTab", "userUpdate", {
          data: { ...dataUserUpdate },
        });
        successToast(
          "MyAccountPersonalTab - userUpdate",
          "message.default",
          t("message.default")
        );
        setDataForm({
          name: dataUserUpdate.user_name,
          surname: dataUserUpdate.user_surname,
          dni: dataUserUpdate.dni,
          birthday: dataUserUpdate.birthday,
          phone: dataUserUpdate.phone,
        });
        dispatch(setUser(dataUserUpdate));
      } else if (!isEmptyObject(errorUserUpdate)) {
        if (equalsIgnoringCase(errorUserUpdate, "expired_session")) {
          dispatch(removeUser());
          navigate(getLocalePath("/"));
          logError("MyAccountPersonalTab", "expired_session", {
            error: "expired_session",
            msg: t(`errors.expired_session`, t("errors.default")),
          });
          errorToast(
            "MyAccountPersonalTab",
            "expired_session",
            t(`errors.expired_session`, t("errors.default"))
          );
        } else {
          logError("MyAccountPersonalTab", "userUpdate", {
            error: errorUserUpdate,
            msg: t(`errors.${errorUserUpdate}`, t("errors.default")),
          });
          errorToast(
            "MyAccountPersonalTab",
            errorUserUpdate,
            t(`errors.${errorUserUpdate}`, t("errors.default"))
          );
        }
      }
    }
  }, [
    dataUserUpdate,
    errorUserUpdate,
    loadingUserUpdate,
    t,
    dispatch,
    navigate,
    getLocalePath,
  ]);

  return (
    <div className="my-account__personal__container">
      <div className="my-account__personal__content">
        <UserUpdateForm
          loading={loading}
          disabled={disabled}
          dataForm={dataForm}
          onValid={(data, evt) => onValidHandler(data, evt)}
          onInvalid={(errors, evt) => onInvalidHandler(errors, evt)}
        />
      </div>
    </div>
  );
}

export default MyAccountPersonalTab;
