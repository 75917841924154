import FooterPage from "../../../components/footer";

function BlogFooter() {
  return (
    <div className="blog-footer">
      <FooterPage />
    </div>
  );
}

export default BlogFooter;
