import HeaderPage from "../../../components/header";

function LotteryHeader() {
  return (
    <div className="syndicates-header">
      <div className="syndicates-header__container">
        <div className="syndicates-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default LotteryHeader;
