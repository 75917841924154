import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  Stack,
  Button,
  Typography,
  CardContent,
  CardActions,
} from "@mui/material";
import SummaryListCart from "../summary-list-cart";
import SummaryTotalCart from "../summary-total-cart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { isEmptyList } from "../../../utils";

function SummaryCart(props) {
  const { items, onClickBuy } = props;

  const { t } = useTranslation();

  const disableBtn = useMemo(() => isEmptyList(items), [items]);

  const onClickBuyHandler = useCallback(
    (evt) => onClickBuy && onClickBuy(evt),
    [onClickBuy]
  );

  const SummaryTitleCart = (props) => {
    return (
      <Box>
        <Typography
          variant="body1"
          component="span"
          title={t("cart.body.summary.title")}
          aria-label={t("cart.body.summary.title")}
          sx={{
            width: "100%",
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {t("cart.body.summary.title")}
        </Typography>
      </Box>
    );
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <SummaryTitleCart />
          <SummaryListCart items={items} />
          <SummaryTotalCart items={items} />
        </Stack>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            disabled={disableBtn}
            title={t("button.buy")}
            aria-label={t("button.buy")}
            startIcon={
              <ShoppingCartIcon
                color={disableBtn ? "disabled" : "contrastText"}
              />
            }
            onClick={(evt) => onClickBuyHandler(evt)}
          >
            {t("button.buy")}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}

export default SummaryCart;
