import React, { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import SuperenalottoBetContainer from "./superenalotto-bet-container";
import SuperenalottoMultipleContainer from "./superenalotto-multiple-container";

import { equalsIgnoringCase, isEmpty } from "../../utils";
import { TABS } from "../../utils/constants";

function SuperenalottoBoard(props) {
  const { tab, onChangeTab, config, onChangeConfig, bets, onChangeBets } =
    props;

  const { t } = useTranslation();

  const isSingleTab = useMemo(
    () => equalsIgnoringCase(tab, TABS.SINGLE),
    [tab]
  );

  const isMultipleTab = useMemo(
    () => equalsIgnoringCase(tab, TABS.MULTIPLE),
    [tab]
  );

  const onClickSingleTabHandler = useCallback(
    (evt) => onChangeTab(evt, TABS.SINGLE),
    [onChangeTab]
  );

  const onClickMultipleTabHandler = useCallback(
    (evt) => onChangeTab(evt, TABS.MULTIPLE),
    [onChangeTab]
  );

  const validateBet = useCallback(
    (numbers, newConfig = null) => {
      const maxNumbers = !isEmpty(newConfig)
        ? newConfig.maxNumbers
        : config.maxNumbers;

      return maxNumbers > 0 ? maxNumbers === numbers.length : false;
    },
    [config]
  );

  const betChangeHandler = useCallback(
    (evt, data, index) => {
      const modifiedBet = {
        numbers: [...data.numbers],
        valid: validateBet(data.numbers),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            valid: false,
            disabled: idx === 0 ? !validateBet(modifiedBet.numbers) : true,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
    },
    [bets, validateBet, onChangeBets]
  );

  const betClearHandler = useCallback(
    (evt, data, index) => {
      const modifiedBet = {
        numbers: [],
        valid: validateBet([], []),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            valid: false,
            disabled: true,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
      onChangeConfig(evt, {
        type: equalsIgnoringCase(tab, TABS.SINGLE)
          ? TABS.SINGLE
          : TABS.MULTIPLE,
        num_draws: 1,
        minBets: equalsIgnoringCase(tab, TABS.SINGLE) ? 6 : 1,
        maxBets: equalsIgnoringCase(tab, TABS.SINGLE) ? 25 : 1,
        numbers: 90,
        maxNumbers: equalsIgnoringCase(tab, TABS.SINGLE) ? 6 : 0,
        superstar: false,
      });
    },
    [tab, bets, validateBet, onChangeBets, onChangeConfig]
  );

  const getRandomNumbers = useCallback((max, length) => {
    let count = 0;
    const result = [];
    const array = [...Array(max).keys()].map((item) => item + 1);
    while (count < length) {
      const random = array[Math.floor(Math.random() * array.length)];
      if (!result.includes(random)) {
        result.push(random);
        count++;
      }
    }
    return result;
  }, []);

  const betRandomHandler = useCallback(
    (evt, data, index) => {
      const randomNumbers = getRandomNumbers(config.numbers, config.maxNumbers);
      const modifiedBet = {
        numbers: randomNumbers,
        valid: validateBet(randomNumbers),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            valid: false,
            disabled: idx !== 0,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
    },
    [
      config.numbers,
      config.maxNumbers,
      bets,
      validateBet,
      getRandomNumbers,
      onChangeBets,
    ]
  );

  const betRandomNumbersHandler = useCallback(
    (evt, data, index) => {
      const randomNumbers = getRandomNumbers(data.numbers, data.maxNumbers);
      const modifiedBet = {
        numbers: randomNumbers,
        valid: validateBet(randomNumbers, data),
        disabled: false,
      };
      const emptyBets = [...Array(bets.length - (index + 1)).keys()].map(
        (bet, idx) => {
          return {
            numbers: [],
            valid: false,
            disabled: idx !== 0,
          };
        }
      );
      const modifiedBets = [...bets.slice(0, index), modifiedBet, ...emptyBets];
      onChangeBets(evt, modifiedBets);
      onChangeConfig(evt, data);
    },
    [bets, validateBet, getRandomNumbers, onChangeBets, onChangeConfig]
  );

  const configChangeHandler = useCallback(
    (evt, data) => onChangeConfig(evt, data),
    [onChangeConfig]
  );

  return (
    <div className="superenalotto-board__container">
      <div className="superenalotto-board__content">
        <div className="superenalotto-board__nav-tabs__container">
          <div
            className={classNames("superenalotto-board__nav-tab", {
              active: isSingleTab,
            })}
            onClick={(evt) => onClickSingleTabHandler(evt)}
          >
            {t("superenalotto.body.tabs.single")}
          </div>
          <div
            className={classNames("superenalotto-board__nav-tab", {
              active: isMultipleTab,
            })}
            onClick={(evt) => onClickMultipleTabHandler(evt)}
          >
            {t("superenalotto.body.tabs.multiple")}
          </div>
        </div>

        <div
          className={classNames("superenalotto-board__grid__container", {
            fadeIn: isSingleTab,
            hide: !isSingleTab,
          })}
        >
          <div className="superenalotto-board__grid__content single">
            {bets.map((bet, idx) => (
              <SuperenalottoBetContainer
                key={idx + 1}
                index={idx + 1}
                bet={bet}
                config={config}
                randomizable
                onClear={(evt, data) => betClearHandler(evt, data, idx)}
                onChange={(evt, data) => betChangeHandler(evt, data, idx)}
                onRandom={(evt, data) => betRandomHandler(evt, data, idx)}
              />
            ))}
          </div>
        </div>
        <div
          className={classNames("superenalotto-board__grid__container", {
            fadeIn: isMultipleTab,
            hide: !isMultipleTab,
          })}
        >
          <div className="superenalotto-board__grid__content multiple">
            {bets.map((bet, idx) => (
              <React.Fragment key={idx + 1}>
                <SuperenalottoBetContainer
                  index={idx + 1}
                  bet={bet}
                  config={config}
                  onClear={(evt, data) => betClearHandler(evt, data, idx)}
                  onChange={(evt, data) => betChangeHandler(evt, data, idx)}
                />
                <SuperenalottoMultipleContainer
                  config={config}
                  onChangeConfig={(evt, data) => configChangeHandler(evt, data)}
                  onRandomNumbers={(evt, data) =>
                    betRandomNumbersHandler(evt, data, idx)
                  }
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperenalottoBoard;
