import { thunk } from "redux-thunk";
import { combineReducers } from "redux";
import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";

import {
  uiReducer,
  euromillionsReducer,
  primitivaReducer,
  bonolotoReducer,
  gordoReducer,
  quinielaReducer,
  powerballReducer,
  megamillionsReducer,
  superenalottoReducer,
  eurodreamsReducer,
  userReducer,
  cartReducer,
} from "../reducers";

const combinedReducers = combineReducers({
  // State Storage
  ui: uiReducer,
  euromillions: euromillionsReducer,
  primitiva: primitivaReducer,
  bonoloto: bonolotoReducer,
  gordo: gordoReducer,
  quiniela: quinielaReducer,
  powerball: powerballReducer,
  megamillions: megamillionsReducer,
  superenalotto: superenalottoReducer,
  eurodreams: eurodreamsReducer,
  // Session Storage
  // Local Storage
  user: userReducer,
  cart: cartReducer,
});

const store = configureStore({
  reducer: combinedReducers,
  middleware: () => [thunk],
  devTools: true,
  trace: true,
});

export default store;
export const persistor = persistStore(store);
