export const TRANSLATIONS = {
  app: {
    name: "loteriasonline",
    version: "1.0",
  },

  button: {
    accept: "Accept",
    cancel: "Cancel",
    buy: "Buy",
    remove: "Remove",
    login: "Sign In",
    register: "Create Account",
    close: "Close",
    save: "Save",
    expand: "Expand",
    play: "Play now!",
    delete: "Delete",
    random: "Random",
    add_cart: "Add to cart",
    load_more: "Load more...",
    go_post: "Go to post",
    subscription: "Subscription",
    cart: "Go to Cart",
  },

  input: {
    search: {
      placeholder: "Search...",
    },
  },

  message: {
    default: "The operation was successful",
    yes: "Yes",
    no: "No",
    warning: "Warning",
    unsaved_changes: "There is unsaved changes, do you want to continue?",
    finalized_draw: "Finalized draw",
    waiting_pot: "Waiting pot",
    waiting_draw: "Waiting draw",
    empty_list: "There are no results with the selected search criteria",
    log_out: "Session was closed successfully",
    recovery_mail: "We send you an email to recover your account",
    success_login: "Wellcome to your account",
    success_add_cart: "Added to cart successfully",
  },

  errors: {
    default: "An error has occurred, please try again later",
    page_not_found: "Page not Found",
    no_more_posts: "No more posts...",
    rest_post_invalid_id: "Post ID not valid",
    error_form: "Please, you must to resolve the form problems",
    already_registered: "There is a registered user yet",
    expired_session: "Session has expired",
    server_error: "A server error has occurred, please try again later",
    password_mismatch: "Invalid password format",
    invalid_email: "Invalid email",
    invalid_password: "Invalid password",
  },

  validators: {
    notEmpty: "The field cannot be empty",
    email: "The field is not a valid email address",
    minLength: "The field length cannot be less than {{value}} chars",
    maxLength: "The field length cannot be greater than {{value}} chars",
    no_whitespaces: "The field cannot contain blank spaces",
    compareFields: "The field value does not match the previous one",
    lessThan: "The field cannot be less than {{value}}",
    moreThan: "The field cannot be greater than {{value}}",
    checkTrue: "The field has to be checked",
    password_mismatch:
      "The field must contain at least one digit and one letter. Special characters: !@#$%_-",
  },

  routes: {
    public: {
      geoblock: {
        index: "geoblock",
      },
      landing: {
        index: "Home",
      },
      lotteries: {
        index: "lotteries",
        euromillions: "euromillones",
        primitiva: "la-primitiva",
        bonoloto: "bonoloto",
        gordo: "el-gordo",
        quiniela: "quiniela",
        powerball: "powerball",
        megamillions: "megamillions",
        superenalotto: "superenalotto",
        eurodreams: "eurodreams",
      },
      raffles: {
        index: "raffles",
        4: "national-thursday",
        6: "national-saturday",
        8: "national-extra",
        9: "national-navidad",
        10: "national-nino",
      },
      syndicates: {
        index: "syndicates",
      },
      blog: {
        index: "blog",
        category: "category",
      },
      results: {
        index: "results",
      },
      help: {
        index: "help",
      },
      legal: {
        index: "legal",
      },
      privacy: {
        index: "privacy",
      },
      cookies: {
        index: "cookies",
      },
      cart: {
        index: "cart",
      },
    },
    private: {
      index: "myaccount",
    },
  },

  draws: {
    1: {
      class: {
        4: "national-thursday",
        6: "national-saturday",
        8: "national-extra",
        10: "national-nino",
        9: "national-navidad",
      },
      title: {
        4: "Nacional Thursday Raffle",
        6: "Nacional Saturday Raffle",
        8: "Nacional Extra Raffle",
        9: "Nacional Navidad Raffle",
        10: "Nacional El Niño Raffle",
      },
      description: {
        4: "Nacional Thursday Raffle - Description",
        6: "Nacional Saturday Raffle - Description",
        8: "Nacional Extra Raffle - Description",
        9: "Nacional Navidad Raffle - Description",
        10: "Nacional El Niño Raffle - Description",
      },
      logo: {
        4: "../../../assets/img/loteria_nat_thurday_es.png",
        6: "../../../assets/img/loteria_nat_saturday_es.png",
        8: "../../../assets/img/loteria_nat_extra_es.png",
        9: "../../../assets/img/loteria_nat_navidad_es.png",
        10: "../../../assets/img/loteria_nat_ninio_es.png",
      },
    },
    2: {
      class: "euromillions",
      title: "Euromillones",
      description: "Euromillones",
      logo: "../../../assets/img/euromillions_es.png",
    },
    3: {
      class: "primitiva",
      title: "La Primitiva",
      description: "La Primitiva",
      logo: "../../../assets/img/primitiva_es.png",
    },
    4: {
      class: "bonoloto",
      title: "Bonoloto",
      description: "Bonoloto",
      logo: "../../../assets/img/bonoloto_es.png",
    },
    5: {
      class: "gordo",
      title: "El Gordo",
      description: "El Gordo",
      logo: "../../../assets/img/gordo_primitiva_es.png",
    },
    6: {
      class: "quiniela",
      title: "Quiniela",
      description: "Quiniela",
      logo: "../../../assets/img/quiniela_es.png",
    },
    7: {
      class: "powerball",
      title: "Powerball",
      description: "Powerball",
      logo: "../../../assets/img/powerball_us.png",
    },
    8: {
      class: "megamillions",
      title: "Megamillions",
      description: "Megamillions",
      logo: "../../../assets/img/megamillions_us.png",
    },
    9: {
      class: "superenalotto",
      title: "Superenalotto",
      description: "Superenalotto",
      logo: "../../../assets/img/superenalotto_it.png",
    },
    10: {
      class: "eurodreams",
      title: "Eurodreams",
      description: "Eurodreams",
      logo: "../../../assets/img/eurodreams_es.png",
    },
  },

  format: {
    number: {
      hundred: "",
      kilo: "k",
      mega: "millions",
    },
  },

  header: {
    toolbar: {
      lottery: "Lotteries",
      raffles: "Raffles",
      syndicates: "Syndicates",
      results: "Results",
      blog: "Blog",
      help: "Help",
      cart: "Cart",
      login: "Sign In",
      register: "Create Account",
      menu: "Menu",
      account: "My Account",
      user: "Hello, {{user_name}}!",
      wallet: "{{amount}}",
      wallet_tooltip: "Your wallet: $t(header.toolbar.wallet)",
      logout: "Log Out",
    },
  },

  logo: {
    alt: "$t(app.name) Logo",
    title: "Home",
  },

  footer: {
    legal_age: {
      alt: "You must be 18 or over",
      title: "You must be 18 or over",
      msg: "You must be 18 or over",
    },
    links: {
      legal: "Terms",
      privacy: "Privacy",
      cookies: "Cookies",
      help: "Help",
    },
    socials: {
      youtube: {
        title: "Youtube",
        href: "https://www.youtube.com/@loteriacastillo",
      },
      facebook: {
        title: "Facebook",
        href: "https://www.facebook.com/loteriacastilloalaquas",
      },
      twitter: {
        title: "X (Twitter)",
        href: "https://twitter.com/CastilloAlaquas",
      },
      instagram: {
        title: "Instagram",
        href: "https://www.instagram.com/loteria_castillo/",
      },
      pinterest: {
        title: "Pinterest",
        href: "https://www.pinterest.es/loteriacastillo/",
      },
      linkedIn: {
        title: "LinkedIn",
        href: "http://www.linkedin.com/company/2926533",
      },
    },
  },

  lottery_card: {
    per_month: "/mth",
  },

  raffle_card: {
    pot: "<currency></currency><amount></amount> <text></text>",
    msg_pot: "$t(raffle_card.pot) in prizes!",
    stock: "stock",
    msg_stock: "<content></content>",
  },

  syndicate_card: {
    bets: "{{num_bets}} bets",
  },

  login: {
    title: "Sign In - $t(app.name)",
    description: "Sign In - $t(app.name) Description",
    header: {
      title: "Sign In",
      subtitle: "",
    },
    body: {
      form: {
        email: "Email",
        password: "Password",
        log_in_btn: "Sign In",
        forgot_password_btn: "Recover",
        forget_password_msg: "Forgot your password? <link1>Recover</link1>",
        create_account_msg:
          "Don't have an account? <link1>Create Account</link1>",
      },
    },
    footer: {},
  },

  register: {
    title: "Create Account - $t(app.name)",
    description: "Create Account - $t(app.name) Description",
    header: {
      title: "Create Account",
      subtitle: "",
    },
    body: {
      form: {
        user_name: "Username",
        email: "Email",
        pass1: "Password",
        pass2: "Repeat Password",
        terms:
          "By clicking, you agree to <link1>legal</link1> and <link2>privacy</link2> policies.",
        create_account_btn: "Create Account",
        log_in_msg: "Already have an account? <link1>Sign In</link1>",
      },
    },
    footer: {},
  },

  forgot_password: {
    title: "Forgot Password - $t(app.name)",
    description: "Forgot Password - $t(app.name) Description",
    header: {
      title: "Forgot Password",
    },
    body: {
      subtitle:
        "Please, enter your email and we will send you the instructions to set up a new password.",
      form: {
        email: "Email",
        recover: "Recover Account",
      },
    },
    footer: {},
  },

  update_user: {
    title: "",
    description: "",
    header: {},
    body: {
      form: {
        title: "User Information",
        personal_title: "Personal Information",
        name: "Name",
        surname: "Surname",
        dni: "Document ID",
        birthday: "Birthday",
        phone: "Phone",
      },
    },
    footer: {},
  },

  change_password: {
    title: "",
    description: "",
    header: {},
    body: {
      form: {
        title: "Change Password",
        email: "Email",
        password: "Old Password",
        newPassword1: "New Password",
        newPassword2: "Repeat New Password",
      },
    },
    footer: {},
  },

  legal: {
    title: "Legal Terms Page - $t(app.name)",
    description: "Legal Terms Page - $t(app.name) Description",
    header: {},
    body: {
      title: "Legal Terms",
    },
    footer: {},
  },

  privacy: {
    title: "Privacy Policy Page - $t(app.name)",
    description: "Privacy Policy Page - $t(app.name) Description",
    header: {},
    body: {
      title: "Privacy Policy",
    },
    footer: {},
  },

  cookies: {
    title: "Cookies Policy Page - $t(app.name)",
    description: "Cookies Policy Page - $t(app.name) Description",
    header: {},
    body: {
      title: "Cookies Policy",
    },
    footer: {},
  },

  cart: {
    title: "Shopping Cart Page - $t(app.name)",
    description: "Shopping Cart Page - $t(app.name) Description",
    header: {},
    body: {
      title: "Shopping Cart",
      items: {
        draw_title: "{{draw_title}} - {{draw_date}}",
        draw_date: "Draw Date: {{draw_date}}",
        multiple: "Multiple Bet: {{multiple}}",
        subscription: "Subscription: {{subscription}}",
        joker: "Joker: {{joker}}",
        megaplier: "Megaplier: {{megaplier}}",
        powerplay: "Powerplay: {{powerplay}}",
        superstar: "Superstar: {{superstar}}",
      },
      login: {
        title: "$t(button.login)",
        subtitle:
          "Please, sign in to continue with your purchase. <login>{{loginLabelBtn}}</login> or <register>{{registerLabelBtn}}</register>.",
      },
      summary: {
        title: "Summary",
        total: "Total: {{total}}",
      },
    },
    footer: {},
  },

  geoblock: {
    title: "Geoblock Page - $t(app.name)",
    description: "Geoblock Page - $t(app.name) Description",
    header: {},
    body: {},
    footer: {},
  },

  landing: {
    title: "Landing Page - $t(app.name)",
    description: "Landing Page - $t(app.name) Description",
    header: {},
    body: {
      section: {
        slider: {
          title: "$t(app.name)",
        },
        lottery: {
          title: "Lotteries",
        },
        raffle: {
          title: "Raffles",
        },
        syndicate: {
          title: "Syndicates",
        },
      },
    },
    footer: {},
  },

  lotteries: {
    title: "Lotteries - $t(app.name)",
    description: "Lotteries - $t(app.name) Descripcion",
    header: {},
    body: {
      title: "Lotteries",
    },
    footer: {},
  },

  raffles: {
    title: "Raffles - $t(app.name)",
    description: "Raffles - $t(app.name) Descripcion",
    header: {},
    body: {
      title: "Raffles",
    },
    footer: {},
  },

  raffle_thursday: {
    title: "National Thursday Raffle - $t(app.name)",
    description: "National Thursday Raffle - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
    },
    footer: {},
  },

  raffle_saturday: {
    title: "National Saturday Raffle - $t(app.name)",
    description: "National Saturday Raffle - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
    },
    footer: {},
  },

  raffle_extra: {
    title: "National Extra Raffle - $t(app.name)",
    description: "National Extra Raffle - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
    },
    footer: {},
  },

  raffle_navidad: {
    title: "National Navidad Raffle - $t(app.name)",
    description: "National Navidad Raffle - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
    },
    footer: {},
  },

  raffle_nino: {
    title: "National El Niño Raffle - $t(app.name)",
    description: "National El Niño Raffle - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
    },
    footer: {},
  },

  syndicates: {
    title: "Syndicates - $t(app.name)",
    description: "Syndicates - $t(app.name) Descripcion",
    header: {},
    body: {
      title: "Syndicates",
    },
    footer: {},
  },

  // 2
  euromillions: {
    title: "Euromillones - $t(app.name)",
    description: "Euromillones - $t(app.name) Descripcion",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
        specials: {
          title: "Stars",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your random multiple play by selecting how many numbers and how many stars you want. You just have to multiply the number combinations with the star combinations to find out the total number of combinations you are going to play.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
          specials: {
            title: "Star combinations",
            num_specialsWithCount_one: "{{count}} star",
            num_specialsWithCount_other: "{{count}} stars",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 3
  primitiva: {
    title: "La Primitiva - $t(app.name)",
    description: "La Primitiva - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
      },
      addons: {
        refund: "Refund",
        joker: "Joker",
        play_joker: "Play $t(primitiva.body.addons.joker)",
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your multiple random play by selecting how many numbers you want. Don't forget to click the refund number.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 4
  bonoloto: {
    title: "Bonoloto - $t(app.name)",
    description: "Bonoloto - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your multiple random play by selecting how many numbers you want.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 5
  gordo: {
    title: "El Gordo - $t(app.name)",
    description: "El Gordo - $t(app.name) - Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
      },
      addons: {
        key_number: "key number",
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your multiple random play by selecting how many numbers you want. Don't forget to click the key number.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 6
  quiniela: {
    title: "Quiniela - $t(app.name)",
    description: "Quiniela - $t(app.name) Description",
    header: {},
    body: {},
    footer: {},
  },

  // 7
  powerball: {
    title: "Powerball - $t(app.name)",
    description: "Powerball - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
        specials: {
          title: "Powerballs",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your random multiple play by selecting how many numbers and how many powerballs you want. You just have to multiply the number combinations with the powerball combinations to find out the total number of combinations you are going to play.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      addons: {
        powerplay: "Powerplay",
        play_powerplay: "Play $t(powerball.body.addons.powerplay)",
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 8
  megamillions: {
    title: "Megamillions - $t(app.name)",
    description: "Megamillions - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
        specials: {
          title: "Megaballs",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your random multiple play by selecting how many numbers and how many megaballs you want. You just have to multiply the number combinations with the megaball combinations to find out the total number of combinations you are going to play.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      addons: {
        megaplier: "Megaplier",
        play_megaplier: "Play $t(megamillions.body.addons.megaplier)",
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 9
  superenalotto: {
    title: "Superenalotto - $t(app.name)",
    description: "Superenalotto - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Actual pot",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your multiple random play by selecting how many numbers you want.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      addons: {
        superstar: "Superstar",
        play_superstar: "Play $t(superenalotto.body.addons.superstar)",
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  // 10
  eurodreams: {
    title: "Eurodreams - $t(app.name)",
    description: "Eurodreams - $t(app.name) Description",
    header: {},
    body: {
      title: {
        play: "Play",
        pot: "Every month for 30 years",
        closingDate: "Closing date",
      },
      tabs: {
        single: "Single bet",
        multiple: "Multiple bet",
      },
      board: {
        numbers: {
          title: "Numbers",
        },
        specials: {
          title: "dreams",
        },
      },
      config: {
        header: {
          title: "Automatic bet",
          subtitle:
            "Select your random multiple play by selecting how many numbers and how many dreams you want. You just have to multiply the number combinations with the dream combinations to find out the total number of combinations you are going to play.",
        },
        body: {
          numbers: {
            title: "Number combinations",
            num_numbersWithCount_one: "{{count}} number",
            num_numbersWithCount_other: "{{count}} numbers",
            num_betsWithCount_one: "{{count}} bet",
            num_betsWithCount_other: "{{count}} bets",
          },
        },
      },
      summary: {
        num_draws: {
          title: "How many draws do you want to play?",
        },
        total: {
          price: "Total: {{amount}}",
          num_draws: "Number of draws: {{value}}",
          draw_date: "Draw date: {{date}}",
          range_draw_date: "Draw date: {{init_date}} - {{end_date}}",
        },
      },
    },
    footer: {},
  },

  template: {
    title: "Página - $t(app.name)",
    description: "Página - $t(app.name) Description",
    header: {},
    body: {},
    footer: {},
  },

  results: {
    title: "Results - $t(app.name)",
    description: "Results - $t(app.name) Description",
    header: {},
    body: {
      title: "Results",
    },
    footer: {},
  },

  help: {
    title: "Help - $t(app.name)",
    description: "Help - $t(app.name) Description",
    header: {},
    body: {
      title: "Help",
    },
    footer: {},
  },

  blog: {
    title: "Blog - $t(app.name)",
    description: "Blog - $t(app.name) Description",
    header: {},
    body: {
      title: "Blog",
      categories: "Categories",
      last_posts: "Last posts",
      next_draws: "Next draws",
    },
    footer: {},
  },

  blog_category: {
    title: "Blog: Category {{category}} - $t(app.name)",
    description: "Blog: Category {{category}} - $t(app.name) Description",
    header: {},
    body: {
      title: "Blog",
      categories: "Categories",
      last_posts: "Last posts",
      next_draws: "Next draws",
    },
    footer: {},
  },

  blog_post: {
    title: "Blog: Category {{category}} - {{title}} - $t(app.name)",
    description:
      "Blog: Category {{category}} - {{title}} - $t(app.name) Description",
    header: {},
    body: {
      title: "Blog",
      categories: "Categories",
      last_posts: "Last posts",
      next_draws: "Next draws",
    },
    footer: {},
  },

  error_page: {
    title: "",
    description: "",
    header: {
      title: "Page not found",
    },
    body: {
      message_count_down_one: "Redirecting in {{count}} second...",
      message_count_down_other: "Redirecting in {{count}} seconds...",
    },
    footer: {},
  },

  account: {
    title: "My account - $t(app.name)",
    description: "My account - $t(app.name) Description",
    header: {},
    body: {
      title: "My Account",
      tabs: {
        personal: {
          index: "personal",
          title: "Personal Details",
          label: "Personal Details",
          anchor: "",
        },
        password: {
          index: "password",
          title: "Password",
          label: "Password",
          anchor: "password",
        },
        bets: {
          index: "bets",
          title: "Bets",
          label: "Bets",
          anchor: "bets",
        },
        movements: {
          index: "movements",
          title: "Movements",
          label: "Movements",
          anchor: "movements",
        },
        deposit: {
          index: "deposit",
          title: "Deposit",
          label: "Deposit",
          anchor: "deposit",
        },
        withdraw: {
          index: "withdraw",
          title: "Withdraw",
          label: "Withdraw",
          anchor: "withdraw",
        },
        subscription: {
          index: "subscription",
          title: "Subscription",
          label: "Subscription",
          anchor: "subscription",
        },
      },
    },
    footer: {},
  },
};
