import FooterPage from "../../../components/footer";

function ResultsFooter() {
  return (
    <div className="results-footer">
      <FooterPage />
    </div>
  );
}

export default ResultsFooter;
