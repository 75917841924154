import i18n from "../../i18n";

import { createTheme } from "@mui/material";
import { enUS, esES } from "@mui/x-date-pickers/locales";

import { equalsIgnoringCase } from "../../utils";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#674a92",
        contrastText: "#ffffff",
      },
    },
  },
  equalsIgnoringCase(i18n.resolvedLanguage, "es") ? esES : enUS
);

export default theme;
