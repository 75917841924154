import { useCallback, useMemo } from "react";

import SearchBlogFilter from "./search-blog-filter";
import CategoryBlogFilter from "./category-blog-filter";
import BlogLastPostsFilter from "./blog-last-posts-filter";
import BlogLotteryFilter from "../blog-lottery-filter";

import { isEmpty } from "../../utils";

function BlogFilter(props) {
  const {
    loading,
    disabled,
    showSearchFilter,
    params,
    onChangeParams,
    showCategoriesFilter,
    loadingCategories,
    categories,
    loadingParentCategory,
    parentCategory,
    showLastPosts,
    showLottery,
  } = props;

  const searchValue = useMemo(() => {
    return !isEmpty(params) && !isEmpty(params.filter)
      ? params.filter.search
      : "";
  }, [params]);

  const onBlurHandler = useCallback(
    (value) => {
      if (!disabled && onChangeParams) {
        onChangeParams("blur", {
          filter: {
            ...params.filter,
            search: value,
          },
          pagination: { ...params.pagination },
        });
      }
    },
    [disabled, params, onChangeParams]
  );

  const onChangeHandler = useCallback(
    (value) => {
      if (!disabled && onChangeParams) {
        onChangeParams("change", {
          filter: {
            ...params.filter,
            search: value,
          },
          pagination: { ...params.pagination },
        });
      }
    },
    [disabled, params, onChangeParams]
  );

  const onClearHandler = useCallback(
    (value) => {
      if (!disabled && onChangeParams) {
        onChangeParams("clear", {
          filter: {
            ...params.filter,
            search: value,
          },
          pagination: { ...params.pagination },
        });
      }
    },
    [disabled, params, onChangeParams]
  );

  return (
    <div className="blog-filter__container">
      <div className="blog-filter__content">
        {showSearchFilter && (
          <SearchBlogFilter
            loading={loading}
            disabled={disabled}
            value={searchValue}
            onBlur={(value) => onBlurHandler(value)}
            onChange={(value) => onChangeHandler(value)}
            onClear={(value) => onClearHandler(value)}
          />
        )}
        {showCategoriesFilter && (
          <CategoryBlogFilter
            loading={loading}
            disabled={disabled}
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
          />
        )}
        {showLastPosts && (
          <BlogLastPostsFilter
            loading={loading}
            disabled={disabled}
            loadingCategories={loadingCategories}
            categories={categories}
            loadingParentCategory={loadingParentCategory}
            parentCategory={parentCategory}
            maxPosts={3}
          />
        )}
        {showLottery && (
          <BlogLotteryFilter loading={loading} disabled={disabled} />
        )}
      </div>
    </div>
  );
}

export default BlogFilter;
