import HeaderPage from "../../../components/header";

function MyAccountHeader() {
  return (
    <div className="my-account-header">
      <div className="my-account-header__container">
        <div className="my-account-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default MyAccountHeader;
