import { useMemo } from "react";

import moment from "moment";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import { Link } from "react-router-dom";
import CountdownComponent from "../countdown-component";

import { DATE_FORMAT, isEmpty, nFormatter } from "../../utils";

function SyndicateCard(props) {
  const { item } = props;

  const { t, i18n } = useTranslation();
  const { getLocalePath } = useGetLocalePath();

  const closeDate = useMemo(() => {
    return moment(item.close_date).format(DATE_FORMAT.US_LONG_FORMAT);
  }, [item.close_date]);

  const disabled = useMemo(() => {
    const now = moment().format(DATE_FORMAT.US_LONG_FORMAT);
    const closeDate = moment(item.close_date).format(
      DATE_FORMAT.US_LONG_FORMAT
    );
    const expired = moment(now).isAfter(moment(closeDate));
    return expired;
  }, [item.close_date]);

  const linkMemo = useMemo(() => {
    return disabled ? "" : getLocalePath(t("routes.public.syndicates.index"));
  }, [disabled, getLocalePath, t]);

  const logoMemo = useMemo(() => {
    return !isEmpty(item.logo)
      ? item.logo
      : "../../../assets/img/syndicate_default.png";
  }, [item.logo]);

  const NameContainerComponent = (props) => {
    return (
      <>
        <div className="name" title={item.name}>
          {item.name}
        </div>
      </>
    );
  };

  const PotContainerComponent = (props) => {
    const potMemo = useMemo(() => {
      const { amount, symbol } = nFormatter(
        parseFloat(item.pot) / 100,
        2,
        i18n
      );
      return (
        <>
          <div className="pot" title={`${amount} ${symbol}`}>
            <span className="amount">{amount}</span>
            <span className="unit">{symbol}</span>
          </div>
          <div
            className="bets"
            title={t("syndicate_card.bets", { num_bets: item.num_bets })}
          >
            <span className="num_bets">
              {t("syndicate_card.bets", { num_bets: item.num_bets })}
            </span>
          </div>
        </>
      );
    }, []);

    const waitingPotMemo = useMemo(() => {
      return (
        <span className="waiting" title={t("message.waiting_pot")}>
          {t("message.waiting_pot")}
        </span>
      );
    }, []);

    return (
      <div className="lottery-card__body__pot__content">
        {!isEmpty(item.pot) && parseFloat(item.pot) > 0
          ? potMemo
          : waitingPotMemo}
      </div>
    );
  };

  return (
    <Link
      className={classNames("syndicate-card__container", {
        disabled: disabled,
      })}
      title={item.title}
      to={linkMemo}
      state={{ item: item }}
    >
      <div className="syndicate-card__header">
        <div className="syndicate-card__header__logo__container">
          <img
            className="logo"
            src={logoMemo}
            alt={item.name}
            title={item.name}
            loading="eager"
            width="auto"
            height="auto"
          />
        </div>
      </div>
      <div className="syndicate-card__body">
        <div className="syndicate-card__body__name__container">
          <NameContainerComponent />
        </div>
        <div className="syndicate-card__body__pot__container">
          <PotContainerComponent />
        </div>
        <div className="syndicate-card__body__countdown__container">
          <span className="icon icon-clock"></span>
          <CountdownComponent date={closeDate} />
        </div>
      </div>
      <div className="syndicate-card__footer">
        <div className="syndicate-card__footer__button__container">
          <div className="button" title={t("button.play")}>
            {t("button.play")}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SyndicateCard;
