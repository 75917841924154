import FooterPage from "../../../components/footer";

function LotteryFooter() {
  return (
    <div className="lotteries-footer">
      <FooterPage />
    </div>
  );
}

export default LotteryFooter;
