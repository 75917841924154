import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../hooks/useGetLocalePath";
import useUserRegister from "../../../hooks/international-api/user/useUserRegister";

import {
  setOpenLoginDialog,
  setOpenRegisterDialog,
} from "../../../redux/reducers/uiReducer";
import { removeUser, setUser } from "../../../redux/reducers/userReducer";

import {
  Link,
  Dialog,
  Backdrop,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { UserRegisterForm } from "../../form";
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from "@mui/icons-material/Close";
import { errorToast, successToast } from "../../toast";

import { logDebug, logError } from "../../../utils/logger";
import { equalsIgnoringCase, isEmptyObject } from "../../../utils";

function RegisterDialog(props) {
  const { isOpen, onClose } = props;

  const [dataForm, setDataForm] = useState({
    user_name: "",
    email: "",
    pass1: "",
    pass2: "",
    termsOfUse: false,
    "g-recaptcha-response": "",
  });

  const recaptchaRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isOpenLoginDialog, isOpenRegisterDialog } = useSelector(
    (state) => state.ui
  );

  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const {
    error: errorUserRegister,
    data: dataUserRegister,
    loading: loadingUserRegister,
    userRegister,
  } = useUserRegister();

  const disabled = useMemo(() => {
    return loadingUserRegister;
  }, [loadingUserRegister]);

  const onCloseHandler = useCallback(
    (evt) => {
      if (!disabled) {
        logDebug("RegisterDialog", "onCloseHandler", {
          evt: evt,
        });
        onClose && onClose(evt);
      }
    },
    [disabled, onClose]
  );

  const asyncScriptOnLoadHandler = useCallback(
    (data) => {
      if (!disabled) {
        logDebug("RegisterDialog", "asyncScriptOnLoadHandler", {
          data: data,
        });
        setLoaded(data.loaded);
      }
    },
    [disabled]
  );

  const DialogHeader = (props) => {
    return (
      <>
        <DialogTitle>
          <Typography
            component="span"
            sx={{
              width: "100%",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {t("register.header.title")}
          </Typography>
        </DialogTitle>
        <IconButton
          title={t("button.close")}
          aria-label={t("button.close")}
          onClick={(evt) => onCloseHandler(evt)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };

  const DialogBody = (props) => {
    const onValidHandler = useCallback(async (data, evt) => {
      if (!disabled) {
        logDebug("RegisterDialog.DialogBody", "onValidHandler", {
          data: data,
          evt: evt,
        });
        setDataForm({ ...data });
        userRegister({
          header: {},
          body: {
            user_name: data.user_name,
            email: data.email,
            pass: data.pass1,
            "g-recaptcha-response": data["g-recaptcha-response"],
          },
        });
      }
    }, []);

    const onInvalidHandler = useCallback(async (errors, evt) => {
      if (!disabled) {
        logDebug("RegisterDialog.DialogBody", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        errorToast(
          "RegisterDialog.DialogBody - onInvalidHandler",
          "error_form",
          t("errors.error_form", t("errors.default"))
        );
      }
    }, []);

    const changeRecaptchaHandler = useCallback((token) => {
      try {
        if (!disabled) {
          logDebug("RegisterDialog.DialogBody", "changeRecaptchaHandler", {
            "g-recaptcha-response": token,
          });
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": token,
          });
        }
      } catch (error) {
        logError("RegisterDialog.DialogBody", "changeRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "RegisterDialog.DialogBody - changeRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    const resetRecaptchaHandler = useCallback((evt) => {
      try {
        if (!disabled) {
          logDebug("RegisterDialog.DialogBody", "resetRecaptchaHandler", {
            evt: evt,
          });
          recaptchaRef.current.reset();
          setDataForm({
            ...dataForm,
            "g-recaptcha-response": "",
          });
        }
      } catch (error) {
        logError("RegisterDialog.DialogBody", "resetRecaptchaHandler", {
          error: error,
        });
        errorToast(
          "RegisterDialog.DialogBody - resetRecaptchaHandler",
          error,
          t(`errors.${error}}`, t("errors.default"))
        );
      }
    }, []);

    return (
      <DialogContent dividers>
        <UserRegisterForm
          loading={loadingUserRegister}
          disabled={disabled}
          dataForm={dataForm}
          recaptcha={{
            loaded: loaded,
            ref: recaptchaRef.current,
            resetRecaptcha: resetRecaptchaHandler,
            changeRecaptcha: changeRecaptchaHandler,
          }}
          onValid={(data, evt) => onValidHandler(data, evt)}
          onInvalid={(errors, evt) => onInvalidHandler(errors, evt)}
        />
      </DialogContent>
    );
  };

  const DialogFooter = (props) => {
    const GoToLoginText = (props) => {
      const onClickHandler = useCallback((evt) => {
        if (!disabled) {
          logDebug(
            "RegisterDialog.DialogFooter.GoToLoginText",
            "onClickHandler",
            {
              evt: evt,
            }
          );
          dispatch(setOpenLoginDialog(!isOpenLoginDialog));
          dispatch(setOpenRegisterDialog(!isOpenRegisterDialog));
        }
      }, []);

      return (
        <Typography variant="body1">
          <Trans
            i18nKey="register.body.form.log_in_msg"
            components={{
              link1: (
                <Link
                  type="button"
                  variant="body1"
                  component="button"
                  title={t("login.header.title")}
                  onClick={(evt) => onClickHandler(evt)}
                />
              ),
            }}
          ></Trans>
        </Typography>
      );
    };

    return (
      <DialogContent dividers>
        <GoToLoginText />
      </DialogContent>
    );
  };

  useEffect(() => {
    if (!loadingUserRegister) {
      if (!isEmptyObject(dataUserRegister)) {
        logDebug("RegisterDialog", "userRegister", {
          data: dataUserRegister,
        });
        dispatch(setUser({ ...dataUserRegister }));
        logDebug("RegisterDialog", "userRegister", {
          data: dataUserRegister,
          msg: t("message.default"),
        });
        successToast("RegisterDialog", "message.default", t("message.default"));
        dispatch(setOpenRegisterDialog(false));
        navigate(getLocalePath(t("routes.private.index")));
      } else if (!isEmptyObject(errorUserRegister)) {
        if (equalsIgnoringCase(errorUserRegister, "expired_session")) {
          dispatch(removeUser());
          navigate(getLocalePath("/"));
          logError("RegisterDialog", "expired_session", {
            error: "expired_session",
            msg: t(`errors.expired_session`, t("errors.default")),
          });
          errorToast(
            "RegisterDialog",
            "expired_session",
            t(`errors.expired_session`, t("errors.default"))
          );
        } else {
          logError("RegisterDialog", "userRegister", {
            error: errorUserRegister,
            msg: t(`errors.${errorUserRegister}`, t("errors.default")),
          });
          errorToast(
            "RegisterDialog",
            errorUserRegister,
            t(`errors.${errorUserRegister}`, t("errors.default"))
          );
        }
      }
    }
  }, [
    dataUserRegister,
    errorUserRegister,
    loadingUserRegister,
    dispatch,
    navigate,
    getLocalePath,
    t,
  ]);

  return (
    <>
      <Dialog open={isOpen} onClose={(evt) => onCloseHandler(evt)}>
        <DialogHeader />
        <DialogBody />
        <DialogFooter />
      </Dialog>
      <ReCAPTCHA
        ref={recaptchaRef}
        className="recaptcha-container"
        size="invisible"
        badge="bottomright"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        asyncScriptOnLoad={(data) => asyncScriptOnLoadHandler(data)}
      />
      <Backdrop
        open={loadingUserRegister}
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" thickness={10} />
      </Backdrop>
    </>
  );
}

export default RegisterDialog;
