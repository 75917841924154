import HeaderPage from "../../../components/header";

function PrimitivaHeader() {
  return (
    <div className="primitiva-header">
      <div className="primitiva-header__container">
        <div className="primitiva-header__content">
          <HeaderPage />
        </div>
      </div>
    </div>
  );
}

export default PrimitivaHeader;
