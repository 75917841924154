import { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useGetLocalePath from "../../../../hooks/useGetLocalePath";
import useUserChangePassword from "../../../../hooks/international-api/user/useUserChangePassword";

import { removeUser } from "../../../../redux/reducers/userReducer";

import { errorToast, successToast } from "../../../../components/toast";
import UserPasswordChangeForm from "../../../../components/form/user-change-password-form";

import { logDebug, logError } from "../../../../utils/logger";
import { equalsIgnoringCase, isEmptyObject } from "../../../../utils";
import { TYPE_REQUEST } from "../../../../hooks/international-api/utils";

function MyAccountPasswordTab() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLocalePath } = useGetLocalePath();

  const { user } = useSelector((state) => state.user);
  const { email, session_token } = user;

  const [dataForm, setDataForm] = useState({
    email: email,
    password: "",
    newPassword1: "",
    newPassword2: "",
  });

  const {
    data: dataUserChangePassword,
    error: errorUserChangePassword,
    loading: loadingUserChangePassword,
    userChangePassword,
  } = useUserChangePassword();

  const loading = useMemo(() => {
    return [loadingUserChangePassword].some((i) => i);
  }, [loadingUserChangePassword]);

  const disabled = useMemo(() => {
    return loading;
  }, [loading]);

  const onValidHandler = useCallback(
    async (data, evt) => {
      if (!disabled) {
        logDebug("MyAccountPersonalTab", "onValidHandler", {
          data: data,
          evt: evt,
        });
        userChangePassword({
          header: {
            email: email,
            token: session_token,
            type_request: TYPE_REQUEST.USERNAME,
          },
          body: {
            password_old: data.password,
            password_new: data.newPassword1,
          },
        });
      }
    },
    [disabled, email, session_token, userChangePassword]
  );

  const onInvalidHandler = useCallback(
    async (errors, evt) => {
      if (!disabled) {
        logDebug("MyAccountPasswordTab", "onInvalidHandler", {
          errors: errors,
          evt: evt,
        });
        errorToast(
          "MyAccountPasswordTab - onInvalidHandler",
          "error_form",
          t("errors.error_form", t("errors.default"))
        );
      }
    },
    [disabled, t]
  );

  useEffect(() => {
    if (!loading) {
      if (!isEmptyObject(dataUserChangePassword)) {
        logDebug("MyAccountPasswordTab", "userChangePassword", {
          data: dataUserChangePassword,
        });
        successToast(
          "MyAccountPasswordTab - userChangePassword",
          "message.default",
          t("message.default")
        );
        setDataForm({
          email: email,
          password: "",
          newPassword1: "",
          newPassword2: "",
        });
      } else if (!isEmptyObject(errorUserChangePassword)) {
        setDataForm({
          email: email,
          password: "",
          newPassword1: "",
          newPassword2: "",
        });
        if (equalsIgnoringCase(errorUserChangePassword, "expired_session")) {
          dispatch(removeUser());
          navigate(getLocalePath("/"));
          logError("MyAccountPasswordTab", "expired_session", {
            error: "expired_session",
            msg: t(`errors.expired_session`, t("errors.default")),
          });
          errorToast(
            "MyAccountPasswordTab",
            "expired_session",
            t(`errors.expired_session`, t("errors.default"))
          );
        } else {
          logError("MyAccountPasswordTab", "userProfile", {
            error: errorUserChangePassword,
            msg: t(`errors.${errorUserChangePassword}`, t("errors.default")),
          });
          errorToast(
            "MyAccountPasswordTab",
            errorUserChangePassword,
            t(`errors.${errorUserChangePassword}`, t("errors.default"))
          );
        }
      }
    }
  }, [
    email,
    loading,
    dataUserChangePassword,
    errorUserChangePassword,
    getLocalePath,
    dispatch,
    navigate,
    t,
  ]);

  return (
    <div className="my-account__personal__container">
      <div className="my-account__personal__content">
        <UserPasswordChangeForm
          loading={loading}
          disabled={disabled}
          dataForm={dataForm}
          onValid={(data, evt) => onValidHandler(data, evt)}
          onInvalid={(errors, evt) => onInvalidHandler(errors, evt)}
        />
      </div>
    </div>
  );
}

export default MyAccountPasswordTab;
