import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGetLocalePath from "../../hooks/useGetLocalePath";

import FooterPage from "../../components/footer";
import HeaderPage from "../../components/header";

function ErrorPage() {
  const COUNTDOWN = 5;
  const { t } = useTranslation();

  const ErrorHeader = () => {
    return (
      <div className="page-error__header">
        <div className="page-error__header__container">
          <div className="page-error__header__content">
            <HeaderPage />
          </div>
        </div>
      </div>
    );
  };

  const ErrorBody = () => {
    const navigate = useNavigate();
    const { getLocalePath } = useGetLocalePath();

    const [countDown, setCountDown] = useState(COUNTDOWN);

    useEffect(() => {
      setTimeout(() => {
        countDown > 1
          ? setCountDown(countDown - 1)
          : navigate(getLocalePath("/"));
      }, 1000);
    }, [countDown, navigate, getLocalePath]);

    return (
      <div className="page-error__body">
        <div className="page-error__body__container">
          <div className="page-error__body__content">
            <h1 className="page-error__body__title">
              {t("error_page.header.title")}
            </h1>
            <p className="page-error__body__message">
              {t("error_page.body.message_count_down", { count: countDown })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const ErrorFooter = () => {
    return (
      <div className="page-error__footer">
        <FooterPage />
      </div>
    );
  };

  return (
    <div className="error-page">
      <div className="error-page__container">
        <ErrorHeader />
        <ErrorBody />
        <ErrorFooter />
      </div>
    </div>
  );
}

export default ErrorPage;
